import React, { useContext } from 'react';
import { Power, ArrowLeft } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../common/auth/auth.context';
import WithConfirm from './with-confirmation';

const VerticalLayout = ({
  children,
  background,
  canGoBack,
}: {
  children?: React.ReactNode;
  background: string;
  canGoBack?: boolean;
}) => {
  const router = useNavigate();
  const { logout } = useContext(AuthContext);

  return (
    <section>
      <div
        className={`h-screen ${background} bg-cover bg-center bg-no-repeat p-16 md:flex md:flex-wrap md:pl-48`}
      >
        <WithConfirm
          onConfirm={logout}
          confirmText="Logging out..."
          subtitle="We will wait you to come back later!"
        >
          <div className="absolute top-[2rem] right-16 cursor-pointer justify-end">
            <Power className="text-white" />
          </div>
        </WithConfirm>
        {canGoBack && (
          <div
            className="absolute top-[2rem] left-16 flex cursor-pointer justify-end"
            onClick={() => router(-1)}
          >
            <ArrowLeft className="text-white" />
            <a className="ml-2 text-white">Inapoi</a>
          </div>
        )}

        <div className="flex-1"></div>
        <div className="h-full flex-1 p-8">{children}</div>
      </div>
    </section>
  );
};

export default VerticalLayout;
