import { useContext, useEffect, useState } from 'react';
import { RadioGroup } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import ButtonWrapper from './button-wrapper';
import PaymentActions from './payment-actions';
import { useAddTipMutation } from '../services/tips/tips.service';
import { AuthContext } from '../common/auth/auth.context';
import useToast from '../common/hooks/useToast';
import { MESSAGES } from '../common/constants/messages';
import { ERRORS } from '../common/constants/errors';
import { ToastType } from '../common/models/toast-type.enum';
import { MIN_TIP_AMOUNT, PAYMENT_OPTION } from '../common/constants/variables';
import { Button, Modal, ModalBody } from 'reactstrap';

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ');
}

interface TipTheDJModalProps {
  open: boolean;
  eventId: number;
  onClose: () => void;
}

interface PaymentOption {
  id: number;
  value: number;
  label: string;
}

const PaymentOption = ({ id, value, label }: PaymentOption) => {
  return (
    <RadioGroup.Option
      value={value}
      className={({ active }) =>
        classNames(
          'cursor-not-allowed text-black',
          active ? 'bg-neon-pink text-white' : 'bg-white',
          'group relative flex items-center justify-center rounded-md px-1 py-2.5 text-sm font-medium uppercase focus:outline-none sm:flex-1',
        )
      }
    >
      <RadioGroup.Label as="span">{`${label} lei`}</RadioGroup.Label>
    </RadioGroup.Option>
  );
};

const TipTheDJModal = ({ open, eventId, onClose }: TipTheDJModalProps) => {
  const [selectedValue, setSelectedValue] = useState<number>();
  const [isSubmitted, setIsSubmited] = useState<boolean>(false);
  const [isPaymentViewEnabled, setIsPaymentViewEnabled] = useState<boolean>(false);
  const { profile } = useContext(AuthContext);

  const [addTip] = useAddTipMutation();

  const handleTipSubmit = async (paymentIntent: string) => {
    if (selectedValue) {
      try {
        await addTip({
          variables: {
            tip: {
              userId: profile?.id,
              amount: selectedValue * 100,
              eventId,
              paymentIntent,
            },
          },
          onCompleted: () => {
            useToast(MESSAGES.guest.event.tipDj, ToastType.SUCCESS);
          },
          onError: (error) => {
            console.error(error);
            useToast(ERRORS.guest.event.tipDj, ToastType.ERROR);
          },
        });
      } catch (error) {
        useToast(ERRORS.guest.event.tipDj, ToastType.ERROR);
        console.error(error);
      }
    } else {
      useToast(ERRORS.guest.event.tipDjRequiredInputs, ToastType.ERROR);
    }
    onClose();
  };

  useEffect(() => {
    if (!open) {
      setTimeout(() => {
        setIsSubmited(false);
        setSelectedValue(undefined);
        setIsPaymentViewEnabled(false);
      }, 400);
    }
  }, [open]);

  const onSubmitPayment = () => {
    setIsSubmited(true);
    if (!selectedValue || selectedValue < MIN_TIP_AMOUNT) {
      return;
    }

    setIsPaymentViewEnabled(true);
  };

  return (
    <Modal isOpen={open} toggle={onClose} centered>
      <ModalBody className="gradient-background flex h-fit min-h-[50vh] w-full items-center justify-center overflow-hidden p-4">
        <button
          type="button"
          className="text-gray-400 hover:text-gray-500 absolute right-4 top-4 sm:right-6 sm:top-8 md:right-6 md:top-6 lg:right-8 lg:top-8"
          onClick={onClose}
        >
          <span className="sr-only">Close</span>
          <XMarkIcon className="white h-6 w-6" color="white" aria-hidden="true" />
        </button>

        {!isPaymentViewEnabled && (
          <div className="grid w-full grid-cols-1 items-start gap-x-6 gap-y-8 sm:grid-cols-12 lg:gap-x-8">
            <div className="sm:col-span-8 lg:col-span-7">
              <h2 className="text-center text-xl font-bold text-white sm:pr-12">Tip the DJ</h2>
              <section aria-labelledby="information-heading" className="mt-2">
                <p className="text-center text-sm text-white">
                  Drop some tips in the DJ's jar, show off your dance moves <br />
                  and keep the party going!
                </p>
              </section>

              <section aria-labelledby="options-heading" className="mt-2 flex flex-col gap-2">
                <form>
                  <div className="mt-4 flex flex-col gap-1">
                    <div className="flex items-center justify-between">
                      <h4 className="text-sm font-medium text-neon-pink">Choose amount</h4>
                    </div>
                    <RadioGroup value={selectedValue} onChange={setSelectedValue} className="mt-2">
                      <div className="grid grid-cols-3 gap-2">
                        {PAYMENT_OPTION.map((option) => (
                          <PaymentOption
                            key={option.value}
                            id={option.value}
                            label={option.label}
                            value={option.value}
                          />
                        ))}
                      </div>
                    </RadioGroup>
                    <div className="flex flex-col gap-1">
                      <div className="relative mt-2 rounded-md shadow-sm">
                        <input
                          onChange={(event) => setSelectedValue(+event.target.value)}
                          type="number"
                          name="price"
                          id="price"
                          className="text-gray-900 placeholder:text-gray-400 block w-full rounded-md border-0 px-2 py-2 focus-visible:outline-none sm:text-sm sm:leading-6"
                          placeholder="0.00"
                          aria-describedby="price-currency"
                        />
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex min-w-[3rem] items-center justify-center rounded-r-md bg-neon-pink">
                          <span className="text-white sm:text-sm" id="price-currency">
                            LEI
                          </span>
                        </div>
                      </div>
                      {(!selectedValue || selectedValue < MIN_TIP_AMOUNT) && isSubmitted && (
                        <p
                          className={`opacity-1 w-full text-right text-xs font-semibold text-neon-red`}
                        >
                          * Minimum tip amount is {MIN_TIP_AMOUNT} lei
                        </p>
                      )}
                    </div>
                  </div>
                  <ButtonWrapper
                    onClick={onSubmitPayment}
                    id="requestButtonNewSession"
                    classNames="!p-5 mt-3 min-w-full"
                  >
                    <p className="text-md pointer-events-none font-semibold text-white">
                      Go To Payment
                    </p>
                  </ButtonWrapper>
                </form>
              </section>
            </div>
          </div>
        )}
        {isPaymentViewEnabled && (
          <div className="rounded-3xl p-8 text-white">
            <h2 className="text-2xl font-semibold leading-8">Finalizeaza plata</h2>
            <p className="mt-6 flex items-baseline justify-center gap-x-1">
              <span className="text-gray-900 text-4xl font-bold tracking-tight">
                {`${selectedValue}`}
              </span>
              <span className="text-gray-600 text-sm font-semibold leading-6">lei</span>
            </p>
            <div className="mt-4 flex flex-col justify-center gap-2">
              {selectedValue && (
                <PaymentActions
                  id="tipDjPayButton"
                  label={'Dj Tip'}
                  amount={selectedValue * 100}
                  onPay={handleTipSubmit}
                  onFail={onClose}
                />
              )}
              <Button
                id="cancelPaymentButton"
                className={`flex h-[2.5rem] w-full min-w-fit items-center justify-around rounded-lg border-none bg-gray-selected p-3 hover:border-none hover:bg-light-gray-200 active:bg-light-gray-200`}
                onClick={setIsPaymentViewEnabled.bind(null, false)}
              >
                <span>Cancel</span>
              </Button>
            </div>
          </div>
        )}
      </ModalBody>
    </Modal>
  );
};

export default TipTheDJModal;
