import { gql } from '@apollo/client';

export const GET_EVENTS_QUERY = gql`
  query ($userId: Int!) {
    user(userId: $userId) {
      events {
        id
        name
        startDate
        location
        active
        code {
          code
          active
        }
      }
    }
  }
`;

export const EVENT_ACTIVE = gql`
  {
    eventActive {
      id
      code {
        code
      }
    }
  }
`;

export const ADD_EVENT_MUTATION = gql`
  mutation AddEvent($newEventData: NewEventInput!) {
    addEvent(newEventData: $newEventData) {
      id
    }
  }
`;

export const GET_EVENT_BY_ID = gql`
  query ($eventId: Int!) {
    event(id: $eventId) {
      id
      name
      djName
      startDate
      endDate
      location
      active
      playlist {
        id
      }
    }
  }
`;

export const GET_ACTIVE_EVENT_BY_ID_WITH_FULL_PLAYLIST = gql`
  {
    eventActive {
      id
      name
      active
      endDate
      currentSessionId
      playlist {
        id
        name
        songs {
          artist
        }
      }
      code {
        code
      }
    }
  }
`;

export const GET_EVENT_BY_ID_WITH_FULL_PLAYLIST = (eventId: any) => gql`
  {
    event(id: ${eventId}) {
      id
      name
      active
      endDate
      currentSessionId
      playlist {
        id
        name
        songs {
          artist
        }
      }
      code {
        code
      }
      activities {
        id
        type
      }
    }
  }
`;

export const GET_PAST_SESSIONS = gql`
  query ($eventId: Int!) {
    event(id: $eventId) {
      id
      sessions {
        id
        played
        songVotes {
          song {
            id
            artist
            title
          }
          user {
            id
          }
          session {
            id
          }
        }
      }
    }
  }
`;

export const EDIT_EVENT = gql`
  mutation EditEvent($editEventInput: EditEventInput!) {
    editEvent(editEventInput: $editEventInput) {
      id
      name
      djName
      startDate
      endDate
      location
      active
      playlist {
        id
      }
    }
  }
`;

export const EDIT_LIVE_EVENT = gql`
  mutation EditEvent($editEventInput: EditEventInput!) {
    editEvent(editEventInput: $editEventInput) {
      id
      name
      active
      endDate
      currentSessionId
      playlist {
        id
        name
        songs {
          artist
        }
      }
      code {
        code
      }
    }
  }
`;

export const START_EVENT = gql`
  mutation StartEvent($eventId: Int!) {
    startEvent(eventId: $eventId) {
      id
    }
  }
`;

export const START_SESSION = gql`
  mutation StartSession($eventId: Int!) {
    startSession(eventId: $eventId) {
      currentSessionId
    }
  }
`;

export const STOP_EVENT = gql`
  mutation ($eventId: Int!) {
    stopEvent(eventId: $eventId) {
      id
      name
      active
      endDate
      currentSessionId
      playlist {
        id
        name
        songs {
          artist
        }
      }
      code {
        code
      }
    }
  }
`;

export const EVENT_UPDATES_SUBSCRIPTION = gql`
  subscription {
    eventUpdates {
      id
      active
      endDate
      code {
        code
      }
    }
  }
`;

export const GET_EVENT_LOBBY_DATA = gql`
  query ($eventId: Int!) {
    event(id: $eventId) {
      name
      active
      djName
      location
      startDate
    }
  }
`;

export const GET_EVENT_ACTIVE_LOBBY_DATA = gql`
  {
    eventActive {
      id
      name
      active
      djName
      location
      startDate
      code {
        code
      }
    }
  }
`;

export const GET_GUEST_EVENT = gql`
  query ($eventId: Int!) {
    event(id: $eventId) {
      id
      name
      djName
      currentSessionId
      active
      activities {
        id
        type
      }
      playlist {
        songs {
          id
        }
      }
    }
  }
`;

export const EVENT_TRACK_SUBSCRIPTION = gql`
  subscription {
    playlistUpdates {
      id
      playlist {
        songs {
          id
        }
      }
    }
  }
`;

export const DELETE_EVENT = gql`
  mutation RemoveEvent($removeEventId: Int!) {
    removeEvent(removeEventId: $removeEventId) {
      id
    }
  }
`;

export const FEEDBACK_SUBSCRIPTION = gql`
  subscription {
    feedbackSubscription {
      id
    }
  }
`;

export const REQUEST_FEEDBACK = gql`
  mutation ($eventId: Int!) {
    requestFeedback(eventId: $eventId) {
      id
    }
  }
`;
