import { Song } from '../common/services/youtube.service';
import Waves from '../assets/img/waves.svg';

const SongResultItem = ({
  song,
  onClick,
  classNames,
}: {
  song: Song;
  onClick: any;
  classNames?: string;
}) => {
  return (
    <div
      className={`flex cursor-pointer p-3 hover:opacity-70 ${classNames}`}
      onClick={() => onClick(song)}
    >
      <div className="flex w-[10%] items-center justify-center">
        <img src={Waves} height={30} width={30} alt="a" />
      </div>
      <div className="jutify-start flex w-[50%] items-center px-2">
        <p className="w-full truncate text-xs text-white">{song.artist}</p>
      </div>
      <div className="jutify-start flex w-[40%] items-center">
        <p className="w-full text-xs text-white sm:truncate">{song.title}</p>
      </div>
    </div>
  );
};

export default SongResultItem;
