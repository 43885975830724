import { IUserPayment } from '../../common/models/user-payment.model';
import API from '../api';

export const requestPaymentSecret = async (amount: number) => {
  return API.post('payment/intent', { amount }).then((res) => res.data);
};

export const getUserPayments = async (userId: number): Promise<IUserPayment[]> => {
  return API.get(`payment/${userId}`).then((res) => res.data);
};
