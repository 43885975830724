import React, { useContext, useEffect, useState } from 'react';
import {
  Form,
  FormGroup,
  Input,
  Label,
  InputGroup,
  InputGroupText,
  FormFeedback,
} from 'reactstrap';
import ModuleMobileLayout from '../components/module-mobile-layout';
import PrimaryButton from '../components/primary-button';
import { useMutation } from '@apollo/client';
import { CREATE_PROFILE } from '../common/graphql/user.graphql';
import { AuthContext } from '../common/auth/auth.context';
import { MESSAGES } from '../common/constants/messages';
import { ToastType } from '../common/models/toast-type.enum';
import useToast from '../common/hooks/useToast';
import { ERRORS } from '../common/constants/errors';
import Frame from '../assets/img/juke-logo.png';
import { useNavigate } from 'react-router-dom';

export function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(' ');
}

const GuestProfile = () => {
  const [username, setUsername] = useState<string | null>(null);
  const [agreement, setAgreement] = useState<boolean>(false);
  const [submited, setSubmited] = useState<boolean>(false);
  const router = useNavigate();
  const [createProfile] = useMutation(CREATE_PROFILE);

  const { profile, setProfile } = useContext(AuthContext);

  useEffect(() => {
    setUsername(sessionStorage.getItem('username'));
    setAgreement(
      sessionStorage.getItem('agreement') ? sessionStorage.getItem('agreement') === 'true' : false,
    );
  }, []);

  const onSubmit = async () => {
    setSubmited(true);
    if (username && agreement) {
      // call this
      try {
        const { data } = await createProfile({
          variables: {
            newProfileInput: {
              userId: profile?.id,
              username,
              agreement,
            },
          },
        });

        setProfile({
          id: profile?.id as number,
          role: profile?.role as string,
          profile: data.createProfile.profile,
        });
        sessionStorage.removeItem('username');
        sessionStorage.removeItem('agreement');

        router(`/guest/lobby`, { replace: true });
        useToast(MESSAGES.guest.profile.create, ToastType.SUCCESS);
      } catch (error: any) {
        useToast(
          error?.message === 'USER_03' ? ERRORS.guest.profile.unique : ERRORS.guest.profile.create,
          ToastType.ERROR,
        );
        console.error(error);
      }
    }
  };

  const onUsernameChange = (event: any) => {
    setUsername(event.target.value);
    sessionStorage.setItem('username', event.target.value);
  };

  const onAgreementChange = (event: any) => {
    setAgreement(event.target.checked);
    sessionStorage.setItem('agreement', event.target.checked);
  };

  return (
    <ModuleMobileLayout className="bg-black bg-gradient-to-b from-[#000]/70 to-[#EF5DA8]/70">
      <section id="top" className="flex h-[40%] w-full items-start justify-center overflow-hidden">
        <div className="flex flex-col items-center justify-center gap-1 py-4 text-center text-4xl text-white">
          Welcome to
          <img className="mt-2 h-[70%] w-[70%]" src={Frame} alt="me" />
        </div>
      </section>
      <section id="bottom" className="absolute bottom-0 flex min-h-[60%] w-full flex-col">
        <svg xmlns="http://www.w3.org/2000/svg" className="-mb-[1px]">
          <path
            d="M178.503 131.238C78.3028 121.07 12.7754 80.7431 -12 114.504V834H440V52.313C386.162 60.2433 271.979 -40.5392 271.979 23.2968C271.979 56.3754 264.288 139.942 178.503 131.238Z"
            fill="#fff"
            fillOpacity="1"
            stroke="url(#paint0_radial_1137_2515)"
            strokeWidth="4"
          />
        </svg>
        <div className="flex flex-1 flex-col bg-white px-4">
          <h1 className="text-xl   font-bold text-black">Create your profile</h1>
          <Form
            className="flex flex-grow flex-col gap-4 pt-4"
            onSubmit={(ev: any) => ev.preventDefault()}
          >
            <FormGroup>
              <Label for="username" className="mb-0 text-[#808080]">
                Choose a unique username
              </Label>
              <InputGroup>
                <InputGroupText
                  className={classNames(
                    submited && username === null ? 'border-[#dc3545]' : 'border-gray',
                    ' font-bold" bg-white py-0 pl-2 pr-0 text-2xl',
                  )}
                >
                  @
                </InputGroupText>
                <Input
                  className="border-l-0 border-gray py-3 text-xl"
                  type="text"
                  name="username"
                  id="username"
                  defaultValue={username || undefined}
                  placeholder="Username"
                  onChange={onUsernameChange}
                  invalid={submited && username === null}
                />
                <FormFeedback>Username is required.</FormFeedback>
              </InputGroup>
            </FormGroup>
            <FormGroup
              check
              className={classNames('px-[1.5rem]', submited && !agreement ? 'mb-4' : '')}
            >
              <Label check className="flex items-center justify-start gap-2">
                <Input
                  type="checkbox"
                  className="h-6 w-6 rounded-full active:border-main active:bg-main"
                  checked={agreement}
                  invalid={submited && !agreement}
                  onChange={onAgreementChange}
                />
                <span>
                  {' '}
                  Agree{' '}
                  <span
                    className="cursor-pointer font-bold"
                    onClick={(event: any) => {
                      event.preventDefault();
                      router('/guest/terms');
                    }}
                  >
                    Terms & Conditions
                  </span>
                </span>
              </Label>
              {submited && !agreement && (
                <div className="ml-2 mt-[-.5rem] text-[.875em] text-[#dc3545]">
                  The agreement is required.
                </div>
              )}
            </FormGroup>
            <div id="controls" className="mb-4 mt-auto flex w-full flex-col">
              <PrimaryButton color="bg-main" onClick={onSubmit}>
                Confirm Profile
              </PrimaryButton>
            </div>
          </Form>
        </div>
      </section>
    </ModuleMobileLayout>
  );
};

export default GuestProfile;
