import { useContext, useEffect, useState } from 'react';
import { Button, FormGroup } from 'reactstrap';
import { searchYoutube, Song } from '../common/services/youtube.service';
import HorizontalLayout from '../components/horizontal-layout';
import SearchInput from '../components/search-input';
import Header from '../components/header';
import PrimaryButton from '../components/primary-button';
import PrimaryInput from '../components/primary-input';
import { useNavigate, useParams } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  DELETE_PLAYLIST,
  EDIT_PLAYLIST,
  GET_PLAYLIST_BY_ID,
} from '../common/graphql/playlist.graphql';
import SongResultItem from '../components/song-result-item';
import PlaylistSong from '../components/playlist-song';
import { AuthContext } from '../common/auth/auth.context';
import Loading from '../components/loading';
import WithConfirm from '../components/with-confirmation';
import { ToastType } from '../common/models/toast-type.enum';
import { ERRORS } from '../common/constants/errors';
import useToast from '../common/hooks/useToast';
import { MESSAGES } from '../common/constants/messages';
import { v4 as uuidv4 } from 'uuid';

const Playlist = () => {
  const router = useNavigate();
  let { id } = useParams();

  const { profile } = useContext(AuthContext);
  // youtube search
  const [searchWord, setSearchWord] = useState<string>('');
  const [searchResults, setSearchResults] = useState<Song[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSongSubmited, setIsSongSubmited] = useState<boolean>(false);
  const [isSubmited, setIsSubmited] = useState<boolean>(false);
  const [selectedSong, setSelectedSong] = useState<number | string | null>(
    null,
  );
  const [customSong, setCustomSong] = useState<{
    title: string;
    artist: string;
  }>({
    title: '',
    artist: '',
  });
  // selected songs
  const [songs, setSongs] = useState<Song[]>([]);
  const [newSongs, setNewSongs] = useState<Song[]>([]);
  const [removedSongIs, setRemovedSongIds] = useState<string[]>([]);
  // playlist name
  const [playlistName, setPlaylistName] = useState<string>('');
  // api
  const [updatePlaylist] = useMutation(EDIT_PLAYLIST);
  const [getPlaylistById] = useLazyQuery(GET_PLAYLIST_BY_ID(id));
  const [deletePlaylist] = useMutation(DELETE_PLAYLIST);

  // INIT playlist
  useEffect(() => {
    // load playlist data
    initPlaylist();
  }, []);

  const initPlaylist = async () => {
    try {
      const { data } = await getPlaylistById();
      setPlaylistName(data.playlist.name);
      setSongs(data.playlist.songs);
    } catch (error) {
      useToast(ERRORS.dj.playlist.edit.get, ToastType.ERROR);
      console.error(error);
    }
  };

  // search for youtube songs
  const searchSong = async () => {
    setIsLoading(true);
    const values = await searchYoutube(searchWord);
    setSearchResults(values);
    setIsLoading(false);
  };

  const addSongToPlaylist = (
    song: { artist: string; title: string },
    isInput?: boolean,
  ) => {
    if (isInput) {
      setIsSongSubmited(true);
    }

    if (isInput && (!customSong.title || !customSong.artist)) {
      return;
    }

    if (!song.artist || !song.title) {
      useToast(ERRORS.dj.playlist.add.addSongFromYoutube, ToastType.ERROR);
      return;
    }
    // check if already in the playlist
    const foundIndex =
      [...songs, ...newSongs].findIndex(
        (eventSong) =>
          eventSong.title === song.title && eventSong.artist === song.artist,
      ) >= 0;

    if (!foundIndex) {
      if (isInput) {
        setCustomSong({ title: '', artist: '' });
      }
      setNewSongs([
        {
          ...song,
          id: uuidv4(),
        },
        ...newSongs,
      ]);
    }
    setIsSongSubmited(false);
  };

  const removeSongFromPlaylist = async (songId: string) => {
    // check if is in db
    const foundIndex = songs.findIndex((item) => item.id === songId);
    if (foundIndex) {
      setRemovedSongIds([...removedSongIs, songId]);
    }

    // remove from both lists
    const updatedPlaylist = songs.filter(
      (eventSong) => eventSong.id !== songId,
    );
    const updateNewPlaylist = newSongs.filter(
      (eventSong) => eventSong.id !== songId,
    );
    setSongs(updatedPlaylist);
    setNewSongs(updateNewPlaylist);
    setSelectedSong(null);
  };

  // const editSongFromPlaylist = async (songId: any, updates: Partial<Song>) => {
  //   const allSongs = [...songs];
  //   const allNewSongs = [...newSongs];

  //   const updatedSongs = allSongs.map((song) => {
  //     if (song.id === songId) {
  //       return {
  //         ...song,
  //         ...updates,
  //       };
  //     }

  //     return { ...song };
  //   });

  //   const updatedNewSongs = allNewSongs.map((song) => {
  //     if (song.id === songId) {
  //       return {
  //         ...song,
  //         ...updates,
  //       };
  //     }

  //     return { ...song };
  //   });

  //   setSongs(updatedSongs);
  //   setNewSongs(updatedNewSongs);
  //   setSelectedSong(null);
  // };

  const onUpdatePlaylist = async () => {
    setIsSubmited(true);
    if (!playlistName || !profile) {
      return;
    }

    if ([...songs, ...newSongs].length === 0) {
      useToast(ERRORS.dj.playlist.add.requiredSong, ToastType.ERROR);
      return;
    }

    try {
      await updatePlaylist({
        variables: {
          editPlaylist: {
            id: id ? +id : null,
            name: playlistName,
            addedSongs: newSongs.map((item) => {
              // remove id from song payload
              const { id, ...data } = item;
              return data;
            }),
            removedSongs: removedSongIs,
          },
        },
      });
      useToast(MESSAGES.dj.playlist.edit.update, ToastType.SUCCESS);
      router(-1);
    } catch (error) {
      useToast(ERRORS.dj.playlist.edit.update, ToastType.ERROR);
      console.error(error);
    }
    setIsSubmited(false);
  };

  const onDeletePlaylist = async () => {
    try {
      await deletePlaylist({
        variables: {
          deletePlaylistId: id ? +id : null,
        },
      });
      useToast(MESSAGES.dj.playlist.edit.delete, ToastType.SUCCESS);
      router(-1);
    } catch (error: any) {
      useToast(
        error.message === 'PLAYLIST_02'
          ? ERRORS.dj.playlist.edit.attached
          : ERRORS.dj.playlist.edit.delete,
        ToastType.ERROR,
      );
      console.error(error);
    }
  };

  return (
    <HorizontalLayout background="bg-desktop" canGoBack>
      <main className="flex h-full w-full flex-col">
        <section id="header" className="h-[10%]">
          <Header title="Edit your playlist" subtitle="Don't forget to save" />
        </section>
        <section
          id="playlists"
          className="flex h-[80%] flex-wrap overflow-y-auto"
        >
          <div className="flex h-full w-full min-w-[400px] flex-1 flex-col px-2">
            <div id="search-youtube" className="h-[12%] py-4">
              <SearchInput
                value={searchWord}
                onChange={setSearchWord}
                onKeyPress={searchSong}
                onClearText={() => setSearchWord('')}
              />
            </div>
            <div id="top" className="h-[71%] w-full">
              <div className="gradient-background h-full max-h-[30rem] w-full overflow-y-auto rounded-lg">
                {!isLoading && (
                  <>
                    {searchResults.map((song, index) => (
                      <SongResultItem
                        key={index}
                        song={song}
                        onClick={addSongToPlaylist}
                      />
                    ))}
                  </>
                )}
                {searchResults.length === 0 && !isLoading && (
                  <div className="flex h-full w-full items-center justify-center">
                    <p className="font-sm font-thin text-white">
                      No items to show
                    </p>
                  </div>
                )}
                {isLoading && <Loading />}
              </div>
            </div>
            <div id="name" className="h-[17%] min-h-[110px] w-full pt-3">
              <div className="gradient-background flex h-full w-full flex-col rounded-lg">
                <span className="p-2 text-xs font-thin text-white">
                  Insert Song Manually
                </span>
                <div className="flex h-full w-full items-start px-2">
                  <FormGroup className="mb-0 flex-2 pr-2">
                    <PrimaryInput
                      id="artist"
                      type="text"
                      placeholder="Artist"
                      errorMessage="Missing Artist Name"
                      value={customSong.artist}
                      invalid={isSongSubmited && customSong.artist.length === 0}
                      onChange={(ev: any) =>
                        setCustomSong({
                          title: customSong?.title || '',
                          artist: ev.target.value,
                        })
                      }
                    />
                  </FormGroup>
                  <FormGroup className="mb-0 flex-2  pr-2">
                    <PrimaryInput
                      id="name"
                      type="text"
                      placeholder="Song Name"
                      invalid={isSongSubmited && customSong.title.length === 0}
                      value={customSong.title}
                      errorMessage="Missing Sonng Title"
                      onChange={(ev: any) =>
                        setCustomSong({
                          artist: customSong?.artist || '',
                          title: ev.target.value,
                        })
                      }
                    />
                  </FormGroup>
                  <div className="flex flex-1 cursor-pointer justify-end">
                    <Button
                      onClick={() => addSongToPlaylist(customSong, true)}
                      className="flex h-[2rem] min-w-[7rem] items-center justify-center rounded-lg border-solid p-0 border-gradient-br-input-border-neon gradient-border-2 focus:outline-none"
                    >
                      <div className="flex h-full w-full items-center justify-center rounded-md bg-neon-red bg-gradient-to-tr from-[#6EB8FE] via-[#6EB8FE] to-[#D266AA] text-xs">
                        Add Song
                      </div>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="h-full min-w-[400px] flex-1 px-2">
            <div
              id="wrapper"
              className="relative h-full w-full rounded-lg bg-gradient-to-bl from-[#6FB8FF]/50 to-[#74002F]/50"
            >
              <div id="playlist-name" className="h-[15%] px-4 pb-4 pt-[2rem]">
                <PrimaryInput
                  id="playlistName"
                  type="text"
                  errorMessage="Please add a playlist name"
                  invalid={isSubmited && playlistName.length === 0}
                  placeholder="Insert playlist name "
                  value={playlistName}
                  onChange={(ev: any) => setPlaylistName(ev.target.value)}
                />
              </div>
              <div id="table" className="flex h-[85%] flex-col">
                <div className="flex cursor-pointer flex-nowrap overflow-hidden border-b-2 border-[#D266AA] py-2 px-14 text-xs">
                  <div className="flex flex-1 items-center justify-start">
                    <p className="text-white">No.</p>
                  </div>
                  <div className="flex flex-1 items-center justify-start px-2">
                    <p className="text-white">Artist</p>
                  </div>
                  <div className="flex flex-2 items-center justify-start px-2">
                    <p className="text-white">Title</p>
                  </div>
                </div>
                <div className="h-full max-h-[30rem] w-full overflow-y-auto px-4">
                  {[...songs, ...newSongs].length > 0 && (
                    <>
                      {[...newSongs, ...songs].map((song, index) => (
                        <PlaylistSong
                          index={index}
                          key={index}
                          song={song}
                          onSelect={setSelectedSong.bind(null, song.id)}
                          active={song.id === selectedSong}
                          onRemoveSong={removeSongFromPlaylist}
                          // onEditSong={editSongFromPlaylist}
                          canEdit={false}
                        />
                      ))}
                    </>
                  )}
                  {[...songs, ...newSongs].length === 0 && (
                    <div className="flex h-full w-full items-center justify-center">
                      <p className="font-sm font-thin text-white">
                        No items to show
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="actions" className="mt-4 flex justify-between p-2">
          <WithConfirm
            onConfirm={onDeletePlaylist}
            confirmText="Delete the playlist"
            subtitle="This action will delete this playlist and is ireversible. Are you sure?"
          >
            <PrimaryButton color="bg-transparent">
              <span className="px-4 text-[#FF0000]">Delete Playlist</span>
            </PrimaryButton>
          </WithConfirm>
          <div className="flex flex-row-reverse">
            <PrimaryButton color="bg-main" onClick={onUpdatePlaylist}>
              <span className="px-4">Save</span>
            </PrimaryButton>
            <PrimaryButton color="bg-transparent" onClick={() => router(-1)}>
              Cancel
            </PrimaryButton>
          </div>
        </section>
      </main>
    </HorizontalLayout>
  );
};

export default Playlist;
