import { AiTwotoneHeart } from 'react-icons/ai';
import { TbMoodSad } from 'react-icons/tb';
import { FEEDBACK_FORM } from '../common/constants/links';
import ButtonWrapper from './button-wrapper';
import PrimaryButton from './primary-button';

const GuestSessionWinner = ({
  winner,
  onClick,
}: {
  winner: { title: string; artist: string; votes: any[] };
  onClick: () => void;
}) => {
  return (
    <section
      id="session-winner"
      className="scroll-section flex h-full flex-col items-center justify-center gap-3 overflow-scroll"
    >
      {winner && (
        <div id="title" className="text-xl">
          And the last session winner is
        </div>
      )}
      <div
        id="winner-song"
        className='backdrop-opacity-50" mb-4 flex w-[80%] max-w-[300px] flex-col items-center justify-around rounded-lg border-[1px] border-solid bg-gradient-to-bl from-[#6FB8FF]/40 to-[#74002F]/40 p-4'
      >
        {winner && (
          <>
            <span id="song-name" className="text-center text-sm font-semibold">
              {winner?.title}
            </span>
            <span id="artist-name" className="text-center text-sm">
              {winner?.artist}
            </span>
            <span id="votes" className="mt-2 flex text-3xl font-semibold">
              {winner?.votes.length}{' '}
              <AiTwotoneHeart size={36} color="#EF5DA8" className="ml-2 animate-pulse" />
            </span>
          </>
        )}
        {!winner && (
          <>
            <span id="song-name" className="text-center text-xl font-semibold">
              No winner this session
            </span>
            <span id="votes" className="mt-4 flex text-3xl font-semibold">
              <TbMoodSad size={36} color="#EF5DA8" className="ml-2" />
            </span>
          </>
        )}
      </div>
      <div id="message" className="text-center text-xl">
        Got a new grooving idea?
      </div>
      <ButtonWrapper onClick={() => onClick()} id="requestButtonSessionWinner" classNames="!p-6">
        <p className="text-md pointer-events-none font-semibold">Request a Song</p>
      </ButtonWrapper>
    </section>
  );
};

export default GuestSessionWinner;
