import axios from 'axios';

// https://vitejs.dev/guide/env-and-mode.html
const API = axios.create({
  baseURL: `${process.env.REACT_APP_REST_SERVER_URL}`,
  timeout: 100000,
  headers: {
    'Content-Type': 'application/json',
  },
});

export default API;
