import React, { ReactNode } from 'react';
const RoundButtonWrapper = ({
  children,
  classNames,
  onClick,
}: {
  children: ReactNode;
  classNames?: string;
  onClick?: () => any;
}) => {
  return (
    <div
      onClick={onClick}
      className={`${classNames} flex h-10 min-h-fit w-10 min-w-fit items-center justify-center rounded-full bg-white-60 p-1 backdrop-blur-md`}
    >
      {children}
    </div>
  );
};

export default RoundButtonWrapper;
