import { Power } from 'react-feather';
import { useContext, useEffect, useState } from 'react';
import WithConfirm from './with-confirmation';
// import DesktopBG from '../assets/img/background-desktop.png';
import MobileBG from '../assets/img/background-mobile.png';
import { loadImage } from '../common/services/preload-images.service';
import LoadingScreen from './loading-screen';
import PrimaryButton from './primary-button';
import { Button, Modal, ModalBody } from 'reactstrap';
import QRCODE from '../assets/img/qrcode_juke.png';
import { BsFillShareFill } from 'react-icons/bs';
import { RotateCw } from 'react-feather';
import { AuthContext } from '../common/auth/auth.context';

const preloadImages = [MobileBG];

const EventLobbyMobileLayout = ({
  children,
  canGoBack,
  className,
  onLeave,
  isLoading,
}: {
  onLeave?: any;
  children?: React.ReactNode;
  canGoBack?: boolean;
  className?: string;
  isLoading?: boolean;
}) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [wrapperLoading, setWrapperLoading] = useState(true);
  const { logout } = useContext(AuthContext);
  useEffect(() => {
    Promise.all(preloadImages.map((path) => loadImage(path))).then(() => {
      setWrapperLoading(false);
    });
  }, []);

  return (
    <>
      {(isLoading || wrapperLoading) && <LoadingScreen />}
      {!isLoading && !wrapperLoading && (
        <section className="relative h-full">
          <div
            className={`gradient-background hidden h-screen flex-col items-center justify-center bg-cover bg-center bg-no-repeat py-16 px-24 lg:flex`}
          >
            <h1 className="flex flex-col items-center justify-center text-3xl text-white">
              <div>Please open this view on a mobile device</div>
              <PrimaryButton color="bg-main" onClick={logout}>
                Logout
              </PrimaryButton>
            </h1>
          </div>
          <div
            className={`gradient-background relative h-fit bg-cover bg-top bg-no-repeat lg:hidden ${className} min-h-[100vh]`}
          >
            <section className="relative flex h-fit items-center justify-between p-3">
              <div className="tetx-white h-7 w-20 bg-juke-logo bg-contain bg-center bg-no-repeat"></div>
              <WithConfirm
                onConfirm={logout}
                confirmText="Logging out..."
                subtitle="We will wait you to come back later!"
              >
                <div>
                  <Power size={30} className="text-white" />
                </div>
              </WithConfirm>
            </section>
            <section className="flex h-full p-3">{children}</section>
          </div>
          <p className="absolute bottom-6 flex w-full justify-center text-center text-xs text-white">
            Powered by <br /> Tribus Technologies
          </p>
        </section>
      )}
    </>
  );
};

export default EventLobbyMobileLayout;
