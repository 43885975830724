import API from '../api';

export interface IMemoryPayload {
  eventId: number;
  description: string;
  photo: File;
  userId: number;
}

export interface ILikeMemoryPayload {
  memoryId: number;
  userId: number;
}

export const postMemory = async (memory: IMemoryPayload) => {
  const formData = new FormData();

  formData.append('eventId', memory.eventId.toString());
  formData.append('description', memory.description);
  formData.append('photo', memory.photo);
  formData.append('userId', memory.userId.toString());

  return API.post('memories', formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  }).then((res) => res.data);
};

export const likeMemory = async ({ memoryId, userId }: ILikeMemoryPayload) => {
  return API.post(`memories/${memoryId}/like`, { userId }).then(
    (res) => res.data,
  );
};

export const getMemories = async (eventId: number) => {
  return API.get(`memories/${eventId}`).then((res) => res.data);
};
