import { useMutation } from '@apollo/client';
import { Auth } from 'aws-amplify';
import {
  createContext,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { LOGIN } from '../graphql/auth.graphql';
import { Profile } from '../models/profile.model';

const AuthContext = createContext({
  loadingProfile: false,
  isAuthenticated: false,
  profile: null as null | Profile,
  setAuthState: {} as Dispatch<SetStateAction<{ isAuthenticated: boolean }>>,
  setProfile: (profile: Profile | null) => {},
  logout: () => null,
});

const { Provider } = AuthContext;

const AuthProvider = ({ children }: any) => {
  const [profile, setProfile] = useState<Profile | null>(null);
  const [authState, setAuthState] = useState({
    isAuthenticated: false,
  });
  const [login, { error }] = useMutation(LOGIN);
  const [loadingProfile, setLoadingProfile] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      try {
        setLoadingProfile(true);
        const user = await Auth.currentAuthenticatedUser();
        const { data } = await login({
          variables: { name: user.signInUserSession.idToken.payload.email },
        });
        const { id, role, profile } = data.login;
        setAuthState({ isAuthenticated: true });
        setProfile({ id, role, profile });
      } catch (error) {
        logout();
      } finally {
        setLoadingProfile(false);
      }
    })();
  }, []);

  useEffect(() => {
    if (profile) localStorage.setItem('profile', JSON.stringify(profile));
  }, [profile]);

  const logout: any = async () => {
    await Auth.signOut();
    setAuthState({ isAuthenticated: false });
    setProfile(null);
    localStorage.removeItem('profile');
  };

  useEffect(() => {
    if (error) {
      logout();
    }
  }, [error]);

  return (
    <Provider
      value={{
        profile,
        setProfile,
        ...authState,
        setAuthState,
        logout,
        loadingProfile,
      }}
    >
      {children}
    </Provider>
  );
};

export { AuthContext, AuthProvider };
