import { useEffect, useState } from 'react';
import { Edit, Minus, PenTool } from 'react-feather';
import { Song } from '../common/services/youtube.service';
import PrimaryInput from './primary-input';

const PlaylistSong = ({
  index,
  song,
  onRemoveSong,
  onEditSong,
  active,
  onSelect,
  canEdit = false,
}: {
  index: number;
  song: Song;
  onRemoveSong: any;
  onEditSong?: any;
  active?: boolean;
  onSelect?: any;
  canEdit?: boolean;
}) => {
  // const [active, setActive] = useState<boolean>(false);

  const [activeSong, setActiveSong] = useState<Song>(song);

  useEffect(() => {
    setActiveSong(song);
  }, [song]);

  const onRemoveItem = (event: any) => {
    event.stopPropagation();
    onRemoveSong(song.id);
  };

  const onEditItem = (event: any) => {
    event.stopPropagation();
    onEditSong(song.id, activeSong);
  };

  return (
    <div
      onClick={(event: any) => {
        event.stopPropagation();
        onSelect(!active);
      }}
      className={`my-2 ml-2 mr-1 flex cursor-pointer flex-nowrap overflow-hidden px-8 py-2 text-xs hover:opacity-70 ${
        active ? ' rounded-lg bg-gray-selected' : ''
      }`}
    >
      <div className="flex flex-1 items-center justify-start">
        <p className="text-white">{index + 1}</p>
      </div>
      <div className="flex  flex-1 items-center justify-start overflow-hidden px-2">
        {active && canEdit ? (
          <PrimaryInput
            id="artist"
            type="text"
            placeholder="Artist"
            errorMessage="Missing Artist Name"
            value={activeSong.artist}
            invalid={activeSong.artist.length === 0}
            onChange={(ev: any) => {
              setActiveSong({
                ...activeSong,
                artist: ev.target.value,
              });
            }}
          />
        ) : (
          <p className="w-full truncate text-white">{activeSong.artist}</p>
        )}
      </div>
      <div className="relative  flex  flex-2 items-center justify-start overflow-hidden px-2">
        {active && canEdit ? (
          <PrimaryInput
            id="title"
            type="text"
            placeholder="Title"
            errorMessage="Missing Title Name"
            value={activeSong.title}
            invalid={activeSong.title.length === 0}
            onChange={(ev: any) => {
              setActiveSong({
                ...activeSong,
                title: ev.target.value,
              });
            }}
          />
        ) : (
          <p className="w-full truncate text-white">{activeSong.title}</p>
        )}
        {active && (
          <Minus
            className="h-full text-[#CB1212]"
            size={18}
            onClick={onRemoveItem}
          />
        )}
        {active &&
          canEdit &&
          (song.title !== activeSong.title ||
            song.artist !== activeSong.artist) && (
            <Edit
              className="ml-2 h-full text-[green]"
              size={18}
              onClick={onEditItem}
            />
          )}
      </div>
    </div>
  );
};

export default PlaylistSong;
