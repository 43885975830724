import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import aws from '../common/auth/aws.service';
import { Hub } from 'aws-amplify';
import { AuthContext } from '../common/auth/auth.context';
import versionService from '../common/services/version.service';
import { Link } from 'react-router-dom';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import GoogleButton from '../components/GoogleButton';
import FacebookButton from '../components/FacebookButton';
import MobileBG from '../assets/img/background-mobile.png';
import { loadImage } from '../common/services/preload-images.service';
import LoadingScreen from '../components/loading-screen';
import Frame from '../assets/img/juke-logo.png';
import PrimaryButton from '../components/primary-button';
import { Spinner } from 'reactstrap';

const preloadImages = [MobileBG];

const Login = () => {
  const navigate = useNavigate();
  const { profile, loadingProfile } = useContext(AuthContext);

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    Promise.all(preloadImages.map((path) => loadImage(path))).then(() => {
      setLoading(false);
    });

    const unsubscribe = Hub.listen('auth', ({ payload: { event, data } }) => {
      console.log('auth event', event);
      console.log('auth data', data);
    });

    return unsubscribe;
  }, []);

  useEffect(() => {
    if (profile?.role === 'guest') {
      navigate('/guest', { replace: true });
    }
    if (profile?.role === 'admin') {
      navigate('/dj', { replace: true });
    }
  }, [profile]);

  const signInWithCognito = () => {
    aws.federatedSignIn(CognitoHostedUIIdentityProvider.Cognito);
  };

  const signInWithFacebook = () => {
    aws.federatedSignIn(CognitoHostedUIIdentityProvider.Facebook);
    // getProfile('juke.tribus@gmail.com'); // DJ to hardcode
  };

  const signInWithGoogle = () => {
    aws.federatedSignIn(CognitoHostedUIIdentityProvider.Google);
    // getProfile('radulescuandrew@gmail.com'); // GUEST to hardcode
  };

  return (
    <section>
      {loading && <LoadingScreen />}
      {!loading && (
        <div className="md:bg-frame flex h-screen flex-col items-center justify-center bg-framemobile bg-cover bg-top bg-no-repeat">
          <div className=" absolute top-[5rem] flex h-[6rem] flex-col items-center justify-between">
            <h1 className="text-3xl text-white">Welcome to</h1>
            <img src={Frame} alt="me" width="150" height="150" />
          </div>
          <div className="flex h-[180px] w-[350px] flex-col items-center justify-around  p-2">
            {!loadingProfile ? (
              <>
                <GoogleButton onClick={signInWithGoogle}>{'Sign in with Google'}</GoogleButton>
                <FacebookButton onClick={signInWithFacebook}>
                  {'Sign in with Facebook'}
                </FacebookButton>
                <PrimaryButton
                  className="!min-w-[240px]"
                  color="bg-main"
                  onClick={signInWithCognito}
                >
                  Sign In
                </PrimaryButton>
              </>
            ) : (
              <div className="flex h-full w-full items-center justify-center p-4">
                <Spinner type="grow" size="sm" className="bg-main">
                  Loading...
                </Spinner>
                <Spinner type="grow" size="sm" className="bg-main">
                  Loading...
                </Spinner>
                <Spinner type="grow" size="sm" className="bg-main">
                  Loading...
                </Spinner>
                <Spinner type="grow" size="sm" className="bg-main">
                  Loading...
                </Spinner>
              </div>
            )}
          </div>
          <div className="absolute bottom-[56px] flex h-[50px] w-full flex-col items-center justify-around">
            <Link to="/login/terms">
              <span className="cursor-pointer text-xs text-main hover:text-main">
                Terms & Conditions
              </span>
            </Link>
            <Link to="/login/policy">
              <span className="cursor-pointer text-xs text-main hover:text-main">
                Privacy Policy
              </span>
            </Link>
          </div>
          <div className="absolute bottom-[1rem] text-white">{versionService.getVersion()}</div>
        </div>
      )}
    </section>
  );
};

export default Login;
