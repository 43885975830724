import { useLazyQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { Spinner } from 'reactstrap';
import { GET_PARTICIPANTS } from '../common/graphql/user.graphql';
import ButtonWrapper from '../components/button-wrapper';

interface ParticipantItemProps {
  index: number;
  name: string;
  votes: number;
}

const ParticipantItem = ({ index, name, votes }: ParticipantItemProps) => {
  return (
    <div className="participant-item flex w-full flex-row items-center justify-between gap-4 py-2">
      <div id="index" className="flex">
        <ButtonWrapper classNames="!p-0">{index}</ButtonWrapper>
      </div>
      <span className="w-full text-sm font-bold">{name}</span>
      <span className="whitespace-nowrap text-sm font-bold">{votes} votes</span>
    </div>
  );
};

const Participants = ({
  eventId,
  onScroll,
}: {
  eventId: number;
  onScroll: (scrollValue: number) => void;
}) => {
  const [participants, setParticipants] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [getEventUsers, {}] = useLazyQuery(GET_PARTICIPANTS, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (eventId) initParticipants(eventId);
  }, [eventId]);

  const initParticipants = async (eventId: number) => {
    try {
      const { data } = await getEventUsers({ variables: { eventId } });
      setParticipants(data.participants || []);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <section
      id="participants"
      className="gradient-background mt-4 flex min-h-0 w-full grow flex-col rounded-t-[1.25rem]"
    >
      <span className="flex w-full flex-row items-center px-6 pt-2 font-bold text-neon-pink">
        <p>Participants</p>
      </span>
      {isLoading && (
        <div className="mt-20 flex h-full w-full items-start justify-center text-neon-pink">
          <Spinner />
        </div>
      )}
      {!isLoading && participants?.length > 0 && (
        <div
          id="playlists"
          className="mt-[1rem] flex grow flex-col overflow-auto px-6"
          onScroll={(event) => {
            const scrollItem = document.querySelector('#playlists');
            onScroll(scrollItem?.scrollTop || 0);
          }}
        >
          {participants.map((participant: any, index: number) => (
            <ParticipantItem
              key={index}
              index={index + 1}
              name={participant.name}
              votes={participant.votes}
            />
          ))}
        </div>
      )}
    </section>
  );
};

export default Participants;
