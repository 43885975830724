import { Input } from 'reactstrap';
import { X } from 'react-feather';
import ButtonWrapper from './button-wrapper';
import { useEffect, useState } from 'react';

// TODO: redo this
const MobileInput = ({
  value,
  onChange,
  onClearText,
  placeholder,
  disabled,
  required,
  setValid,
  isSubmited,
}: {
  value: string | undefined;
  onChange: any;
  onClearText: any;
  placeholder: string;
  disabled: boolean;
  required: boolean;
  isSubmited: boolean;
  setValid: (isValid: boolean) => void;
}) => {
  const [emojiError, setEmojiError] = useState<boolean | undefined>(undefined);
  const [inputRequiredError, setInputRequiredError] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    if (value !== undefined) {
      if (
        value.match(
          /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
        )
      ) {
        setEmojiError(true);
      } else {
        setEmojiError(false);
      }
    }
  }, [value]);

  useEffect(() => {
    if (emojiError !== undefined && inputRequiredError !== undefined) {
      if (emojiError || inputRequiredError) {
        setValid(false);
      } else {
        setValid(true);
      }
    }
  }, [emojiError, inputRequiredError]);

  useEffect(() => {
    if (isSubmited && !value) {
      setValid(false);
      setInputRequiredError(true);
    }
  }, [isSubmited]);

  return (
    <div className="flex w-full flex-col">
      <ButtonWrapper classNames="!w-full py-0 gap-1 !h-[2.8rem]">
        <Input
          disabled={disabled}
          type="text"
          placeholder={`${placeholder}${required ? '*' : ''}`}
          value={value}
          className="h-full w-full rounded-2xl p-0 text-white placeholder:text-xs placeholder:text-white focus:outline-none"
          style={{ backgroundColor: 'transparent', border: 'none' }}
          onChange={(event) => {
            if (event.target.value === '' && required) {
              setInputRequiredError(true);
            } else {
              setInputRequiredError(false);
            }
            onChange(event.target.value);
          }}
        />
        {(value?.length || 0) > 0 && (
          <X
            className="h-[1rem] w-[1rem] text-white"
            onClick={() => {
              if (required) {
                setInputRequiredError(true);
              }
              onClearText();
            }}
          />
        )}
      </ButtonWrapper>
      <p
        className={`${
          inputRequiredError || emojiError ? 'opacity-1' : 'opacity-0'
        } pb-3 text-xs font-semibold text-neon-red`}
      >
        {emojiError && `Input cannot contain emoji ${inputRequiredError ? <br /> : ''}`}
        {inputRequiredError && `* ${placeholder} is required`}
      </p>
    </div>
  );
};

export default MobileInput;
