import { Auth } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';

const signUp = async (username: string, password: string) => {
  try {
    const { user } = await Auth.signUp({
      username,
      password,
      attributes: {
        email: username,
      },
    });
    console.log(user);
  } catch (error) {
    console.log('error signing up:', error);
  }
};

const confirmSignUp = async (username: string, code: string) => {
  try {
    await Auth.confirmSignUp(username, code);
  } catch (error) {
    console.log('error confirming sign up', error);
  }
};

async function signOut() {
  try {
    localStorage.removeItem('profile');
    await Auth.signOut();
  } catch (error) {
    console.log('error signing out: ', error);
  }
}

const signIn = async (username: string, password: string) => {
  try {
    const user = await Auth.signIn(username, password);
  } catch (error) {
    console.log('error signing in', error);
  }
};

const federatedSignIn = async (provider: CognitoHostedUIIdentityProvider) => {
  try {
    const response = await Auth.federatedSignIn({
      provider,
    });
    console.log('federate response', response);
  } catch (error) {
    console.error('error', error);
  }
};

export default { federatedSignIn, signOut };
