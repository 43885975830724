import { ArrowLeft, Power } from 'react-feather';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import WithConfirm from './with-confirmation';
import { AuthContext } from '../common/auth/auth.context';

const HorizontalLayout = ({
  children,
  background,
  canGoBack,
}: {
  children?: React.ReactNode;
  background: string;
  canGoBack?: boolean;
}) => {
  const router = useNavigate();
  const { logout } = useContext(AuthContext);

  return (
    <div className={`h-screen w-screen ${background} bg-cover bg-center bg-no-repeat px-24 py-16`}>
      <WithConfirm
        onConfirm={logout}
        confirmText="Logging out..."
        subtitle="We will wait you to come back later!"
      >
        <div className="absolute right-16 top-[2rem] cursor-pointer justify-end">
          <Power className="text-white" />
        </div>
      </WithConfirm>
      {canGoBack && (
        <div
          className="absolute left-16 top-[2rem] flex cursor-pointer justify-end"
          onClick={() => router(-1)}
        >
          <ArrowLeft className="text-white" />
          <a className="ml-2 text-white">Inapoi</a>
        </div>
      )}
      {children}
    </div>
  );
};

export default HorizontalLayout;
