import { Spinner } from 'reactstrap';

const Loading = () => {
  return (
    <div className="flex h-full w-full items-center justify-center p-4">
      <Spinner type="grow" size="sm">
        Loading...
      </Spinner>
      <Spinner type="grow" size="sm">
        Loading...
      </Spinner>
      <Spinner type="grow" size="sm">
        Loading...
      </Spinner>
      <Spinner type="grow" size="sm">
        Loading...
      </Spinner>
    </div>
  );
};

export default Loading;
