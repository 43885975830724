import { useLazyQuery } from '@apollo/client';
import { useContext, useEffect, useState } from 'react';
import PlaylistGroup from '../components/playlist-group';
import VerticalLayout from '../components/vertical-layout';
import Header from '../components/header';
import PrimaryButton from '../components/primary-button';
import { Plus } from 'react-feather';
import { GET_PLAYLISTS_BY_USER_ID } from '../common/graphql/playlist.graphql';
import { AuthContext } from '../common/auth/auth.context';
import { PlaylistModel } from '../common/models/playlist.model';
import { useNavigate } from 'react-router-dom';
import { ERRORS } from '../common/constants/errors';
import { ToastType } from '../common/models/toast-type.enum';
import useToast from '../common/hooks/useToast';

const Playlists = () => {
  const router = useNavigate();
  const { profile } = useContext(AuthContext);
  const [playlists, setPlaylists] = useState<PlaylistModel[]>([]);
  // make sure this is called after the profile is loaded
  const [getPlaylists, { error }] = useLazyQuery(GET_PLAYLISTS_BY_USER_ID, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (error) {
      useToast(ERRORS.dj.playlist.list.getAll, ToastType.ERROR);
      console.error(error);
    }
  }, [error]);

  useEffect(() => {
    if (profile) {
      (async () => {
        const { data } = await getPlaylists({
          variables: { userId: profile.id },
        });
        setPlaylists(data.user.playlist);
      })();
    }
  }, [profile]);

  const navigateToPlaylist = () => {
    router(`/dj/playlist/add`);
  };

  return (
    <VerticalLayout background="bg-desktop" canGoBack>
      <main className="flex h-full w-full max-w-[610px] flex-col pl-8">
        <section id="header" className="h-[10%]">
          <Header
            title="Your Playlists"
            subtitle="Edit or create a new one using the oprions below"
          />
        </section>
        <section id="playlist" className="h-[80%] min-w-[500px] py-2">
          {playlists.length > 0 ? (
            <div className="max-h-96 overflow-y-auto">
              <PlaylistGroup playlist={playlists} />
            </div>
          ) : (
            <p className=" mt-6 p-0 text-xs font-thin text-white">
              Nu aveti nici un playlist adaugat
            </p>
          )}
        </section>
        <section
          id="actions"
          className="flex h-[10%] items-center justify-center"
        >
          <PrimaryButton
            color="bg-main"
            icon={<Plus className="text-sm text-white" />}
            onClick={navigateToPlaylist}
          >
            Create New Playlist
          </PrimaryButton>
        </section>
      </main>
    </VerticalLayout>
  );
};

export default Playlists;
