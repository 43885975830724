import { useLazyQuery, useMutation, useSubscription } from '@apollo/client';
import { useEffect, useState } from 'react';
import { GET_PAST_SESSIONS } from '../common/graphql/event.graphql';
import { PLAY_MUTATION, SESSION_END_SUBSCRIPTION } from '../common/graphql/session.graphql';
import votesService from '../common/services/votes.service';
import sessionService from '../common/services/session.service';
import { Play, Check } from 'react-feather';
import useToast from '../common/hooks/useToast';
import { ERRORS } from '../common/constants/errors';
import { ToastType } from '../common/models/toast-type.enum';

const SessionItem = ({ session, index, onPlayClick }: any) => {
  return (
    <div className="flex w-full items-center p-2">
      <div id="index" className="flex w-[10%]">
        <div className="flex h-[30px] w-[30px] items-center justify-center text-sm">S{index}</div>
      </div>
      <div id="song" className="flex w-[80%] flex-col items-start justify-start px-4">
        <span className="text-xs font-bold">{session.songVotes[0].title}</span>
        <span className="text-xxs font-thin">{session.songVotes[0].artist}</span>
      </div>
      <div id="actions" className="w-[10%]">
        {!session?.played ? (
          <Play
            size={20}
            color="#F564D1"
            className="cursor-pointer"
            onClick={onPlayClick?.bind(null, session.id)}
          />
        ) : (
          <Check size={20} color="#3DE161" />
        )}
      </div>
    </div>
  );
};

const PastSessions = ({ eventId }: { eventId: number }) => {
  const [sessions, setSessions] = useState<any[]>([]);
  const [getPastSessions] = useLazyQuery(GET_PAST_SESSIONS, {
    fetchPolicy: 'network-only',
  });
  const [playSong] = useMutation(PLAY_MUTATION);
  const { data } = useSubscription(SESSION_END_SUBSCRIPTION);

  useEffect(() => {
    if (data) {
      // new session final
      const topSong = votesService.parseSongData(data.sessionEnded.songVotes)[0];

      if (topSong) {
        setSessions([{ ...data.sessionEnded, songVotes: [topSong] }, ...sessions]);
      }
    }
  }, [data]);

  useEffect(() => {
    if (eventId) {
      // get past sessions
      initSessions();
    }
  }, [eventId]);

  const initSessions = async () => {
    try {
      const { data } = await getPastSessions({
        variables: {
          eventId,
        },
      });
      const updatedSessions = sessionService.parseSessionData(data.event.sessions);
      setSessions(updatedSessions);
    } catch (error) {
      useToast(ERRORS.dj.event.view.getPastSessions, ToastType.ERROR);
      console.error(error);
    }
  };

  const onPlaySessionClick = async (sessionId: number) => {
    try {
      const { data } = await playSong({
        variables: { sessionId },
      });

      const newSessions = sessions.filter((s) => s.id !== data.play.id);
      const topSong = votesService.parseSongData(data.play.songVotes)[0];
      setSessions(
        [...newSessions, { ...data.play, songVotes: [topSong] }].sort((a, b) => b.id - a.id),
      );
    } catch (error) {
      useToast(ERRORS.dj.event.view.playSong, ToastType.ERROR);
      console.error(error);
    }
  };

  return (
    <div className="gradient-background flex h-full flex-1 flex-col rounded-lg p-2 text-white">
      <h1 className=" text-md border-b-2 border-neon-red p-2" id="header">
        WINNER SONGS
      </h1>
      {sessions.length > 0 && (
        <div id="list" className="flex flex-col overflow-y-auto">
          {sessions
            .sort((a, b) => b.id - a.id)
            .map((session, index) => (
              <SessionItem
                key={session.id}
                index={sessions.length - index}
                session={session}
                onPlayClick={onPlaySessionClick}
              />
            ))}
        </div>
      )}
      {sessions.length === 0 && (
        <div id="empty" className="flex items-center justify-center p-4">
          <p className="text-sm font-thin">No songs yet.</p>
        </div>
      )}
    </div>
  );
};

export default PastSessions;
