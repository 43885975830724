const CardPrice = ({ price }: { price: string }) => {
  return (
    <div className="flex h-6 flex-row items-center justify-center overflow-hidden">
      <div className="rounded--md relative flex h-full items-center justify-end border-b-[12px] border-r-[15px] border-t-[12px] border-b-transparent border-r-neon-pink border-t-transparent">
        <div className="absolute left-2 h-[3px] w-[3px] rounded-full bg-white"></div>
      </div>
      <div className="flex h-full w-fit items-center rounded-r-md bg-neon-pink p-2">
        <p className="whitespace-nowrap text-center text-sm font-bold">{price}</p>
      </div>
    </div>
  );
};

export default CardPrice;
