import { useLazyQuery, useSubscription } from '@apollo/client';
import { useEffect, useState } from 'react';
import { ERRORS } from '../common/constants/errors';
import { GET_SESSION_WITH_SONGS } from '../common/graphql/session.graphql';
import { UNVOTE_SUBSCRIPTION, VOTE_SUBSCTIPTION } from '../common/graphql/vote.graphql';
import useToast from '../common/hooks/useToast';
import { ToastType } from '../common/models/toast-type.enum';
import votesService from '../common/services/votes.service';
import ButtonWrapper from './button-wrapper';

const SongItem = ({ song, index }: any) => {
  return (
    <div className="flex w-full items-center justify-between gap-4 p-2">
      <div id="index" className="flex w-[10%]">
        <ButtonWrapper classNames="!p-0">
          <p className="text-xs font-semibold">{index + 1}</p>
        </ButtonWrapper>
      </div>
      <div id="song" className="flex w-full flex-col items-start justify-start text-4xl">
        <span className="text-xs font-bold">{song.title}</span>
        <span className="text-xs font-thin">{song.artist}</span>
      </div>
      <div id="votes" className="flex flex-row gap-1 text-sm font-bold">
        <span className={``}>{song.votes.length}</span>
        votes
      </div>
    </div>
  );
};

const WebLiveSession = ({ title, sessionId }: { title: string; sessionId: number }) => {
  const [songs, setSongs] = useState<any[]>([]);
  const [getSessionById] = useLazyQuery(GET_SESSION_WITH_SONGS, {
    fetchPolicy: 'network-only',
  });
  const votesSubscription = useSubscription(VOTE_SUBSCTIPTION);
  const unvoteSubscription = useSubscription(UNVOTE_SUBSCRIPTION);

  useEffect(() => {
    // listen to subscription data
    if (votesSubscription.data) {
      const { voteAdded } = votesSubscription.data;
      onNewVote(voteAdded);
    }
  }, [votesSubscription.data]);

  useEffect(() => {
    // listen to subscription data
    if (unvoteSubscription.data) {
      const { voteRemoved } = unvoteSubscription.data;
      onVoteRemoved(voteRemoved);
    }
  }, [unvoteSubscription.data]);

  useEffect(() => {
    if (sessionId) {
      initSession();
    }
  }, [sessionId]);

  const initSession = async () => {
    try {
      const { data } = await getSessionById({ variables: { sessionId } });
      const structuredSongs = votesService.parseSongData(data.session.songVotes);
      setSongs(structuredSongs);
    } catch (error) {
      useToast(ERRORS.dj.event.view.getSession, ToastType.ERROR);
      console.error(error);
    }
  };

  const onNewVote = (vote: any) => {
    let updatedSongs = votesService.upsertSong(songs, vote);
    setSongs(updatedSongs);
  };

  const onVoteRemoved = (vote: any) => {
    let updatedSongs = votesService.removeSongVote(songs, vote);
    setSongs(updatedSongs);
  };

  return (
    <div className="gradient-background flex h-full flex-1 flex-col rounded-lg p-2 text-white">
      <h1 className=" text-md border-b-2 border-neon-red p-2" id="header">
        {title.toLocaleUpperCase()}
      </h1>
      {songs.length > 0 && (
        <div id="list" className="flex flex-col overflow-y-auto">
          {songs.map((song, index) => (
            <SongItem key={song.id} index={index} song={song} />
          ))}
        </div>
      )}
      {songs.length === 0 && (
        <div id="empty" className="flex items-center justify-center p-4">
          <p className="text-sm font-thin">No Votes yet.</p>
        </div>
      )}
    </div>
  );
};

export default WebLiveSession;
