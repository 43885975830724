import { useContext, useEffect, useState } from 'react';
import { Button, FormGroup } from 'reactstrap';
import { searchYoutube, Song } from '../common/services/youtube.service';
import HorizontalLayout from '../components/horizontal-layout';
import SearchInput from '../components/search-input';
import Header from '../components/header';
import PrimaryButton from '../components/primary-button';
import { Plus } from 'react-feather';
import PrimaryInput from '../components/primary-input';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import SongResultItem from '../components/song-result-item';
import PlaylistSong from '../components/playlist-song';
import { AuthContext } from '../common/auth/auth.context';
import { CREATE_PLAYLIST } from '../common/graphql/playlist.graphql';
import Loading from '../components/loading';
import useToast from '../common/hooks/useToast';
import { ERRORS } from '../common/constants/errors';
import { ToastType } from '../common/models/toast-type.enum';
import { MESSAGES } from '../common/constants/messages';
import { v4 as uuidv4 } from 'uuid';

const jsmediatags = window.jsmediatags;

const PlaylistAdd = () => {
  const router = useNavigate();
  const { profile } = useContext(AuthContext);
  // youtube search
  const [searchWord, setSearchWord] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchResults, setSearchResults] = useState<Song[]>([]);
  // custom song
  const [customSong, setCustomSong] = useState<{
    title: string;
    artist: string;
  }>({
    title: '',
    artist: '',
  });
  const [isSongSubmited, setIsSongSubmited] = useState<boolean>(false);
  const [isSubmited, setIsSubmited] = useState<boolean>(false);
  // selected songs
  const [songs, setSongs] = useState<Song[]>([]);
  const [selectedSong, setSelectedSong] = useState<number | string | null>(
    null,
  );
  // playlist name
  const [playlistName, setPlaylistName] = useState<string>('');
  // api
  const [createPlaylist, { error }] = useMutation(CREATE_PLAYLIST);

  useEffect(() => {
    console.log(songs);
  }, [songs]);

  useEffect(() => {
    if (error) {
      useToast(ERRORS.dj.playlist.add.create, ToastType.ERROR);
      console.error(error);
    }
  }, [error]);

  // search for youtube songs
  const searchSong = async () => {
    setIsLoading(true);
    const values = await searchYoutube(searchWord);
    setSearchResults(values);
    setIsLoading(false);
  };

  const addSongToPlaylist = (
    song: { artist: string; title: string },
    isInput?: boolean,
  ) => {
    if (isInput) {
      setIsSongSubmited(true);
    }

    if (isInput && (!customSong.title || !customSong.artist)) {
      return;
    }

    if (!song.title || !song.artist) {
      useToast(ERRORS.dj.playlist.add.addSongFromYoutube, ToastType.ERROR);
      return;
    }

    // check if already in the playlist
    const foundIndex =
      songs.findIndex(
        (eventSong) =>
          eventSong.title === song.title && eventSong.artist === song.artist,
      ) >= 0;

    if (!foundIndex) {
      if (isInput) {
        setCustomSong({ title: '', artist: '' });
      }
      // will add the generated id (the highest id + 1)
      setSongs([
        {
          ...song,
          id: uuidv4(),
        },
        ...songs,
      ]);
    }
    setIsSongSubmited(false);
  };

  const addMultipleSongsToPlaylist = (
    newSongs: { artist: string; title: string; id: any }[],
  ) => {
    // Build song object to contain an id and check there is no duplicate
    const songsToAdd = newSongs.filter((song) => {
      // Exclude songs that are already in playlist
      return (
        songs.findIndex(
          (eventSong) =>
            eventSong.title === song.title && eventSong.artist === song.artist,
        ) === -1
      );
    });

    useToast(
      `Added ${songsToAdd.length}, skipped duplicates ${
        newSongs.length - songsToAdd.length
      }`,
      ToastType.SUCCESS,
    );
    // Update songs in state
    setSongs([...songsToAdd, ...songs]);
  };

  const removeSongFromPlaylist = async (songId: string) => {
    const updatedPlaylist = songs.filter(
      (eventSong) => eventSong.id !== songId,
    );
    setSongs(updatedPlaylist);
    setSelectedSong(null);
  };

  const editSongFromPlaylist = async (songId: any, updates: Partial<Song>) => {
    const allSongs = [...songs];

    const updated = allSongs.map((song) => {
      if (song.id === songId) {
        return {
          ...song,
          ...updates,
        };
      }

      return { ...song };
    });

    setSongs(updated);
    setSelectedSong(null);
  };

  const onCreatePlaylist = async () => {
    setIsSubmited(true);
    if (!playlistName || !profile) {
      return;
    }

    if (songs.length === 0) {
      useToast(ERRORS.dj.playlist.add.requiredSong, ToastType.ERROR);
      return;
    }

    try {
      await createPlaylist({
        variables: {
          playlistInput: {
            name: playlistName,
            userId: profile?.id,
            songs: songs.map((item) => {
              // remove id from song payload
              const { id, ...data } = item;
              return data;
            }),
          },
        },
      });
      setIsSubmited(false);
      useToast(MESSAGES.dj.playlist.add.create, ToastType.SUCCESS);
      router(-1);
    } catch (error) {
      console.error(error);
    }
  };

  const audioFileToSong = (file: File): Promise<Song> => {
    return new Promise<Song>((resolve, reject) => {
      jsmediatags.read(file, {
        onSuccess: function (tag: any) {
          console.log(tag.tags.title);
          console.log(tag.tags.artist);
          if (tag.tags.title && tag.tags.artist) {
            // Add
            resolve({
              title: tag.tags.title,
              artist: tag.tags.artist,
              id: uuidv4(), // ⇨ '9b1deb4d-3b7d-4bad-9bdd-2b0d7b3dcb6d'
            });
          } else {
            // Use the filename to be changed manually
            resolve({ title: file.name, artist: '-', id: uuidv4() });
          }
        },
        onError: function (error: any) {
          console.log(error);
          alert(JSON.stringify(error));
          reject(error);
        },
      });
    });
  };

  const onUploadMultipleFiles = async (files: FileList | null) => {
    if (files && files.length) {
      const songsToAdd: Song[] = [];
      for (let i = 0; i < files.length; i++) {
        const song = await audioFileToSong(files[i]);
        songsToAdd.push(song);
      }
      addMultipleSongsToPlaylist(songsToAdd);
    }
  };

  return (
    <HorizontalLayout background="bg-desktop" canGoBack>
      <main className="flex h-full w-full flex-col flex-wrap">
        <section id="header" className="h-[10%]">
          <Header
            title="Lets create a new playlist"
            subtitle="Add songs to create a new playlist"
          />
        </section>
        <section
          id="playlists"
          className="flex h-[80%] flex-wrap overflow-y-auto"
        >
          <div className="flex h-full w-full min-w-[400px] flex-1 flex-col px-2">
            <div id="search-youtube" className="h-[12%] py-4">
              <SearchInput
                value={searchWord}
                onChange={setSearchWord}
                onKeyPress={searchSong}
                onClearText={() => setSearchWord('')}
              />
            </div>
            <div id="top" className="h-[71%] w-full">
              <div className="gradient-background h-full max-h-[30rem] w-full overflow-y-auto rounded-lg">
                {!isLoading && (
                  <>
                    {searchResults.map((song, index) => (
                      <SongResultItem
                        key={index}
                        song={song}
                        onClick={addSongToPlaylist}
                      />
                    ))}
                  </>
                )}
                {searchResults.length === 0 && !isLoading && (
                  <div className="flex h-full w-full items-center justify-center">
                    <p className="font-sm font-thin text-white">
                      No items to show
                    </p>
                  </div>
                )}
                {isLoading && <Loading />}
              </div>
            </div>
            <div id="name" className="h-[17%] min-h-[110px] w-full pt-3">
              <div className="gradient-background flex h-full w-full flex-col rounded-lg">
                <span className="p-2 text-xs font-thin text-white">
                  Insert Song Manually
                </span>
                <div className="flex h-full w-full items-start px-2">
                  <FormGroup className="mb-0 flex-2 pr-2">
                    <PrimaryInput
                      id="artist"
                      type="text"
                      placeholder="Artist"
                      errorMessage="Missing Artist Name"
                      value={customSong.artist}
                      invalid={isSongSubmited && customSong.artist.length === 0}
                      onChange={(ev: any) =>
                        setCustomSong({
                          title: customSong?.title || '',
                          artist: ev.target.value,
                        })
                      }
                    />
                  </FormGroup>
                  <FormGroup className="mb-0 flex-2  pr-2">
                    <PrimaryInput
                      id="name"
                      type="text"
                      placeholder="Song Name"
                      invalid={isSongSubmited && customSong.title.length === 0}
                      value={customSong.title}
                      errorMessage="Missing Sonng Title"
                      onChange={(ev: any) =>
                        setCustomSong({
                          artist: customSong?.artist || '',
                          title: ev.target.value,
                        })
                      }
                    />
                  </FormGroup>
                  <div className="flex flex-1 cursor-pointer justify-end">
                    <Button
                      onClick={() => addSongToPlaylist(customSong, true)}
                      className="flex h-[2rem] min-w-[7rem] items-center justify-center rounded-lg border-solid p-0 border-gradient-br-input-border-neon gradient-border-2 focus:outline-none"
                    >
                      <div className="flex h-full w-full items-center justify-center rounded-md bg-neon-red bg-gradient-to-tr from-[#6EB8FE] via-[#6EB8FE] to-[#D266AA] text-xs">
                        Add Song
                      </div>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div className='to-[#74002F]/70" flex h-auto w-full flex-col rounded-lg bg-gradient-to-bl from-[#6FB8FF]/70 p-2'>
              <span className="p-2 text-xs font-thin text-white">
                Upload multiple files
              </span>
              <input
                className="text-white"
                type="file"
                name="filefield"
                multiple={true}
                placeholder="Select multiple files..."
                title="a"
                onChange={(e) => onUploadMultipleFiles(e.target.files)}
                accept="audio/*"
              />
            </div>
          </div>
          <div className="h-full min-w-[400px] flex-1 px-2">
            <div
              id="wrapper"
              className="relative h-full w-full rounded-lg bg-gradient-to-bl from-[#6FB8FF]/50 to-[#74002F]/50"
            >
              <div id="playlist-name" className="h-[15%] px-4 pb-4 pt-[2rem]">
                <PrimaryInput
                  id="playlistName"
                  type="text"
                  errorMessage="Please add a playlist name"
                  invalid={isSubmited && playlistName.length === 0}
                  placeholder="Insert playlist name "
                  onChange={(ev: any) => setPlaylistName(ev.target.value)}
                />
              </div>
              <div id="table" className="flex h-[85%] flex-col">
                <div className="flex cursor-pointer flex-nowrap overflow-hidden border-b-2 border-[#D266AA] py-2 px-14 text-xs">
                  <div className="flex flex-1 items-center justify-start">
                    <p className="text-white">No.</p>
                  </div>
                  <div className="flex flex-1 items-center justify-start px-2">
                    <p className="text-white">Artist</p>
                  </div>
                  <div className="flex flex-2 items-center justify-start px-2">
                    <p className="text-white">Title</p>
                  </div>
                </div>
                <div className="h-full max-h-[30rem] w-full overflow-y-auto px-4">
                  {songs.length > 0 && (
                    <>
                      {songs.map((song, index) => (
                        <PlaylistSong
                          index={index}
                          key={index}
                          song={song}
                          onSelect={setSelectedSong.bind(null, song.id)}
                          active={song.id === selectedSong}
                          onRemoveSong={removeSongFromPlaylist}
                          onEditSong={editSongFromPlaylist}
                          canEdit={true}
                        />
                      ))}
                    </>
                  )}
                  {songs.length === 0 && (
                    <div className="flex h-full w-full items-center justify-center">
                      <p className="font-sm font-thin text-white">
                        No items to show
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          id="actions"
          className="flex h-[10%] flex-row-reverse items-center p-2"
        >
          <PrimaryButton
            color="bg-main"
            onClick={onCreatePlaylist}
            icon={<Plus className="text-sm text-white" />}
          >
            Create
          </PrimaryButton>
          <PrimaryButton color="bg-transparent" onClick={() => router(-1)}>
            Cancel
          </PrimaryButton>
        </section>
      </main>
    </HorizontalLayout>
  );
};

export default PlaylistAdd;
