import { useEffect } from 'react';
import { useTimer } from 'react-timer-hook';

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(' ');
}

const SessionCountDown = ({
  endDate,
  onComplete,
  className,
}: {
  endDate: Date;
  onComplete?: any;
  className?: string;
}) => {
  const { minutes, seconds, restart } = useTimer({
    expiryTimestamp: endDate,
    onExpire: onComplete.bind(null, true),
  });

  // // needs this as for some reason the countdonw stops once we change the date
  useEffect(() => {
    if (endDate) {
      restart(endDate);
    }
  }, [endDate]);

  const zeroPrefix = (value: number) => (value < 10 ? `0${value}` : `${value}`);

  return (
    <h1 className={classNames(className ? className : 'text-2xl')}>
      {zeroPrefix(minutes)}:{zeroPrefix(seconds)}
    </h1>
  );
};

export default SessionCountDown;
