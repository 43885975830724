import { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { AuthContext } from '../common/auth/auth.context';

const PublicRoutes = (props: any) => {
  const { isAuthenticated } = useContext(AuthContext);
  return isAuthenticated ? <Navigate to="/" /> : <Outlet />;
};

export default PublicRoutes;
