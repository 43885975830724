import { useNavigate, useParams } from 'react-router-dom';
import HorizontalLayout from '../components/horizontal-layout';
import { useContext, useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  EDIT_LIVE_EVENT,
  GET_EVENT_BY_ID_WITH_FULL_PLAYLIST,
  START_EVENT,
  START_SESSION,
  STOP_EVENT,
} from '../common/graphql/event.graphql';
import { ToastType } from '../common/models/toast-type.enum';
import useToast from '../common/hooks/useToast';
import { ERRORS } from '../common/constants/errors';
import Header from '../components/header';
import WithConfirm from '../components/with-confirmation';
import PlaylistCard from '../components/playlist-card';
import { GET_PLAYLISTS_BY_USER_ID } from '../common/graphql/playlist.graphql';
import { AuthContext } from '../common/auth/auth.context';
import Statistics from '../components/statistics';
import { GET_SESSION_BY_ID } from '../common/graphql/session.graphql';
import { compareAsc } from 'date-fns';
import ConfirmationModal from '../components/confirmation-modal';
import WebLiveSession from '../components/web-live-session';
import PastSessions from '../components/past-sessions';
import SongRequests from '../components/song-requests';
import { MESSAGES } from '../common/constants/messages';
import PrimaryButton from '../components/primary-button';
import OutlinedButton from '../components/outlined-button';

const Event = () => {
  const { id } = useParams();
  const router = useNavigate();
  // context
  const { profile } = useContext(AuthContext);
  // event
  const [event, setEvent] = useState<null | any>(null);
  // event session
  const [session, setSession] = useState<null | any>(null);
  const [currentSessionEnded, setCurrentSessionEnded] = useState<boolean>(false);
  const [showNextSessionModal, setShowNextSessionModal] = useState<boolean | null>(null);
  // playlists
  const [inactivePlaylists, setInactivePlaylists] = useState<any[]>([]);
  const [totalNumberOfTracks, setTotalNumberOfTracks] = useState<number>(0);

  // queries
  const [getEventById] = useLazyQuery(GET_EVENT_BY_ID_WITH_FULL_PLAYLIST(id), {
    fetchPolicy: 'network-only',
  });
  const [getPlaylistsByUserId] = useLazyQuery(GET_PLAYLISTS_BY_USER_ID, {
    fetchPolicy: 'network-only',
  });
  const [getSessionById] = useLazyQuery(GET_SESSION_BY_ID, {
    fetchPolicy: 'network-only',
  });

  // api mutations
  const [updateEvent] = useMutation(EDIT_LIVE_EVENT);
  const [startEvent] = useMutation(START_EVENT);
  const [startSession] = useMutation(START_SESSION);
  const [stopEvent] = useMutation(STOP_EVENT);

  /**
   * INIT
   */
  useEffect(() => {
    initEvent();
  }, []);

  useEffect(() => {
    if (profile && event) {
      initPlaylists();
    }
  }, [profile, event]);

  useEffect(() => {
    if (event?.currentSessionId) {
      initSession();
    }
    updateTracks();
  }, [event]);

  const initEvent = async () => {
    try {
      const { data } = await getEventById();
      setEvent(data.event);
    } catch (error) {
      useToast(ERRORS.dj.event.view.get, ToastType.ERROR);
      console.error(error);
    }
  };

  const initPlaylists = async () => {
    try {
      const { data } = await getPlaylistsByUserId({
        variables: { userId: profile?.id },
      });
      onUpdateActivePlaylists(data.user.playlist);
    } catch (error) {
      useToast(ERRORS.dj.event.view.getPlaylists, ToastType.ERROR);
      console.error(error);
    }
  };

  const initSession = async () => {
    try {
      const { data } = await getSessionById({
        variables: { sessionId: event.currentSessionId },
      });

      if (compareAsc(Date.now(), new Date(data.session.endDate)) >= 0) {
        // need to show new session modal
        setCurrentSessionEnded(true);
      } else {
        setSession(data.session);
      }
    } catch (error) {
      useToast(ERRORS.dj.event.view.getSession, ToastType.ERROR);
      console.error(error);
    }
  };

  // playlist handling
  const onRemovePlaylistFromEvent = async (playlistId: number) => {
    // don't let the user remove a playlist from a finished event
    try {
      if (event.endDate) {
        return;
      }

      const { data } = await updateEvent({
        variables: {
          editEventInput: {
            id: event.id,
            removedPlaylistIds: [playlistId],
          },
        },
      });

      setEvent(data.editEvent);
      onUpdateActivePlaylists(data.editEvent.playlist);
    } catch (error) {
      useToast(ERRORS.dj.event.view.updateEvent, ToastType.ERROR);
      console.error(error);
    }
  };

  const onAddPlaylistToEvent = async (playlistId: any) => {
    // don't let the user remove a playlist from a finished event
    try {
      if (event.endDate) {
        return;
      }

      const { data } = await updateEvent({
        variables: {
          editEventInput: {
            id: event.id,
            playlistIds: [playlistId],
          },
        },
      });

      setEvent(data.editEvent);
      onUpdateActivePlaylists(data.editEvent.playlist);
    } catch (error) {
      useToast(ERRORS.dj.event.view.updateEvent, ToastType.ERROR);
      console.error(error);
    }
  };

  const onUpdateActivePlaylists = (activePlaylists: any[]) => {
    // data.user.playlist
    const playlists: any[] = [];
    activePlaylists.map((value: any) => {
      const index = event?.playlist.findIndex((item: any) => item.id === value.id);
      // if the playlist is not in the event is inactive
      if (index < 0) {
        playlists.push(value);
      }
    });

    // set final inactive playlists
    setInactivePlaylists(playlists);
  };

  const updateTracks = () => {
    const totalTracks = event?.playlist?.reduce((prev: number, current: any) => {
      prev += current.songs.length;
      return prev;
    }, 0);
    setTotalNumberOfTracks(totalTracks);
  };

  // session handling
  const endCurrentSession = (ended: boolean) => {
    setCurrentSessionEnded(ended);
    if (event?.active && showNextSessionModal === null) {
      setShowNextSessionModal(ended);
    }
  };

  const onSessionStart = async () => {
    try {
      const { data } = await startSession({
        variables: {
          eventId: event.id,
        },
      });

      // update current session
      setEvent({
        ...event,
        active: true,
        currentSessionId: data.startSession.currentSessionId,
      });

      endCurrentSession(false);
      setShowNextSessionModal(null);
      useToast(MESSAGES.dj.event.add.startSession, ToastType.SUCCESS);
    } catch (error) {
      useToast(ERRORS.dj.event.view.startSession, ToastType.ERROR);
      console.error(error);
    }
  };

  // event handling
  const onStartEvent = async () => {
    try {
      await startEvent({
        variables: {
          eventId: event.id,
        },
      });
      useToast(MESSAGES.dj.event.add.startEvent, ToastType.SUCCESS);
      await onSessionStart();
    } catch (error) {
      useToast(ERRORS.dj.event.view.startEvent, ToastType.ERROR);
      console.error(error);
    }
  };

  const onStopEvent = async () => {
    try {
      const { data } = await stopEvent({ variables: { eventId: event.id } });
      setCurrentSessionEnded(true);
      setEvent(data.stopEvent);
      useToast(MESSAGES.dj.event.add.stopEvent, ToastType.SUCCESS);
    } catch (error) {
      useToast(ERRORS.dj.event.view.stopEvent, ToastType.ERROR);
      console.error(error);
    }
  };

  const onEditEvent = () => {
    router(`/dj/event/edit/${id}`);
  };

  return (
    <HorizontalLayout background="bg-frame1" canGoBack>
      <section id="title" className="flex h-[10%] w-full items-center justify-between">
        <Header title={event?.name} subtitle={event?.description || 'Enjoy the experience'} />
        <div className="text-2xl text-white">
          Event ID: <span> {event?.code?.code}</span>
        </div>
      </section>
      <section id="content" className="flex h-[85%] w-full gap-3 pb-3">
        <div className="flex h-full min-w-[28%] flex-1 flex-col gap-3">
          <div id="Statistics" className="gradient-background flex-1 rounded-lg">
            <Statistics
              sessionId={session?.id}
              tracks={totalNumberOfTracks}
              eventId={event?.id}
              onSessionComplete={endCurrentSession}
              eventActive={event?.active}
            />
          </div>
          <div className="gradient-background flex-3 overflow-y-auto rounded-lg p-4">
            <h1 className="text-xl text-white">Active playlists</h1>
            <div className="flex max-h-[380px] max-w-[850px] flex-wrap overflow-y-auto">
              {event?.playlist?.map((pl: any) => (
                <WithConfirm
                  onConfirm={onRemovePlaylistFromEvent.bind(this, pl.id)}
                  confirmText="Remove playlist from event"
                  subtitle="All the tracks from this playlist will be removed from the current event. Are you sure?"
                  key={pl.id}
                >
                  <PlaylistCard item={pl} />
                </WithConfirm>
              ))}
            </div>
          </div>
          <div className="gradient-background flex-3 overflow-y-auto rounded-lg p-4">
            <h1 className="text-xl text-white">Inactive playlists</h1>
            <div className="flex overflow-y-auto lg:max-w-[850px]">
              {inactivePlaylists.map((pl: any) => (
                <WithConfirm
                  onConfirm={onAddPlaylistToEvent.bind(this, pl.id)}
                  confirmText="Add playlist to event"
                  subtitle="All the tracks from this playlist will be added in the current event and be visible for all participants. Are you sure?"
                  key={pl.id}
                >
                  <PlaylistCard item={pl} />
                </WithConfirm>
              ))}
            </div>
          </div>
        </div>
        <WebLiveSession title="live vote" sessionId={session?.id} />
        <PastSessions eventId={event?.id} />
        <SongRequests eventId={event?.id} />
      </section>
      <section id="actions" className="flex h-[5%] w-full flex-row-reverse items-center">
        {!event?.active && !event?.endDate && (
          <WithConfirm
            onConfirm={onStartEvent}
            confirmText="Start the event"
            subtitle="Are you sure do you want to start this event?"
          >
            <PrimaryButton color="bg-main">Start Event</PrimaryButton>
          </WithConfirm>
        )}
        {event?.active && currentSessionEnded && (
          <WithConfirm
            onConfirm={onSessionStart}
            confirmText="Start a new session"
            subtitle="Are you sure do you want to start a new session?"
          >
            <PrimaryButton color="bg-main" className="animate-pulse">
              New Session
            </PrimaryButton>
          </WithConfirm>
        )}
        {event?.active && (
          <div className="mr-auto">
            <WithConfirm
              onConfirm={onStopEvent}
              confirmText="Stop the event"
              subtitle="Are you sure do you want to STOP this event? This action is irreversible and will kick out all participants."
            >
              <OutlinedButton className="text-neon-red">Stop Event</OutlinedButton>
            </WithConfirm>
          </div>
        )}
        {!event?.endDate && (
          <>
            <PrimaryButton
              color="bg-transparent"
              onClick={() => router(`/dj/event/${event.id}/statistics`)}
            >
              Statistics
            </PrimaryButton>
            {event?.activities?.find((activity: any) => activity.type === 'memories') && (
              <PrimaryButton
                color="bg-transparent"
                onClick={() => router(`/dj/event/${event.id}/memories-wall`)}
              >
                Memories Wall
              </PrimaryButton>
            )}

            <PrimaryButton color="bg-transparent" onClick={onEditEvent}>
              Edit Event
            </PrimaryButton>
          </>
        )}
      </section>
      <ConfirmationModal
        isOpen={showNextSessionModal === true}
        title="Start a new session"
        subtitle="Are you sure do you want to start a new session?"
        onConfirm={onSessionStart}
        toggle={setShowNextSessionModal.bind(null, false)}
      />
    </HorizontalLayout>
  );
};

export default Event;
