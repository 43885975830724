import { useLazyQuery, useSubscription } from '@apollo/client';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { EVENT_UPDATES_SUBSCRIPTION, GET_GUEST_EVENT } from '../common/graphql/event.graphql';
import useToast from '../common/hooks/useToast';
import { ERRORS } from '../common/constants/errors';
import { ToastType } from '../common/models/toast-type.enum';
import 'primereact/resources/themes/lara-light-indigo/theme.css'; //theme
import 'primereact/resources/primereact.min.css'; //core css
import 'primeicons/primeicons.css';
import ImageCard from '../components/image-card';
import ParalaxMobileLayout from '../components/paralax-mobile-layout';
import ImageModal from '../components/image-modal';
import {
  GET_MEMORIES_BY_EVENT_ID,
  MEMORIES_UPDATES_SUBSCRIPTION,
} from '../common/graphql/memories.graphql';
import { useLikeMemoryMutation } from '../services/memories/memories.service';
import { AuthContext } from '../common/auth/auth.context';
import Lottie from 'lottie-react';
import loadingAnimation from '../assets/lottie/image-loader.json';
import { MESSAGES } from '../common/constants/messages';
import { useInView } from 'react-intersection-observer';

const VirtualScrollChild = ({ children }: { children: any }) => {
  const [ref, inView] = useInView({ threshold: 0, triggerOnce: true });
  return <div ref={ref}>{inView ? children : null}</div>;
};

export type ImageType = {
  id: number;
  path: string;
  description: string;
  user: { profile: { username: string } };
  likesCount: number;
  liked?: boolean;
};

const MemoriesApp = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const { id } = useParams();
  const router = useNavigate();
  const [event, setEvent] = useState<any | null>(null);
  const [images, setImages] = useState<ImageType[]>([]);
  const [toggleImageUpload, setToggleImageUpload] = useState<boolean>(false);
  const [files, setFiles] = useState<FileList>();
  const [showRefresh, setShowRefresh] = useState<boolean>(false);

  const [getEvent] = useLazyQuery(GET_GUEST_EVENT, {
    fetchPolicy: 'network-only',
  });
  // subscriptions
  const eventUpdatesSubscription = useSubscription(EVENT_UPDATES_SUBSCRIPTION);
  const memoriesUpdatesSubscription = useSubscription(MEMORIES_UPDATES_SUBSCRIPTION);

  const [getMemories, { refetch }] = useLazyQuery(GET_MEMORIES_BY_EVENT_ID, {
    fetchPolicy: 'network-only',
  });

  const { profile } = useContext(AuthContext);
  const { mutate } = useLikeMemoryMutation();

  useEffect(() => {
    localStorage.setItem('route', 'memories-wall');
    initEvent();
  }, []);

  useEffect(() => {
    if (event) {
      initImages();
    }
  }, [event]);

  useEffect(() => {
    // event is over
    if (eventUpdatesSubscription.data?.eventUpdates.endDate) {
      router(`/guest/finished/${id}`, { replace: true });
    }
  }, [eventUpdatesSubscription.data]);

  useEffect(() => {
    if (memoriesUpdatesSubscription.data?.newMemory) {
      setShowRefresh(true);
    }
  }, [memoriesUpdatesSubscription.data]);

  const initEvent = async () => {
    try {
      console.log('init memories');
      const eventResponse = await getEvent({
        variables: { eventId: +(id as string) },
      });

      if (!eventResponse.data.event.active) {
        if (eventResponse.data.event.endDate !== null) {
          router(`/guest/finished/${id}`, { replace: true });
        } else {
          router('/guest', { replace: true });
        }
      } else {
        setEvent(eventResponse.data.event);
        if (
          !eventResponse.data.event.activities.find(
            (activity: { id: number; type: string }) => activity.type === 'memories',
          )
        ) {
          localStorage.setItem('route', '');
          router('/guest', { replace: true });
        }
      }
    } catch (error) {
      setLoading(false);
      useToast(ERRORS.guest.event.getEvent, ToastType.ERROR);
      console.error(error);
    }
  };

  const initImages = async () => {
    // TODO move this from here
    setLoading(true);
    const { data: response } = await getMemories({
      variables: { eventId: event.id, userId: profile?.id as number },
    });

    setImages(response.memories);

    setLoading(false);
    // useToast(ERRORS.guest.event.getEvent, ToastType.ERROR);
    // console.error(error);
  };

  const handleImageLike = (imageId: number) => {
    if (profile) {
      mutate(
        { memoryId: imageId, userId: profile.id },
        {
          onSuccess: () => {
            const newImages = images.map((image: ImageType) => {
              if (image.id === imageId) {
                return {
                  ...image,
                  liked: !image.liked,
                  likesCount: !image.liked == true ? image.likesCount + 1 : image.likesCount - 1,
                };
              } else return image;
            });
            setImages(newImages);
          },
        },
      );
    }
  };

  const clickInput = (event: any) => {
    event.stopPropagation();
    document.getElementById('imageUploadInput')?.click();
  };

  const handleFileChange = (event: any) => {
    event.stopPropagation();
    if (event.target.files.length) {
      setFiles(event.target.files);
      setToggleImageUpload(true);
    } else {
      setFiles(undefined);
    }
  };

  const handleImagesRefresh = async () => {
    const { data } = await refetch();
    if (data?.memories) {
      setImages(data.memories);
      document.getElementById('scroll-target')?.scrollTo({ top: 0, behavior: 'smooth' });
      setShowRefresh(false);
    }
  };

  return (
    <ParalaxMobileLayout
      className="scroll-section !w-full overflow-scroll bg-memories-background !bg-cover !bg-center"
      canGoBack
      onLeave={() => initEvent()}
      openImageUploadModal={(event) => {
        clickInput(event);
      }}
      canAnimateButton={!loading && !images.length}
    >
      <div className="fixed top-0 h-full w-full !bg-gradient-to-b !from-[#09112F60] !via-[#09112F] !via-70% !to-black"></div>
      <main className="relative flex h-full min-h-fit w-full flex-col text-white ">
        <section id="header" className="fixed top-20 flex w-full flex-col items-center p-8 pt-0">
          <div className="z-10 flex flex-col items-center justify-center">
            <div className="h-36 w-44 bg-memories-for-logo bg-contain bg-no-repeat"></div>
            <p className="text-center font-raleway text-2xl font-light">{event?.name}</p>
          </div>
        </section>
        <div
          className={`${
            images.length === 0 || loading ? '' : ''
          } gradient-background absolute mt-72 flex min-h-[70%] w-full flex-col rounded-t-3xl p-2 !opacity-100`}
        >
          <p className="font-sm p-4 font-medium">
            Upload here your best memories and let the party begin!
          </p>
          {loading && (
            <div className="my-auto flex w-full items-center justify-center">
              <Lottie className="lottie" animationData={loadingAnimation} loop={true} />
            </div>
          )}
          {!loading && !images.length && (
            <div className="my-auto flex w-full items-center justify-center">
              No images uploaded yet
            </div>
          )}
          {!loading && images.length !== 0 && (
            <div className="flex h-full min-h-[26rem] w-full flex-col gap-2">
              {images.map((image) => (
                <VirtualScrollChild key={image.id}>
                  <ImageCard image={image} onLikeClick={handleImageLike}></ImageCard>
                </VirtualScrollChild>
              ))}
            </div>
          )}
          <p className="mt-auto flex w-full justify-center py-4 text-center text-xs text-white">
            Powered by <br /> Tribus Technologies
          </p>
        </div>
        <input
          id="imageUploadInput"
          className="hidden"
          type="file"
          accept="image/png, image/jpeg"
          onChange={(event) => handleFileChange(event)}
        />
        {toggleImageUpload && (
          <ImageModal
            toggle={() => {
              setToggleImageUpload(!toggleImageUpload);
              (document.getElementById('imageUploadInput') as any).value = '';
            }}
            files={files}
            eventId={event?.id}
            handlePostUpload={() => {
              useToast(MESSAGES.guest.event.uploadMemory, ToastType.SUCCESS);
              handleImagesRefresh();
            }}
          ></ImageModal>
        )}
      </main>

      <div
        className={`${
          showRefresh ? '' : 'active'
        } sticky-header-hide fixed top-4 z-50 flex w-full items-center justify-center`}
      >
        <div
          className="rounded-full bg-white-60 px-3 py-2 text-sm text-white backdrop-blur-md"
          onClick={handleImagesRefresh}
        >
          See new updates
        </div>
      </div>
    </ParalaxMobileLayout>
  );
};

export default MemoriesApp;
