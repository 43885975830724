import { toast } from 'react-toastify';
import { ToastType } from '../models/toast-type.enum';

const useToast = (message: string, type: ToastType) => {
  toast(message, {
    position: 'top-right',
    className: `toast-${type}`,
    progressClassName: 'no-progress',
  });
};

export default useToast;
