import { useLazyQuery, useSubscription } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import HorizontalLayout from '../components/horizontal-layout';
import { AuthContext } from '../common/auth/auth.context';
import { GET_EVENT_BY_ID_WITH_FULL_PLAYLIST } from '../common/graphql/event.graphql';
import useToast from '../common/hooks/useToast';
import { ERRORS } from '../common/constants/errors';
import { ToastType } from '../common/models/toast-type.enum';
import {
  GET_MEMORIES_BY_EVENT_ID,
  MEMORIES_UPDATES_SUBSCRIPTION,
} from '../common/graphql/memories.graphql';
import QRCODE from '../assets/img/qrcode_juke.png';
import { ImageType } from './MemoriesApp';

const EventMemories = () => {
  const { id } = useParams();
  const router = useNavigate();
  // context
  const { profile } = useContext(AuthContext);
  // state
  const [event, setEvent] = useState<null | any>(null);
  const [images, setImages] = useState<ImageType[]>();
  const [index, setIndex] = useState<number | undefined>();

  // api queries
  const [getEventById] = useLazyQuery(GET_EVENT_BY_ID_WITH_FULL_PLAYLIST(id), {
    fetchPolicy: 'network-only',
  });
  const [getMemories] = useLazyQuery(GET_MEMORIES_BY_EVENT_ID, {
    fetchPolicy: 'network-only',
  });

  const memoriesUpdatesSubscription = useSubscription(
    MEMORIES_UPDATES_SUBSCRIPTION,
  );

  /**
   * INIT
   */
  useEffect(() => {
    initEvent();
  }, []);

  useEffect(() => {
    if (event) {
      initImages();
    }
  }, [event]);

  useEffect(() => {
    if (images?.length === 1) {
      setIndex(0);
      return;
    }
    if (images?.length === 2) {
      setIndex(1);
    }
  }, [images]);

  useEffect(() => {
    if (index !== undefined && images?.length) {
      setTimeout(() => {
        index + 1 === images?.length ? setIndex(0) : setIndex(index + 1);
      }, 10000);
    }
  }, [index]);

  useEffect(() => {
    if (memoriesUpdatesSubscription.data?.newMemory) {
      const updatedMemories = [
        ...(images || []),
        memoriesUpdatesSubscription.data.newMemory,
      ];
      setImages(updatedMemories);
    }
  }, [memoriesUpdatesSubscription.data]);

  const initImages = async () => {
    const { data: response } = await getMemories({
      variables: { eventId: event.id, userId: profile?.id as number },
    });

    setImages(
      [...response.memories].sort((memory1: any, memory2: any) => {
        if (memory1.id > memory2.id) return 1;
        else return -1;
      }),
    );
    if (response.memories.length !== 0) {
      setIndex(0);
    }
  };

  const initEvent = async () => {
    try {
      if (!profile) router('/');
      const { data } = await getEventById();
      if (
        !data?.event?.activities?.find(
          (activity: any) => activity.type === 'memories',
        )
      ) {
        router(`/dj/event/${data.id}`);
      }
      setEvent(data.event);
    } catch (error) {
      useToast(ERRORS.dj.event.view.get, ToastType.ERROR);
      console.error(error);
    }
  };

  return (
    <HorizontalLayout background="bg-frame1" canGoBack={false}>
      {!images?.length && (
        <div className="absolute left-0 top-0 z-50 flex h-[100vh] w-[100vw] flex-col items-center justify-center gap-10">
          <div className="custom-animation h-24 w-64 bg-juke-logo bg-contain bg-no-repeat"></div>

          <div className="grid grid-flow-row grid-cols-[10fr_1fr_10fr] items-center justify-center gap-20">
            <div className="flex flex-col items-center justify-center gap-1">
              <div className="h-[23rem] w-[30rem] bg-memories-for-logo bg-contain bg-no-repeat"></div>
              <p className="font-raleway text-4xl text-white">{event?.name}</p>
            </div>
            <div className="flex h-96 w-1 self-center justify-self-center bg-white"></div>
            <div className="flex flex-col items-center justify-center gap-4">
              <p className="text-4xl text-white">Scan to join!</p>
              <img className="mt-2 h-80 w-80" src={QRCODE} alt="me" />
            </div>
          </div>
          <p className="text-center text-xs text-white">
            Powered by <br /> Tribus Technologies
          </p>
        </div>
      )}
      {images?.length && index !== undefined && (
        <div className="absolute top-0 left-0 h-[100vh] w-[100vw]">
          <>
            <div className="absolute top-10 left-10 z-50 flex flex-col items-center justify-center gap-1">
              <div className="h-48 w-64 bg-memories-for-logo bg-contain bg-no-repeat"></div>
              <p className="font-raleway text-3xl text-white">{event?.name}</p>
            </div>
            <div className="absolute bottom-10 right-10 z-50 flex flex-col items-center justify-center gap-1">
              <p className="text-2xl text-white">Scan to join!</p>
              <img className="mt-2 h-56 w-56" src={QRCODE} alt="me" />
            </div>
          </>
          <img
            className="absolute top-0 left-0 z-10 h-full w-full object-contain object-center shadow-2xl"
            src={images[index].path}
          ></img>
          <div className="absolute top-0 left-0 h-full w-full bg-black-60 backdrop-blur-xl"></div>
          <img
            className="h-full w-full object-cover object-center"
            src={images[index].path}
          ></img>
        </div>
      )}
    </HorizontalLayout>
  );
};

export default EventMemories;
