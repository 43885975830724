import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../common/auth/auth.context';
import ModuleMobileLayout from '../components/module-mobile-layout';

// This page has a transition purpose
const Guest = () => {
  const router = useNavigate();
  const { profile } = useContext(AuthContext);

  useEffect(() => {
    if (profile) {
      if (profile.profile === null) {
        router('/guest/profile');
      } else {
        router(`/guest/lobby`, { replace: true });
      }
    }
  }, [profile]);

  return <ModuleMobileLayout className="bg-black"></ModuleMobileLayout>;
};

export default Guest;
