const parseSongData = (songVotes: any[]) => {
  const parsedSongs = songVotes.reduce((prev, current) => {
    const foundIndex = prev.findIndex((s: any) => s.id === current.song.id);
    if (foundIndex >= 0) {
      prev[foundIndex].votes.push({
        userId: current.user.id,
        songId: current.song.id,
        sessionId: current.session.id,
      });
      return prev;
    } else {
      return [
        ...prev,
        {
          ...current.song,
          votes: [
            {
              userId: current.user.id,
              songId: current.song.id,
              sessionId: current.session.id,
            },
          ],
        },
      ];
    }
  }, []);

  return (parsedSongs as any[]).sort(sortSongs);
};

const upsertSong = (songs: any[], vote: any) => {
  let updatedSongs = [...songs];
  const foundIndex = updatedSongs.findIndex((s: any) => s.id === vote.song.id);

  if (foundIndex >= 0) {
    updatedSongs[foundIndex].votes.push({
      userId: vote.user.id,
      songId: vote.song.id,
      sessionId: vote.session.id,
    });
  } else {
    updatedSongs = [
      ...updatedSongs,
      {
        ...vote.song,
        votes: [
          {
            userId: vote.user.id,
            songId: vote.song.id,
            sessionId: vote.session.id,
          },
        ],
      },
    ];
  }

  return updatedSongs.sort(sortSongs);
};

const removeSongVote = (songs: any[], vote: any) => {
  let updatedSongs = [...songs];
  const foundIndex = updatedSongs.findIndex((s: any) => s.id === vote.song.id);

  if (foundIndex < 0) {
    return updatedSongs.sort(sortSongs);
  }

  updatedSongs[foundIndex].votes = updatedSongs[foundIndex].votes.filter(
    (v: any) => !(v.userId === vote.user.id && v.sessionId === vote.session.id),
  );

  // if the song has no votes we remove it
  if (updatedSongs[foundIndex].votes.length == 0) {
    updatedSongs = updatedSongs.filter(
      (song) => song.id !== updatedSongs[foundIndex].id,
    );
  }

  return updatedSongs.sort(sortSongs);
};

const sortSongs = (songA: any, songB: any) =>
  songB.votes.length - songA.votes.length;

export default { parseSongData, upsertSong, removeSongVote };
