import { useLazyQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { Spinner } from 'reactstrap';
import ButtonWrapper from '../components/button-wrapper';
import CardPrice from '../components/card-price';
import { GET_SONG_REQUESTS_BY_EVENT_ID } from '../services/song-requests/song-requests.service';
import {
  useNewRequestSubscription,
  useRequestResolverSubscription,
} from '../services/song-requests/song-requests.service';

interface SongRequestItemProps {
  artist: string;
  id: number;
  status: 'PENDING' | 'APPROVED' | 'REJECTED';
  title: string;
  payment: { amount: number; user: { profile: { username: string } } };
  username: string;
  amount: number;
}

const SongRequestItem = ({ index, request }: { index: number; request: SongRequestItemProps }) => {
  const getStatus = (status: 'PENDING' | 'APPROVED' | 'REJECTED' | 'PLAYED') => {
    switch (status) {
      case 'PENDING':
        return (
          <p className="rounded-lg bg-neon-orange px-2 py-1 text-xs font-semibold text-white">
            Pending
          </p>
        );
      case 'APPROVED':
        return (
          <p className="rounded-lg bg-success px-2 py-1 text-xs font-semibold text-white">
            Approved
          </p>
        );
      case 'REJECTED':
        return (
          <p className="rounded-lg bg-neon-red px-2 py-1 text-xs font-semibold text-white">
            Rejected
          </p>
        );
      case 'PLAYED':
        return (
          <p className="rounded-lg bg-blue px-2 py-1 text-xs font-semibold text-white">Played</p>
        );
    }
  };
  return (
    <div className="request-item flex w-full cursor-pointer flex-row items-center justify-between gap-4 py-2">
      <div id="index" className="flex">
        <ButtonWrapper classNames="!p-0">
          <p className="text-sm">{index}</p>
        </ButtonWrapper>
      </div>
      <div className="flex w-full flex-col">
        <span className="w-full text-xs font-bold">{request.title}</span>
        <span className="w-full text-xs font-thin">{request.artist}</span>
        <span className="w-full pt-1 text-xs font-semibold">by {request.username || '-'}</span>
      </div>
      <div className="flex flex-col items-end gap-1">
        {getStatus(request.status)}
        <CardPrice price={`${request.payment.amount / 100} lei`}></CardPrice>
      </div>
    </div>
  );
};

const SongRequests = ({
  eventId,
  onScroll,
  onRequestSongClick,
}: {
  eventId: number;
  onScroll: (scrollValue: number) => void;
  onRequestSongClick: () => void;
}) => {
  const [songRequests, setSongRequests] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [getSongRequestsByEventId] = useLazyQuery(GET_SONG_REQUESTS_BY_EVENT_ID, {
    fetchPolicy: 'network-only',
  });

  const { data } = useNewRequestSubscription(eventId);
  const { data: suggestionResolved } = useRequestResolverSubscription(eventId);

  useEffect(() => {
    if (eventId) initSongRequests(eventId);
  }, [eventId]);

  useEffect(() => {
    if (data?.newSuggestion) {
      const newSongRequests = [...songRequests];
      newSongRequests.push({
        ...data.newSuggestion,
        id: data.newSuggestion.suggestionId,
        status: 'PENDING',
      });
      setSongRequests(newSongRequests);
    }
  }, [data]);

  useEffect(() => {
    if (suggestionResolved?.suggestionResolved) {
      const updatedSongRequests = [...songRequests].map((songRequest) => {
        if (songRequest.id === suggestionResolved.suggestionResolved.suggestionId) {
          return { ...songRequest, status: suggestionResolved.suggestionResolved.status };
        } else {
          return songRequest;
        }
      });
      setSongRequests(updatedSongRequests);
    }
  }, [suggestionResolved]);

  const initSongRequests = async (eventId: number) => {
    try {
      const { data } = await getSongRequestsByEventId({ variables: { eventId } });
      sortAndInitSongRequests(data.suggestions || []);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const sortAndInitSongRequests = (incommingSongRequests: any[]) => {
    const sortedSongRequests = [...incommingSongRequests]
      .map((songRequest) => ({
        ...songRequest,
        username: songRequest.payment.user.profile.username,
        amount: songRequest.payment.amount,
      }))
      .sort((a, b) => b.id - a.id);
    setSongRequests(sortedSongRequests);
  };

  return (
    <section
      id="songRequests"
      className="gradient-background mt-4 flex min-h-0 w-full grow flex-col rounded-t-[1.25rem]"
    >
      <span className="flex w-full flex-row items-center px-6 pt-2 font-bold text-neon-pink">
        <p>Song Requests</p>
      </span>
      {isLoading && (
        <div className="mt-20 flex h-full w-full items-start justify-center text-neon-pink">
          <Spinner />
        </div>
      )}
      {!isLoading && songRequests?.length > 0 && (
        <>
          <div className="flex w-full flex-row items-center justify-between gap-2 px-4 py-2">
            <p>Did not find your song?</p>
            <ButtonWrapper onClick={() => onRequestSongClick()} id="requestButtonRequestsTab">
              <p className="pointer-events-none text-sm font-semibold">Request</p>
            </ButtonWrapper>
          </div>
          <div
            id="songRequestsList"
            className="flex grow flex-col overflow-auto px-6"
            onScroll={(event) => {
              const scrollItem = document.querySelector('#songRequestsList');
              onScroll(scrollItem?.scrollTop || 0);
            }}
          >
            {songRequests.map((request: any, index: number) => (
              <SongRequestItem key={index} index={index + 1} request={request} />
            ))}
          </div>
        </>
      )}
      {!isLoading && !(songRequests?.length > 0) && (
        <div id="empty" className="flex flex-col items-center justify-center gap-3 p-4">
          <p className="text-sm font-thin">No song requests yet</p>
          <div id="message" className="text-md text-center">
            Got a new grooving idea?
          </div>
          <ButtonWrapper
            onClick={() => onRequestSongClick()}
            id="requestButtonRequestsTab"
            classNames="!p-4"
          >
            <p className="pointer-events-none text-sm font-semibold">Request a Song</p>
          </ButtonWrapper>
        </div>
      )}
    </section>
  );
};

export default SongRequests;
