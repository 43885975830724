import Event from "./event-item";

function Eventlist({ events }: { events: any[] }) {
  const listItems = events.map((event) => {
    return <Event key={event.id} event={event} />;
  });
  return <ol className="w-full overflow-scroll">{listItems}</ol>;
}

export default Eventlist;
