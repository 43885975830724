import React, { useContext, useEffect } from 'react';
import ModuleMobileLayout from '../components/module-mobile-layout';
import { AuthContext } from '../common/auth/auth.context';
import { useNavigate } from 'react-router-dom';
import format from 'date-fns/format';
import { usePaymentsQuery } from '../services/payment/payment.service';
import { IUserPayment, PaymentStatus, PaymentType } from '../common/models/user-payment.model';

const GuestPayments = () => {
  const router = useNavigate();
  const { profile } = useContext(AuthContext);

  const {
    data: payments,
    isFetching: isLoadingPayments,
    error: paymentError,
  } = usePaymentsQuery(profile?.id as number);

  useEffect(() => {
    if (!profile) {
      router('/');
    }
  }, []);

  const getPaymentType = (type: PaymentType) => {
    if (type === PaymentType.TIP) return 'Tip DJ';
    if (type === PaymentType.SUGGESTION) return 'Song Request';
    return 'Payment';
  };

  const getStatusChip = (status: string) => {
    switch (status) {
      case PaymentStatus.PENDING:
        return (
          <div className="w-fit rounded-full bg-neon-orange px-2 py-1 text-[0.625rem] font-medium text-white shadow-md">
            Pending
          </div>
        );
      case PaymentStatus.COMPLETE:
        return (
          <div className="w-fit rounded-full bg-success px-2 py-1 text-[0.625rem] font-medium text-white shadow-md">
            Approved
          </div>
        );
      case PaymentStatus.REFUNDED:
        return (
          <div className="w-fit rounded-full bg-neon-red px-2 py-1 text-[0.625rem] font-medium text-white shadow-md">
            Rejected
          </div>
        );
    }
  };

  return (
    <ModuleMobileLayout
      canGoBack={true}
      className="gradient-background flex !h-[100svh] min-h-fit flex-col"
    >
      <main className="relative flex h-full w-full flex-col overflow-scroll p-3 text-white">
        <div className="flex flex-col pb-3 pt-12">
          <p className="text-lg font-semibold">Payments</p>
          <p className="text-xs">Review your payments history here</p>
        </div>
        {payments?.length === 0 && (
          <div className="flex h-full w-full items-center justify-center text-sm font-thin">
            <p>There are no payments recorded</p>
          </div>
        )}
        {payments && payments?.length > 0 && (
          <div className="flex w-full flex-col gap-2">
            {payments.map((paymentItem: IUserPayment) => (
              <div
                key={paymentItem.id}
                className="flex flex-row items-center divide-x divide-dashed divide-white-60 rounded-lg border-[1px] border-white-30 bg-gray-selected px-3 py-3 backdrop-blur-md"
              >
                <div className="flex h-full w-[75%] flex-col gap-1">
                  <div className="flex gap-2">
                    <p className="font-semibold">{getPaymentType(paymentItem.paymentType)}</p>
                    {getStatusChip(paymentItem.status)}
                  </div>
                  <p className="text-xs font-medium">{paymentItem.eventName}</p>
                  <p className="text-xs font-light">
                    {format(new Date(paymentItem.createdOn), 'dd.MM.yyyy')}
                  </p>
                </div>
                <div className="flex h-full w-[25%] flex-col items-center justify-center">
                  <p className="text-2xl font-medium">{paymentItem.amount / 100}</p>
                  <p className="text-xs font-thin">lei</p>
                </div>
              </div>
            ))}
          </div>
        )}
      </main>
    </ModuleMobileLayout>
  );
};

export default GuestPayments;
