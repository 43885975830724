import { useContext, useEffect } from 'react';
import { Routes, Route, Navigate, useSearchParams } from 'react-router-dom';
import { AuthContext } from '../common/auth/auth.context';
import Event from '../pages/Event';
import EventAdd from '../pages/EventAdd';
import EventEdit from '../pages/EventEdit';
import EventMemories from '../pages/EventMemories';
import Events from '../pages/Events';
import EventStatistics from '../pages/EventStatistics';
import Guest from '../pages/Guest';
import GuestEvent from '../pages/GuestEvent';
import GuestEventFinished from '../pages/GuestEventFinished';
import GuestLobby from '../pages/GuestLobby';
import GuestPayments from '../pages/GuestPayments';
import GuestProfile from '../pages/GuestProfile';
import JukeApp from '../pages/JukeApp';
import Login from '../pages/Login';
import MemoriesApp from '../pages/MemoriesApp';
import Menu from '../pages/Menu';
import Playlist from '../pages/Playlist';
import PlaylistAdd from '../pages/PlaylistAdd';
import Playlists from '../pages/Playlists';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import Terms from '../pages/Terms';
import AdminRoutes from './AdminRoutes';
import GuestRoutes from './GuestRoutes';
import ProtectedRoutes from './ProtectedRoutes';
import PublicRoutes from './PublicRoutes';

const MainRoutes = () => {
  const { profile } = useContext(AuthContext);
  const [search, setSearch] = useSearchParams();

  useEffect(() => {
    const partyCode = search.get('partyCode');
    if (partyCode) {
      localStorage.setItem('partyCode', partyCode);
    }
  }, [search]);

  return (
    <Routes>
      {/** Protected Routes */}
      {/** Wrap all Route under ProtectedRoutes element */}
      <Route path="/" element={<ProtectedRoutes />}>
        <Route path="/">
          <Route
            path="/"
            element={
              profile?.role === 'admin' ? (
                <Navigate replace to="dj" />
              ) : (
                <Navigate replace to="guest" />
              )
            }
          />
          {/* DJ ROUTES */}
          <Route path="dj" element={<AdminRoutes />}>
            <Route path="/dj" element={<Navigate replace to="/dj/menu" />} />
            <Route path="/dj/menu" element={<Menu />} />
            <Route path="/dj/playlists" element={<Playlists />} />
            <Route path="/dj/playlist/add" element={<PlaylistAdd />} />
            <Route path="/dj/playlist/:id" element={<Playlist />} />
            <Route path="/dj/events" element={<Events />} />
            <Route path="/dj/event/add" element={<EventAdd />} />
            <Route path="/dj/event/edit/:id" element={<EventEdit />} />
            <Route path="/dj/event/:id" element={<Event />} />
            <Route path="/dj/event/:id/memories-wall" element={<EventMemories />} />
            <Route path="/dj/event/:id/statistics" element={<EventStatistics />} />
          </Route>
          {/* GUEST ROUTES */}
          <Route path="guest" element={<GuestRoutes />}>
            <Route path="/guest" element={<Guest />} />
            <Route path="/guest/terms" element={<Terms />} />
            <Route path="/guest/profile" element={<GuestProfile />} />
            <Route path="/guest/profile/payments" element={<GuestPayments />} />
            <Route path="/guest/lobby" element={<GuestLobby />} />
            <Route path="/guest/event/:id" element={<GuestEvent />} />
            <Route path="/guest/event/:id/juke" element={<JukeApp />} />
            <Route path="/guest/event/:id/memories-wall" element={<MemoriesApp />} />
            <Route path="/guest/finished/:id" element={<GuestEventFinished />} />
          </Route>
        </Route>
      </Route>
      {/** Public Routes */}
      {/** Wrap all Route under PublicRoutes element */}
      <Route path="login" element={<PublicRoutes />}>
        <Route path="/login" element={<Login />} />
        <Route path="/login/policy" element={<PrivacyPolicy />} />
        <Route path="/login/terms" element={<Terms />} />
      </Route>
      <Route path="*" element={<Navigate replace to="/" />} />
    </Routes>
  );
};

export default MainRoutes;
