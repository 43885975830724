import React from 'react';
import { Button } from 'reactstrap';

const OutlinedButton = ({
  icon,
  children,
  onClick,
  className,
}: {
  children: any;
  onClick?: any;
  icon?: React.ReactNode;
  className?: string;
}) => {
  return (
    <Button
      className={`
        bg-transparent
        flex h-10 w-36 min-w-[8rem] 
        items-center justify-center
        rounded-lg border-none p-3 
        text-gray ring-2 
        ring-inset ring-neon-pink
        hover:border-none hover:bg-[#C450A7] active:bg-[#C450A7] ${className}`}
      onClick={onClick}
    >
      <span className={`${icon ? 'mr-2' : ''}`}>{children}</span>
      {icon}
    </Button>
  );
};

export default OutlinedButton;
