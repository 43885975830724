import { useContext, useEffect, useState } from 'react';
import { Button, Modal, ModalBody, Spinner } from 'reactstrap';
import PrimaryButton from './primary-button';
import { usePostMemoryMutation } from '../services/memories/memories.service';
import { AuthContext } from '../common/auth/auth.context';
import loadingAnimation from '../assets/lottie/image-loader.json';
import Lottie from 'lottie-react';
import useToast from '../common/hooks/useToast';
import { ERRORS } from '../common/constants/errors';
import { ToastType } from '../common/models/toast-type.enum';

const ImageModal = ({
  toggle,
  files,
  eventId,
  handlePostUpload,
}: {
  toggle: () => void;
  files: FileList | undefined;
  eventId: number;
  handlePostUpload: () => void;
}) => {
  const [loading, setLoading] = useState<Boolean>(true);
  const [uploadLoading, setUploadLoading] = useState<boolean>(false);
  const [description, setDescription] = useState<string>('');
  const { profile } = useContext(AuthContext);
  const { mutateAsync: postMemory } = usePostMemoryMutation();

  useEffect(() => {
    setTimeout(() => {
      const canvas: HTMLCanvasElement | null = document.getElementById(
        'imageCanvas',
      ) as HTMLCanvasElement;

      if (canvas && files) {
        const ctx = canvas.getContext('2d');

        canvas.height = 96;
        canvas.width = 96;

        const reader = new FileReader();
        reader.onload = function (event) {
          const img = new Image();
          img.onload = function () {
            if (canvas) {
              const { offsetX, offsetY, width, height } = cover(
                canvas.width,
                canvas.height,
                img.width,
                img.height,
              );
              ctx?.drawImage(img, offsetX, offsetY, width, height);
            }
          };
          img.src = (event.target?.result || '') as string;
        };
        reader.readAsDataURL(files[0]);
      }
      setLoading(false);
    }, 2000);
  }, [files]);

  const fit = (contains: any) => {
    return (
      parentWidth: any,
      parentHeight: any,
      childWidth: any,
      childHeight: any,
      scale = 1,
      offsetX = 0.5,
      offsetY = 0.5,
    ) => {
      const childRatio = childWidth / childHeight;
      const parentRatio = parentWidth / parentHeight;
      let width = parentWidth * scale;
      let height = parentHeight * scale;

      if (contains ? childRatio > parentRatio : childRatio < parentRatio) {
        height = width / childRatio;
      } else {
        width = height * childRatio;
      }

      return {
        width,
        height,
        offsetX: (parentWidth - width) * offsetX,
        offsetY: (parentHeight - height) * offsetY,
      };
    };
  };

  const cover = fit(false);

  const onImageSubmit = async () => {
    setUploadLoading(true);
    try {
      if (files) {
        const result = await postMemory({
          eventId,
          description,
          photo: files[0],
          userId: profile?.id as number,
        });
        if (result) {
          handlePostUpload();
        }
      }
    } catch (error) {
      useToast(ERRORS.dj.event.view.getPastSessions, ToastType.ERROR);
      console.error(error);
    }
    setUploadLoading(false);
    toggle();
  };

  return (
    <Modal isOpen={true} toggle={toggle} centered>
      <ModalBody className="gradient-background h-fit min-h-[50vh] text-white">
        {!loading && !files && (
          <div className="flex flex-col gap-2 p-4">
            <div className="mb-4 text-center text-lg font-bold ">Oops</div>
            <p className="text-center">
              An error occured while uploading files. Please try again later.
            </p>
            <Button
              className={`flex h-[2.5rem] w-auto min-w-[5rem] items-center justify-around rounded-lg border-none bg-light-gray-100 p-3 hover:border-none hover:bg-light-gray-200 active:bg-light-gray-200`}
              onClick={toggle}
            >
              <span className="text-black">Close</span>
            </Button>
          </div>
        )}
        {files && (
          <div className="flex h-full flex-col gap-4 p-4">
            <div className="text-center text-lg font-bold ">Upload your memories!</div>
            <div className="flex h-full w-full flex-col items-center justify-center gap-3">
              <div>
                <canvas id="imageCanvas"></canvas>
                <button></button>
              </div>
              {loading && (
                <Lottie
                  className="lottie absolute h-40"
                  animationData={loadingAnimation}
                  loop={true}
                />
              )}
              {!loading && (
                <textarea
                  id="description"
                  className={`${
                    uploadLoading ? 'pointer-events-none' : ''
                  } border-1 h-28 w-full rounded-md border-2 border-solid p-2 border-gradient-br-input-border-neon gradient-border-1`}
                  placeholder="Add description"
                  onChange={(event) => setDescription(event.target.value)}
                ></textarea>
              )}
            </div>
            {!loading && (
              <div className="flex h-fit flex-row items-center justify-between gap-4">
                {!uploadLoading && (
                  <Button
                    className={`flex h-[2.5rem] w-auto min-w-[5rem] items-center justify-around rounded-lg border-none bg-gray-selected p-3 hover:border-none hover:bg-light-gray-200 active:bg-light-gray-200`}
                    onClick={toggle}
                  >
                    <span>Cancel</span>
                  </Button>
                )}
                <PrimaryButton
                  className={`${uploadLoading ? 'disabled !w-full' : ''}`}
                  onClick={onImageSubmit}
                  color="bg-main"
                >
                  {!uploadLoading && 'Upload'}
                  {uploadLoading && <Spinner size="sm"></Spinner>}
                </PrimaryButton>
              </div>
            )}
          </div>
        )}
      </ModalBody>
    </Modal>
  );
};

export default ImageModal;
