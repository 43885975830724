const Header = ({ title, subtitle }: { title: string; subtitle?: string }) => {
  return (
    <div className="py-2">
      <h1 className="text-4xl text-white">{title}</h1>
      <p className="text-xs font-thin leading-8 text-white">{subtitle || ""}</p>
    </div>
  );
};

export default Header;
