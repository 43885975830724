export const ERRORS = {
  dj: {
    playlist: {
      list: {
        getAll: 'Could not load the playlists',
      },
      add: {
        create: 'Error while creating the playlist',
        addSongFromYoutube: 'Artist and song name are mandatory',
        requiredSong: 'Cannot create a playlist without at least one song',
      },
      edit: {
        get: 'Could not load playlist from the database',
        update: 'Error while updating the playlist',
        delete: 'Error while deleting the playlist',
        attached: 'Playlist is part of at least one event',
      },
    },
    event: {
      list: {
        getAll: 'Could not load events',
      },
      add: {
        getPlaylists: 'Could not load playlists',
        requiredPlaylist: 'Cannot create event without at least one playlist',
        create: 'Error while creating the playlist',
        unique: 'The event name is already taken',
      },
      edit: {
        getEvent: 'Could not load event data',
        update: 'Error while updating the event',
        delete: 'Error while deleting the event',
      },
      view: {
        get: 'Could not load the event',
        getPlaylists: 'Could not load playlists for this event',
        getSession: 'Could not load session for this event',
        getEventUsers: 'Could not load participants',
        getPastSessions: 'Could not load past sessions',
        playSong: 'Error while playing the song',
        playSongRequest: 'Error while playing the song request',
        rejectSongRequest: 'Error while rejecting the song request',
        startEvent: 'Error while starting the event',
        startSession: 'Error while starting the session',
        stopEvent: 'Error while stoping the event',
        updateEvent: 'Error while updateing playlists',
        requestFeedback: 'Error while requesting feedback',
        getSongRequests: 'Could not load song requests',
      },
      statistics: {
        voters: 'Could not retrieve top voters',
      },
    },
  },
  guest: {
    join: {
      getUserEvent: 'Could not load user data',
      invalidCode: 'The code is not valid',
      userJoined: 'User is already a part of the event',
      onboarding: 'Error while onboarding',
      noActiveEvent: 'There is not active event',
    },
    event: {
      getEvent: 'Could not load event data',
      getParticipants: 'Could not load participants data',
      getSongs: 'Could not load song data',
      getSession: 'Could not load session data',
      getVotes: 'Error while updateing the votes',
      voteLimit: 'You have reached your vote limit',
      vote: 'Error while votting',
      disconnect: 'Error while leaving the event',
      uploadMemory: 'Error while uploading memory. Please try again later!',
      requestSong: 'An error occured while processing the request. Please try again later!',
      requestSongRequiredInputs: 'Song artist, title and tip are required to request a song.',
      tipDj: 'An error occured while tipping the DJ. Please try again later!',
      tipDjRequiredInputs: 'Tip amount is required',
      tipsAmount: 'An error occured while getting the total tips',
    },
    profile: {
      create: 'Could not create the profile',
      unique: 'This username is already taken',
    },
    payment: {
      confirm: 'Eroare la efectuarea platii!',
      confirmCard:
        'Eroare la confirmarea platii cu cardul, va rugam sa incercati alta metoda de plata!',
    },
  },
};
