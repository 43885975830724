/**
 * OBS: Max daily quota 10000
 */

import { parse } from 'tinyduration';

// const API = process.env.GOOGLE_API
const API = 'AIzaSyCbEWv6rT4jTKha1FrWjfT5bkxYoLzLTc4';
const MAX_RESULTS = 20;
const BASE_URL = 'https://www.googleapis.com/youtube/v3/';
// response details about the song, snippet- title, description and contentDetails - Duration
const DETAILS_RESPONSE_REQ = encodeURIComponent('snippet,contentDetails');

export interface Song {
  id: string;
  title: string;
  artist: string;
}

export const searchYoutube = async (searchWord: string): Promise<Song[]> => {
  let songs: Song[] = [];
  if (searchWord) {
    const ids = await searchVideos(searchWord);
    songs = await getVideosDetails(ids);
  }

  return Promise.resolve(songs);
};

// This will return an array for most popular video ids for the selected search word
const searchVideos = async (searchWord: string): Promise<string> => {
  let videoIds = '';

  try {
    // this has a quota of 100
    const resonse = await fetch(
      `${BASE_URL}search?key=${API}&part=id&order=relevance&maxResults=${MAX_RESULTS}&q=${searchWord}`,
    );

    const jsonResponse = await resonse.json();

    videoIds = (<any[]>jsonResponse.items)
      .map((item: { id: any }) => item.id.videoId)
      .join(',');
  } catch (error) {
    console.error(error);
  }

  return encodeURIComponent(videoIds);
};

const getVideosDetails = async (videoIds: string): Promise<any> => {
  let searchResults = [];

  try {
    const resonse = await fetch(
      `${BASE_URL}videos?type=video&key=${API}&part=${DETAILS_RESPONSE_REQ}&id=${videoIds}`,
    );

    const responseJson = await resonse.json();

    searchResults = <any[]>responseJson.items;
  } catch (error) {
    console.error(error);
  }

  return parseYoutubeSongsResults(searchResults);
};

const parseYoutubeSongsResults = (results: any[]): Partial<Song>[] => {
  return results.map((item: any) => {
    const [artist, title] = (<string>item.snippet.title).split('-');

    return {
      artist: artist?.trim(),
      title: title?.trim(),
    };
  });
};
