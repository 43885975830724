import * as React from 'react';
import { compose, withStateHandlers } from 'recompose';
import ConfirmationModal from './confirmation-modal';
// import Confirm from 'semantic-ui-react/dist/commonjs/addons/Confirm/Confirm';

/**
 * with confirm hoc
 * wraps an underlying component with a modal action confirmation
 */

export type ConfirmOptions = {
  confirmText?: string; // text to display when confirm is triggered
  subtitle?: string;
  children?: any;
  onConfirm?: () => any; // action if confirmed
};

type State = { isConfirmVisible: boolean };

type Updaters = {
  showConfirm: () => any;
  hideConfirm: () => any;
};

type WrappedProps = State & Updaters & ConfirmOptions;

export const WithConfirm = compose<WrappedProps, ConfirmOptions>(
  withStateHandlers<State, Updaters, WrappedProps>(
    { isConfirmVisible: false },
    {
      showConfirm:
        ({ isConfirmVisible }: any) =>
        () => ({
          isConfirmVisible: true,
        }),
      hideConfirm:
        ({ isConfirmVisible }: any) =>
        () => ({
          isConfirmVisible: false,
        }),
    },
  ),
)((props) => {
  const {
    children,
    confirmText = 'Are you sure?',
    subtitle,
    onConfirm,
    showConfirm,
    hideConfirm,
    isConfirmVisible,
  } = props;

  const child = React.Children.only(props.children);
  const cloned = React.cloneElement(child, { onClick: showConfirm });

  return (
    <>
      <ConfirmationModal
        title={confirmText}
        subtitle={subtitle}
        isOpen={isConfirmVisible}
        toggle={hideConfirm}
        onConfirm={() => {
          hideConfirm();
          typeof onConfirm === 'function' && onConfirm();
        }}
      />

      {cloned}
    </>
  );
});

export default WithConfirm;
