import React, { useEffect, useState } from 'react';
import { AiOutlineHeart, AiTwotoneHeart } from 'react-icons/ai';
import { ImageType } from '../pages/MemoriesApp';
import ButtonWrapper from './button-wrapper';
import loadingAnimation from '../assets/lottie/image-loader.json';
import Lottie from 'lottie-react';

const ImageCard = ({
  image,
  onLikeClick,
}: {
  image: ImageType;
  onLikeClick: (imageId: number) => void;
}) => {
  const [showMore, setShowMore] = useState<boolean>(false);
  const [displayShowMore, setDisplayShowMore] = useState<boolean>(false);
  const [imageLoading, setImageLoading] = useState<boolean>(true);

  useEffect(() => {
    if (!imageLoading) {
      const descriptionParagraph = document.getElementById(`description-${image.id}`);
      if (descriptionParagraph) {
        if (isEllipsisActive(descriptionParagraph)) {
          setDisplayShowMore(true);
        }
      }
    }
  }, [imageLoading]);

  const isEllipsisActive = (e: any) => {
    return e.offsetWidth < e.scrollWidth;
  };

  return (
    <ButtonWrapper classNames="h-fit !rounded-2xl !p-1 !border-gradient-br-input-border-black60 w-full !min-h-[15rem]">
      <div className="flex w-full flex-1 flex-col p-1">
        <img
          id={`image-${image.id}`}
          className="none hidden h-auto w-full rounded-2xl bg-contain bg-center"
          src={image.path}
          onLoad={() => {
            document.getElementById(`image-${image.id}`)?.classList.remove('hidden');
            setImageLoading(false);
          }}
        ></img>
        {imageLoading && <Lottie className="lottie" animationData={loadingAnimation} loop={true} />}
        {!imageLoading && (
          <div className="flex w-full flex-col items-start justify-between overflow-hidden p-3">
            <div className="flex w-full flex-row justify-between gap-1">
              <p className="font-semibold">{image.user.profile.username}</p>
              <div className="flex gap-1">
                {image.likesCount}
                <section onClick={() => onLikeClick(image.id)}>
                  {image.liked ? (
                    <AiTwotoneHeart size={26} color="#EF5DA8" />
                  ) : (
                    <AiOutlineHeart size={26} />
                  )}
                </section>
              </div>
            </div>
            <div
              className={`${
                showMore ? 'flex-col' : 'flex-row justify-between'
              } flex w-full gap-1 text-sm`}
            >
              <p
                id={`description-${image.id}`}
                className={`${
                  showMore ? 'w-full break-all' : ' w-[60vw] truncate text-ellipsis'
                } font-light`}
              >
                {image.description}
              </p>
              {displayShowMore && (
                <p onClick={() => setShowMore(!showMore)} className="text-right text-main">
                  {showMore ? 'Hide' : 'Show more'}
                </p>
              )}
            </div>
          </div>
        )}
      </div>
    </ButtonWrapper>
  );
};

export default ImageCard;
