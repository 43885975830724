export const MIN_TIP_AMOUNT = 30;

export const PAYMENT_OPTION = [
  { id: 1, label: '30', value: 30 },
  { id: 2, label: '50', value: 50 },
  { id: 3, label: '100', value: 100 },
];

export const STATISTICS_TOAST_TIMER = 20 * 1000;
export const REQUEST_PLAYING_TIMER = 60 * 1000;
export const STATISTICS_CAROUSEL_INTERVAL = 10 * 1000;
