export const loadImage = (imagePath: string) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      // If somethis goes really wrong, just resolve it
      resolve(imagePath);
    }, 5000);

    const img = new Image();
    img.onload = () => {
      resolve(img);
    };
    img.src = imagePath;
  });
};
