import votesService from "./votes.service";

const parseSessionData = (sessions: any[]) => {
  const updatedSessions: any[] = [];
  sessions.forEach((session) => {
    // get the top song for each session
    const topSong = votesService.parseSongData(session.songVotes)[0];

    if (topSong) {
      updatedSessions.push({ ...session, songVotes: [topSong] });
    }
  });

  return updatedSessions;
};

export default { parseSessionData };
