import { ChevronLeft, Power } from 'react-feather';
import React, { useContext, useEffect, useRef, useState } from 'react';
import WithConfirm from './with-confirmation';
import MobileBG from '../assets/img/background-mobile.png';
import { loadImage } from '../common/services/preload-images.service';
import LoadingScreen from './loading-screen';
import PrimaryButton from './primary-button';
import { Button, Modal, ModalBody } from 'reactstrap';
import QRCODE from '../assets/img/qrcode_juke.png';
import { BsFillShareFill } from 'react-icons/bs';
import { AuthContext } from '../common/auth/auth.context';
import RoundButtonWrapper from './round-button-wrapper';
import { Router } from '@aws-amplify/ui-react/dist/types/components/Authenticator/Router';
import { useNavigate } from 'react-router-dom';
import { CameraIcon } from '@heroicons/react/24/solid';
import ButtonWrapper from './button-wrapper';

const preloadImages = [MobileBG];

const ParalaxMobileLayout = ({
  children,
  canGoBack,
  className,
  onLeave,
  isLoading,
  menuClassName,
  canAnimateButton,
  openImageUploadModal,
}: {
  onLeave?: any;
  children?: React.ReactNode;
  canGoBack?: boolean;
  className?: string;
  isLoading?: boolean;
  menuClassName?: string;
  canAnimateButton: boolean;
  openImageUploadModal: (event: any) => void;
}) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [wrapperLoading, setWrapperLoading] = useState(true);
  const { logout } = useContext(AuthContext);
  const [scrollParam, setScrollParam] = useState<number>(0);
  const prevScrollParam = useRef<number>(0);
  const router = useNavigate();

  useEffect(() => {
    Promise.all(preloadImages.map((path) => loadImage(path))).then(() => {
      setWrapperLoading(false);
    });
  }, []);

  useEffect(() => {
    const menu = document.getElementById('sticky-header-hide');
    const imageUploadMenu = document.getElementById('sticky-menu-hide');

    if (menu) {
      if (scrollParam <= 0) {
        menu.classList.remove('active');
        imageUploadMenu?.classList.remove('active');
      } else if (prevScrollParam.current < (scrollParam || 0)) {
        //scroll down
        menu.classList.add('active');
        imageUploadMenu?.classList.add('active');
      } else if (prevScrollParam.current > (scrollParam || 0)) {
        //scroll up
        menu.classList.remove('active');
        imageUploadMenu?.classList.remove('active');
      }
    }
    prevScrollParam.current = scrollParam || 0;
  }, [scrollParam]);

  return (
    <>
      {(isLoading || wrapperLoading) && <LoadingScreen />}
      {!isLoading && !wrapperLoading && (
        <section className="h-full">
          <div
            className={`hidden h-screen flex-col items-center justify-center bg-desktop bg-cover bg-center bg-no-repeat px-24 py-16 lg:flex`}
          >
            <h1 className="flex flex-col items-center justify-center text-3xl text-white">
              <div>Please open this view on a mobile device</div>
              <PrimaryButton color="bg-main" onClick={logout}>
                Logout
              </PrimaryButton>
            </h1>
          </div>
          <div
            id="scroll-target"
            className={`relative h-full min-h-[100svh] bg-framemobile bg-cover bg-top bg-no-repeat lg:hidden ${className}`}
            onScroll={(event) => {
              const scrollItem = document.querySelector('#scroll-target');
              setScrollParam(scrollItem?.scrollTop || 0);
            }}
          >
            <section
              id="sticky-header-hide"
              className={`sticky-header-hide fixed top-0 z-10 w-full`}
            >
              <section
                className={`flex h-fit w-full flex-row-reverse items-start justify-between p-3 ${menuClassName}`}
              >
                <WithConfirm
                  onConfirm={logout}
                  confirmText="Logging out..."
                  subtitle="We will wait you to come back later!"
                >
                  {/* <div> */}
                  <RoundButtonWrapper>
                    <Power size={25} className="text-white" />
                  </RoundButtonWrapper>
                  {/* </div> */}
                </WithConfirm>
                {canGoBack && (
                  <div className="flex gap-3">
                    {onLeave && (
                      <RoundButtonWrapper
                        onClick={() => {
                          localStorage.setItem('route', 'event');
                          router(-1);
                        }}
                      >
                        <ChevronLeft size={25} className="cursor-pointer text-white" />
                      </RoundButtonWrapper>
                    )}
                    <RoundButtonWrapper
                      classNames="!bg-main"
                      onClick={setModalOpen.bind(null, true)}
                    >
                      <BsFillShareFill size={16} className="cursor-pointer text-white" />
                    </RoundButtonWrapper>
                  </div>
                )}
              </section>
              <div className="absolute top-0 z-[-1] h-full w-full"></div>
            </section>
            <section className="flex h-full min-h-fit w-full">{children}</section>
            {modalOpen && (
              <Modal
                isOpen={true}
                toggle={setModalOpen.bind(null, true)}
                centered
                size="lg"
                contentClassName="rounded-[1.25rem] bg-[#F3F3F3] max-w-lg"
                className="max-w-lg"
              >
                <ModalBody className="gradient-background text-white">
                  <div className="flex h-[15rem] flex-col items-center justify-around px-2 pt-2">
                    <div id="header" className="flex flex-col items-center">
                      <p className="text-center text-xl font-semibold">Share with your friends</p>
                    </div>
                    <img className="mt-2" src={QRCODE} alt="me" width={140} height={140} />
                    <div className="flex items-center justify-evenly pt-2">
                      <Button
                        className={`flex h-[2.5rem] w-auto min-w-[10rem] items-center justify-around rounded-lg border-none bg-gray-selected p-3 hover:border-none hover:bg-light-gray-200 active:bg-light-gray-200`}
                        onClick={setModalOpen.bind(null, false)}
                      >
                        <span className="mr-2">Cancel</span>
                      </Button>
                    </div>
                  </div>
                </ModalBody>
              </Modal>
            )}
            <section className="flex h-fit w-full justify-center">
              <ButtonWrapper
                id="sticky-menu-hide"
                classNames={`${
                  canAnimateButton ? 'custom-animation' : ''
                } !h-fit sticky-menu-hide fixed bottom-6 m-auto flex w-[80%] flex-row items-center justify-center gap-2 self-center rounded-full bg-black-60 py-3 text-white backdrop-blur-md`}
                onClick={(event) => openImageUploadModal(event)}
              >
                <CameraIcon className="h-4" />
                <p className="text-white">Upload your memories here</p>
              </ButtonWrapper>
            </section>
          </div>
        </section>
      )}
    </>
  );
};

export default ParalaxMobileLayout;
