import React from 'react';
import { Button } from 'reactstrap';

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(' ');
}

const PrimaryButton = ({
  color,
  icon,
  children,
  onClick,
  className,
}: {
  children: any;
  color: string;
  onClick?: any;
  icon?: React.ReactNode;
  className?: string;
}) => {
  return (
    <Button
      type="button"
      className={classNames(
        `flex h-[2.5rem] w-auto min-w-[8rem] items-center justify-center gap-1 rounded-lg ${color} border-none p-3 hover:border-none hover:bg-[#C450A7] active:bg-[#C450A7]`,
        className,
        !onClick ? 'pointer-events-none' : '',
      )}
      onClick={(event: any) => {
        event.preventDefault();
        if (onClick) onClick();
      }}
    >
      <span className="text-white">{children}</span>
      {icon}
    </Button>
  );
};

export default PrimaryButton;
