import { Button } from 'reactstrap';
import btn_google from '../assets/svg/btn_google_light_focus_ios.svg';

const GoogleButton = ({
  onClick,
  children,
}: {
  onClick: any;
  children: any;
}) => {
  return (
    <Button
      className="flex h-[2.5rem] w-[15rem] items-center rounded-lg border-none bg-white"
      onClick={onClick}
    >
      <div className="w-[10%]">
        <img src={btn_google} />
      </div>
      <span className="w-[90%] text-black-80">{children}</span>
    </Button>
  );
};

export default GoogleButton;
