import React from 'react';
import { TrophyIcon } from '@heroicons/react/24/solid';
import { HeartIcon } from '@heroicons/react/24/solid';
import { UsersIcon } from '@heroicons/react/24/solid';
import { MusicalNoteIcon } from '@heroicons/react/24/solid';

export type MENU_TYPE =
  | 'playlist'
  | 'participants'
  | 'previous_sessions'
  | 'feedback'
  | 'song_requests';

export const StickyMenu = ({
  selected,
  onPlaylistClick,
  onParticipantsClick,
  onWinnersClick,
  onSongRequestClick,
}: {
  selected: MENU_TYPE;
  onPlaylistClick: () => void;
  onParticipantsClick: () => void;
  onWinnersClick: () => void;
  onSongRequestClick: () => void;
}) => {
  const menuItems = [
    {
      key: 'playlist',
      label: 'Playlist',
      icon: <HeartIcon className="h-5 w-5"></HeartIcon>,
      command: onPlaylistClick,
    },
    {
      key: 'participants',
      label: 'Participants',
      icon: <UsersIcon className="h-5 w-5"></UsersIcon>,
      command: onParticipantsClick,
    },
    {
      key: 'previous_sessions',
      label: 'Previous Sessions',
      icon: <TrophyIcon className="h-5 w-5"></TrophyIcon>,
      command: onWinnersClick,
    },
    {
      key: 'song_requests',
      label: 'Song Requests',
      icon: <MusicalNoteIcon className="h-5 w-5"></MusicalNoteIcon>,
      command: onSongRequestClick,
    },
  ];

  return (
    <div
      id="sticky-menu-hide"
      className="sticky-menu-hide fixed bottom-6 flex w-[80%] flex-row justify-around self-center rounded-full bg-black-60 py-3 backdrop-blur-md"
    >
      {menuItems.map((menuItem) => {
        return (
          <div
            key={menuItem.key}
            className={`${selected === menuItem.key ? 'text-neon-purple' : ''}`}
            onClick={menuItem.command}
          >
            {menuItem.icon}
          </div>
        );
      })}
    </div>
  );
};

export default StickyMenu;
