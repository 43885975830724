import React, { useState } from 'react';
import { Song } from '../common/services/youtube.service';
import { CheckCircle } from 'react-feather';
import { PlaylistModel } from '../common/models/playlist.model';
import { useNavigate } from 'react-router-dom';

export const PlaylistItem = ({
  item,
  selectable,
  onSelect,
  active,
}: {
  item: PlaylistModel;
  selectable?: boolean;
  onSelect?: any;
  active?: boolean;
}) => {
  const router = useNavigate();
  const [selected, setSelected] = useState(active);

  const parsePreview = (songs: Song[]) => {
    const artists = songs.map((song) => song.artist);
    const artistsUnique = new Set(artists);
    return Array.from(artistsUnique).join(',');
  };

  const onItemClick = () => {
    if (selectable && onSelect) {
      setSelected(!selected);
      onSelect(item.id);
    } else {
      router(`/dj/playlist/${item.id}`);
    }
  };

  return (
    <div
      className="card mr-[10px] mt-[10px] flex h-36 w-36 shrink-0 cursor-pointer flex-col bg-neon-red text-white hover:opacity-80"
      onClick={onItemClick}
    >
      <div className="flex flex-2 flex-col rounded-t-lg bg-neon-red px-[1rem] pt-[1rem]">
        <h4 className="flex-1 text-sm text-white">{item.name}</h4>
        <div className="flex flex-1 items-start justify-center">
          {selected && selectable && <CheckCircle className="opacity-1" />}
        </div>
      </div>
      <div className="flex-1 rounded-b-lg bg-[#00000080] px-2 py-3 opacity-25">
        <p className="h-full text-[10px] line-clamp-2">
          {parsePreview(item.songs)}
        </p>
      </div>
    </div>
  );
};

const PlaylistGroup = ({
  playlist,
  selectable,
  onSelect,
  selectedItems,
  classes,
}: {
  playlist: PlaylistModel[];
  selectable?: boolean;
  selectedItems?: number[];
  onSelect?: any;
  classes?: string;
}) => {
  return (
    <div className={`flex ${classes ? classes : 'flex-wrap overflow-y-auto'}`}>
      {playlist.map((item: PlaylistModel) => (
        <PlaylistItem
          key={item.id}
          item={item}
          selectable={selectable}
          onSelect={onSelect}
          active={
            selectedItems &&
            selectedItems?.findIndex((id) => id === item.id) >= 0
          }
        />
      ))}
    </div>
  );
};

export default PlaylistGroup;
