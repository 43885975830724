import { useLazyQuery, useSubscription } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { EVENT_UPDATES_SUBSCRIPTION, GET_GUEST_EVENT } from '../common/graphql/event.graphql';
import { SESSION_END_SUBSCRIPTION } from '../common/graphql/session.graphql';
import useToast from '../common/hooks/useToast';
import { ERRORS } from '../common/constants/errors';
import { ToastType } from '../common/models/toast-type.enum';
import 'primereact/resources/themes/lara-light-indigo/theme.css'; //theme
import 'primereact/resources/primereact.min.css'; //core css
import 'primeicons/primeicons.css';
import ButtonWrapper from '../components/button-wrapper';
import EventLobbyMobileLayout from '../components/event-lobby-mobile-layout';

const GuestEvent = () => {
  const { id } = useParams();
  const router = useNavigate();
  const [event, setEvent] = useState<any | null>(null);
  const [jukeEventEnabled, setJukeEventEnabled] = useState<boolean>(false);

  const [getEvent, { refetch }] = useLazyQuery(GET_GUEST_EVENT, {
    fetchPolicy: 'network-only',
  });
  // subscriptions
  const sessionEndSubscription = useSubscription(SESSION_END_SUBSCRIPTION);
  const eventUpdatesSubscription = useSubscription(EVENT_UPDATES_SUBSCRIPTION);

  useEffect(() => {
    //intercept browser back click to change route
    window.onpopstate = (event: any) => {
      if (
        event?.target.location.pathname.endsWith('/memories-wall') ||
        event?.target.location.pathname.endsWith('/juke') ||
        event?.target.location.pathname.endsWith(`/${id}`)
      ) {
        localStorage.setItem('route', 'event');
      }
    };
  });

  useEffect(() => {
    initEvent();
    // console.log('process.env.REACT_APP_JUKE_ENABLED', process.env.REACT_APP_JUKE_ENABLED);
    // if (process.env.REACT_APP_JUKE_ENABLED === 'true') {
    setJukeEventEnabled(true);
    // }
  }, []);

  useEffect(() => {
    if (sessionEndSubscription.data) {
      initEvent();
    }
  }, [sessionEndSubscription.data]);

  useEffect(() => {
    // event is over
    if (eventUpdatesSubscription.data?.eventUpdates.endDate) {
      router(`/guest/finished/${id}`, { replace: true });
    }
  }, [eventUpdatesSubscription.data]);

  useEffect(() => {
    if (event?.endDate) {
      router(`/guest/finished/${id}`, { replace: true });
    }
  }, [event]);

  const initEvent = async () => {
    try {
      console.log('init guest event');
      const eventResponse = await getEvent({
        variables: { eventId: +(id as string) },
      });
      if (!eventResponse.data.event.active) {
        if (eventResponse.data.event.endDate !== null) {
          router(`/guest/finished/${id}`, { replace: true });
        } else {
          router('/guest', { replace: true });
        }
      } else {
        setEvent(eventResponse.data.event);
        const eventActivities = [...eventResponse.data.event.activities];
        if (
          (eventActivities?.length === 1 &&
            eventActivities.find((activity) => activity.type === 'juke')) ||
          localStorage.getItem('route') === 'juke'
        ) {
          router(`/guest/event/${id}/juke`);
        } else if (
          (eventActivities?.length === 1 &&
            eventActivities.find((activity) => activity.type === 'memories')) ||
          localStorage.getItem('route') === 'memories-wall'
        ) {
          router(`/guest/event/${id}/memories-wall`);
        }
      }
    } catch (error) {
      useToast(ERRORS.guest.event.getEvent, ToastType.ERROR);
      console.error(error);
    }
  };

  return (
    <>
      {event?.activities?.length > 1 && (
        <div>
          <EventLobbyMobileLayout onLeave={() => initEvent()}>
            <main className="relative flex h-full w-full flex-col gap-4 overflow-hidden text-white">
              <p className="text-3xl font-medium">
                Let's <span className="font-semibold text-neon-green">JUKE</span> the night
                together!
              </p>
              <div className="z-10 flex h-full w-full flex-col gap-4">
                {event.activities.map((activity: { type: string }, index: number) => {
                  return (
                    <ButtonWrapper
                      key={index}
                      classNames="w-full h-fit !rounded-3xl !p-0 overflow-hidden !shadow-sm"
                      onClick={() => {
                        if (activity.type === 'juke' && !jukeEventEnabled) return;
                        activity.type === 'juke'
                          ? router(`/guest/event/${id}/juke`)
                          : router(`/guest/event/${id}/memories-wall`);
                      }}
                    >
                      {activity.type === 'juke' && (
                        <div
                          className={`relative flex h-full w-full scale-150 flex-col items-center justify-center overflow-hidden bg-frame5 bg-cover bg-bottom bg-no-repeat p-2`}
                        >
                          {!jukeEventEnabled && (
                            <p className="fixed bottom-8 z-50 text-xxs font-semibold text-white shadow-lg">
                              Starting soon
                            </p>
                          )}
                          {jukeEventEnabled && (
                            <div className="absolute h-full w-full !bg-black-60"></div>
                          )}
                          {!jukeEventEnabled && (
                            <div className="absolute h-full w-full !bg-gray-selected-30"></div>
                          )}
                          <div className="z-10 h-36 w-36 bg-juke-logo bg-contain bg-center bg-no-repeat"></div>
                        </div>
                      )}
                      {activity.type === 'memories' && (
                        <div className="relative flex h-full w-full items-center justify-center overflow-hidden bg-memories-background bg-cover bg-center bg-no-repeat p-2">
                          <div className="absolute h-full w-full !bg-[#09112F60]"></div>
                          <div className="z-10 h-36 w-48 bg-memories-logo bg-contain bg-center bg-no-repeat"></div>
                        </div>
                      )}
                    </ButtonWrapper>
                  );
                })}
              </div>
            </main>
          </EventLobbyMobileLayout>
        </div>
      )}
    </>
  );
};

export default GuestEvent;
