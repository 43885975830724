import React from 'react';
import { AiOutlineHeart, AiTwotoneHeart } from 'react-icons/ai';
import ButtonWrapper from './button-wrapper';

const SongItem = ({ song, index, voted, onVote }: any) => {
  return (
    <div className="song-item flex w-full cursor-pointer items-center py-2">
      <div id="index" className="flex">
        <ButtonWrapper classNames="!p-0 !w-[35px] !h-[35px]">
          <p className="text-xs text-white">{index + 1}</p>
        </ButtonWrapper>
      </div>
      <div id="song" className="flex flex-3 flex-col items-start justify-start px-4">
        <span className="text-sm font-bold">{song.title}</span>
        <span className="text-xs font-thin">{song.artist}</span>
      </div>
      <div id="votes" className="flex gap-2">
        <span className={`${!song.votes ? 'hidden' : ''}`}>
          {song.votes ? song.votes.length : ''}
        </span>
        {voted && voted() ? (
          <AiTwotoneHeart
            size={26}
            color="#EF5DA8"
            onClick={onVote && onVote.bind(null, song.id)}
          />
        ) : (
          <AiOutlineHeart size={26} onClick={onVote && onVote.bind(null, song.id)} />
        )}
      </div>
    </div>
  );
};

export default SongItem;
