import { useContext, useEffect, useState } from 'react';
import { Form } from 'reactstrap';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import VerticalLayout from '../components/vertical-layout';
import PlaylistGroup from '../components/playlist-group';
import { PlaylistModel } from '../common/models/playlist.model';
import Header from '../components/header';
import PrimaryInput from '../components/primary-input';
import PrimaryButton from '../components/primary-button';
import { Plus } from 'react-feather';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { AuthContext } from '../common/auth/auth.context';
import { GET_PLAYLISTS_BY_USER_ID } from '../common/graphql/playlist.graphql';
import { ADD_EVENT_MUTATION } from '../common/graphql/event.graphql';
import useToast from '../common/hooks/useToast';
import { ERRORS } from '../common/constants/errors';
import { ToastType } from '../common/models/toast-type.enum';
import { MESSAGES } from '../common/constants/messages';

const EventAdd = () => {
  const router = useNavigate();
  // form state
  const [name, setName] = useState<string>('');
  const [location, setLocation] = useState<string>('');
  const [date, setDate] = useState<Date>(new Date());
  const [hour, setHour] = useState<string>('');
  const [djName, setDjName] = useState<string>('');
  // submision
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [jukeSelected, setJukeSelected] = useState<boolean>(true);
  const [memoriesSelected, setMemoriesSelected] = useState<boolean>(false);

  const { profile } = useContext(AuthContext);
  const [playlists, setPlaylists] = useState<PlaylistModel[]>([]);
  const [selectedPlaylists, setSelectedPlaylists] = useState<number[]>([]);
  // make sure this is called after the profile is loaded
  const [getPlaylists] = useLazyQuery(GET_PLAYLISTS_BY_USER_ID, {
    fetchPolicy: 'network-only',
  });
  // mutations
  const [addNewEvent] = useMutation(ADD_EVENT_MUTATION);

  useEffect(() => {
    if (profile) {
      initPlaylists();
    }
  }, [profile]);

  const initPlaylists = async () => {
    try {
      const { data } = await getPlaylists({
        variables: { userId: profile?.id },
      });
      setPlaylists(data.user.playlist);
    } catch (error) {
      useToast(ERRORS.dj.event.add.getPlaylists, ToastType.ERROR);
      console.error(error);
    }
  };

  const onSubmit = async () => {
    setIsSubmitted(true);

    if (name && location && date && hour && djName) {
      if (selectedPlaylists.length === 0) {
        useToast(ERRORS.dj.event.add.requiredPlaylist, ToastType.ERROR);
        return;
      }
      // construct the date
      const startDate = new Date(date);
      startDate.setHours(+hour.split(':')[0]);
      startDate.setMinutes(+hour.split(':')[1]);

      const eventActivities = [];
      jukeSelected ? eventActivities.push(1) : null;
      memoriesSelected ? eventActivities.push(2) : null;

      const newEventData = {
        userId: profile ? +profile.id : null,
        name,
        location,
        startDate,
        djName,
        playlistIds: selectedPlaylists,
        eventActivitiesIds: eventActivities,
      };
      try {
        await addNewEvent({
          variables: { newEventData },
        });
        useToast(MESSAGES.dj.event.add.create, ToastType.SUCCESS);
        router(-1);
      } catch (error) {
        if ((error as any).message === 'EVENT_01') {
          useToast(ERRORS.dj.event.add.unique, ToastType.ERROR);
        } else {
          useToast(ERRORS.dj.event.add.create, ToastType.ERROR);
        }
        console.error(error);
      }
      setIsSubmitted(false);
    }
  };

  const onPlaylistSelected = (id: number) => {
    const foundIndex = selectedPlaylists.findIndex((item) => item === id) >= 0;
    if (foundIndex) {
      // if found remove
      setSelectedPlaylists(selectedPlaylists.filter((item) => item !== id));
    } else {
      // if not found push
      setSelectedPlaylists([...selectedPlaylists, id]);
    }
  };

  return (
    <VerticalLayout background="bg-frame3" canGoBack>
      <main className="flex h-full w-full max-w-[610px] flex-col">
        <section id="header" className="flex h-[10%] items-end">
          <Header
            title="Let's create a new event"
            subtitle="Add all the requested details to finish creating a event"
          />
        </section>
        <section id="form" className="h-[15%] min-w-[500px] py-1">
          <Form className="w-full">
            <div className="mb-2 flex w-full flex-wrap items-start justify-start">
              <div className="w-[50%]">
                <PrimaryInput
                  id="name"
                  type="text"
                  placeholder="Event name"
                  errorMessage="Please enter event name"
                  invalid={isSubmitted && name.length === 0}
                  onChange={(event: any) => setName(event.target.value)}
                />
              </div>
              <div className="w-[30%] px-2">
                <div className="relative flex h-[2rem] items-center rounded-lg border-2 border-solid border-gradient-br-input-border-neon gradient-border-1">
                  <DatePicker
                    className=" bg-transparent h-full w-full rounded-lg  p-1 text-white focus:outline-none"
                    selected={date}
                    onChange={(date: Date) => setDate(date)}
                    minDate={new Date()}
                  />
                </div>
              </div>
              <div className="w-[20%]">
                <PrimaryInput
                  id="hour"
                  type="time"
                  invalid={isSubmitted && hour.length === 0}
                  errorMessage="Missing Time"
                  placeholder="Start hour"
                  onChange={(event: any) => setHour(event.target.value)}
                />
              </div>
            </div>
            <div className="flex w-full items-start justify-start">
              <div className="w-[50%]">
                <PrimaryInput
                  id="location"
                  type="text"
                  placeholder="Location"
                  errorMessage="Please enter location"
                  invalid={isSubmitted && location.length === 0}
                  onChange={(event: any) => setLocation(event.target.value)}
                />
              </div>
              <div className="w-[50%] pl-2">
                <PrimaryInput
                  id="djName"
                  type="text"
                  placeholder="DJ Name"
                  errorMessage="Please enter dj name"
                  invalid={isSubmitted && djName.length === 0}
                  onChange={(event: any) => setDjName(event.target.value)}
                />
              </div>
            </div>
          </Form>
        </section>
        <section id="event-activities" className="h-fit text-white">
          <p className="pb-2 text-sm">Event activities*</p>
          <div className="flex flex-row gap-1">
            <input
              type="checkbox"
              id="juke"
              name="activities"
              value="juke"
              checked={jukeSelected}
              onChange={() => setJukeSelected(!jukeSelected)}
            />
            <label htmlFor="juke">Juke</label>
          </div>
          <div className="flex flex-row gap-1">
            <input
              type="checkbox"
              id="memories"
              name="activities"
              value="memories"
              checked={memoriesSelected}
              onChange={() => setMemoriesSelected(!memoriesSelected)}
            />
            <label htmlFor="memories">Memories Wall</label>
          </div>
          {!jukeSelected && !memoriesSelected && (
            <span className="text-sm text-neon-red">
              *You have to select at least one option
            </span>
          )}
        </section>
        <section id="playlist" className="h-[60%] pt-6">
          <p className="text-sm text-white">
            Playlist added{' '}
            <span className="text-success">{selectedPlaylists.length}</span>
          </p>
          <div className="h-full max-h-96 w-full">
            <PlaylistGroup
              playlist={playlists}
              selectable
              onSelect={onPlaylistSelected}
            />
          </div>
        </section>
        <section id="actions" className="flex h-[10%] flex-row-reverse p-1">
          <PrimaryButton
            color="bg-main"
            onClick={onSubmit}
            icon={<Plus className="text-sm text-white" />}
          >
            Create Event
          </PrimaryButton>
          <PrimaryButton color="bg-transparent" onClick={() => router(-1)}>
            Cancel
          </PrimaryButton>
        </section>
      </main>
    </VerticalLayout>
  );
};

export default EventAdd;
