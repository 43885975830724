import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import './App.css';
import MainRoutes from './routes/MainRoutes';
import TagManager from 'react-gtm-module';

const tagManagerArgs = {
  gtmId: 'GTM-PPH6QKL',
};

TagManager.initialize(tagManagerArgs);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      staleTime: 0, // DEFAULT: 0 seconds
      cacheTime: 300000, // DEFAULT: 5 minutes (300000 ms)
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      refetchOnReconnect: 'always',
      suspense: false,
    },
  },
});

const App = () => (
  <QueryClientProvider client={queryClient}>
    <MainRoutes />
  </QueryClientProvider>
);
export default App;
