import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import PrimaryButton from './primary-button';
import { X } from 'react-feather';

const ConfirmationModal = ({
  title,
  subtitle,
  toggle,
  isOpen,
  onConfirm,
}: {
  title: string;
  subtitle?: string;
  toggle: any;
  isOpen?: boolean;
  onConfirm?: any;
}) => {
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      centered
      size="lg"
      contentClassName="rounded-[1.25rem] bg-[#F3F3F3] max-w-lg"
      className="max-w-lg"
    >
      <ModalBody className="gradient-background text-white">
        <div className="flex h-[15rem] flex-col justify-around px-2 pt-2">
          <div id="header" className="flex flex-col items-center">
            <p className="text-center text-2xl font-semibold ">{title}</p>
            <p className="mt-1 w-[80%] text-center text-sm font-light ">{subtitle}</p>
          </div>
          <div className="flex items-center justify-evenly gap-2">
            <Button
              className={`flex h-[2.5rem] w-[10rem] min-w-fit items-center justify-around rounded-lg border-none bg-gray-selected p-3 hover:border-none hover:bg-light-gray-200 active:bg-light-gray-200`}
              onClick={toggle}
            >
              <span>Cancel</span>
            </Button>
            <Button
              className={`flex h-[2.5rem] w-[10rem] min-w-fit items-center justify-around rounded-lg border-none bg-main p-3 hover:border-none hover:bg-[#C450A7] active:bg-[#C450A7]`}
              onClick={onConfirm}
            >
              <span className="text-white">Confirm</span>
            </Button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ConfirmationModal;
