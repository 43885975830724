import { Button } from 'reactstrap';
import btn_facebook from '../assets/img/f_logo_RGB-White_1024.png';

const FacebookButton = ({
  onClick,
  children,
}: {
  onClick: any;
  children: any;
}) => {
  return (
    <Button
      className="flex h-[2.5rem] w-[15rem] items-center rounded-lg border-none bg-[#4267B2] hover:bg-[#5890FF]"
      onClick={onClick}
    >
      <div className="w-[10%]">
        <img src={btn_facebook} />
      </div>
      <span className="w-[90%] text-white">{children}</span>
    </Button>
  );
};

export default FacebookButton;
