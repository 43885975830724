import { useLazyQuery, useMutation, useSubscription } from '@apollo/client';
import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AuthContext } from '../common/auth/auth.context';
import {
  EVENT_TRACK_SUBSCRIPTION,
  EVENT_UPDATES_SUBSCRIPTION,
  GET_GUEST_EVENT,
} from '../common/graphql/event.graphql';
import {
  GET_SESSION_WITH_SONGS,
  SESSION_END_SUBSCRIPTION,
} from '../common/graphql/session.graphql';
import { GET_SONGS_BY_EVENT_ID } from '../common/graphql/songs.graphql';
import {
  DISCONNECT_USER,
  EVENT_USERS_DISCONNECT_SUBSCRIPTION,
  EVENT_USERS_SUBSCRIPTION,
  GET_EVENT_USERS,
} from '../common/graphql/user.graphql';
import { UNVOTE_SUBSCRIPTION, VOTE_SONG, VOTE_SUBSCTIPTION } from '../common/graphql/vote.graphql';
import SessionCountDown from '../components/countdown';
import GuestSessionWinner from '../components/guest-session-winner';
import ModuleMobileLayout from '../components/module-mobile-layout';
import SearchInput from '../components/search-input';
import { StatisticsItem } from '../components/statistics';
import votesService from '../common/services/votes.service';
import { compareAsc } from 'date-fns';
import useToast from '../common/hooks/useToast';
import { ERRORS } from '../common/constants/errors';
import { ToastType } from '../common/models/toast-type.enum';
import { AiFillStar, AiOutlineHeart, AiTwotoneHeart } from 'react-icons/ai';
import { BsMusicNoteBeamed } from 'react-icons/bs';
import { classNames } from './GuestProfile';
import Participants from './Participants';
import PastWinners from './PastWinners';
import 'primereact/resources/themes/lara-light-indigo/theme.css'; //theme
import 'primereact/resources/primereact.min.css'; //core css
import 'primeicons/primeicons.css';
import ButtonWrapper from '../components/button-wrapper';
import StickyMenu, { MENU_TYPE } from '../components/sticky-menu';
import TipDjModal from '../components/tip-dj-modal';
import RequestSongModal from '../components/request-song-modal';
import { useAddTipMutation } from '../services/tips/tips.service';
import SongRequests from './SongRequests';
import SongItem from '../components/song-item';

const OnVoteMessages = [
  'Great choice!',
  'Wow, I also like this song!',
  'This song will rock the night!',
  'F**k yeah, I feel you bro!',
  'Best choice ever!',
  'Wohooo, is this real?',
  'Is that girl watching you?',
  'Fire in the hoe (oops typo)!',
];

const OnUnvoteMessages = [
  'Bummer...',
  'What comes around, goes around...',
  'At least make sure you vote another one.',
  'Did you change your mind?',
  'Oh no, why?',
];

const VotesRemaining = ({
  songs,
  userId,
  sessionId,
  votesExcedeed,
  onVotesCountChange,
}: {
  songs: any;
  userId: number | undefined;
  sessionId: number | undefined;
  votesExcedeed: boolean | undefined;
  onVotesCountChange: (votes: number) => void;
}) => {
  const [votes, setVotes] = useState<number>(0);

  const [addTip, { error, loading }] = useAddTipMutation();

  useEffect(() => {
    const count = getVotesCount();
    // TODO: improve this logic
    setVotes(count > 3 ? 3 : count);
    onVotesCountChange(count > 3 ? 3 : count);
  }, [songs]);

  const getVotesCount = () => {
    return songs.reduce((prev: number, current: any) => {
      const found =
        current.votes.findIndex(
          (vote: any) => vote.userId === userId && vote.sessionId === sessionId,
        ) >= 0;
      if (found) {
        prev = prev + 1;
      }

      return prev;
    }, 0);
  };

  return (
    <div
      className={classNames('flex min-w-[100px] flex-col', votesExcedeed ? 'animate-shakes' : '')}
    >
      <div className="flex justify-around">
        {votes <= 2 ? (
          <AiTwotoneHeart size={26} color="#EF5DA8" />
        ) : (
          <AiOutlineHeart className="animate-[pulse_1s_ease-in-out_1]" size={26} />
        )}
        {votes <= 1 ? (
          <AiTwotoneHeart size={26} color="#EF5DA8" />
        ) : (
          <AiOutlineHeart className="animate-[pulse_1s_ease-in-out_1]" size={26} />
        )}
        {votes === 0 ? (
          <AiTwotoneHeart size={26} color="#EF5DA8" />
        ) : (
          <AiOutlineHeart className="animate-[pulse_1s_ease-in-out_1]" size={26} />
        )}
      </div>
      {/* TODO remove this hardcoded value */}
      <span className="text-[9px] font-medium">{`${3 - votes} VOTES REMAINING`}</span>
    </div>
  );
};

const JukeApp = () => {
  const { id } = useParams();
  const router = useNavigate();
  const [votesUsed, setVotesUsed] = useState<number>(0);
  const [currentTab, setCurrentTab] = useState(1); // 1 TOP, 2 Playlist
  const [event, setEvent] = useState<any | null>(null);
  const [session, setSession] = useState<any | null>(null);
  const [votes, setVotes] = useState<number>(0);
  const [numberOfParticipants, setNumberOfParticipants] = useState<number>(0);
  const [songs, setSongs] = useState<any[]>([]);
  const [topSongs, setTopSongs] = useState<any[]>([]);
  const [filteredSongs, setFilteredSongs] = useState<any[]>([]);
  const [searchWord, setSearchWord] = useState<string>('');
  const [numberOfTracks, setNumberOfTracks] = useState<number>(0);
  const [isSessionOver, setIsSessionOver] = useState<boolean>(false);
  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);

  const { profile } = useContext(AuthContext);

  const [votesExcedeed, setVotesExcedeed] = useState<boolean>(false);
  const [selectedMenu, setSelectedMenu] = useState<MENU_TYPE>('playlist');
  const [toggleTipDJModal, setToggleTipDJModal] = useState<boolean>(false);
  const [toggleRequestSongModal, setToggleRequestSongModal] = useState<boolean>(false);
  const [scrollParam, setScrollParam] = useState<number>(0);

  const prevScrollParam = useRef<number>(0);

  const [getEvent, { refetch }] = useLazyQuery(GET_GUEST_EVENT, {
    fetchPolicy: 'network-only',
  });
  const [getSession] = useLazyQuery(GET_SESSION_WITH_SONGS, {
    fetchPolicy: 'network-only',
  });
  const [getEventUsers] = useLazyQuery(GET_EVENT_USERS, {
    fetchPolicy: 'network-only',
  });
  const [getSongs] = useLazyQuery(GET_SONGS_BY_EVENT_ID, {
    fetchPolicy: 'network-only',
  });
  const [voteSong] = useMutation(VOTE_SONG);
  const [disconnect] = useMutation(DISCONNECT_USER);
  // subscriptions
  const votesSubsctiption = useSubscription(VOTE_SUBSCTIPTION);
  const unvoteSubscription = useSubscription(UNVOTE_SUBSCRIPTION);
  const userSubscription = useSubscription(EVENT_USERS_SUBSCRIPTION);
  const userDisconnectSubscription = useSubscription(EVENT_USERS_DISCONNECT_SUBSCRIPTION);
  const tracksSubscription = useSubscription(EVENT_TRACK_SUBSCRIPTION);
  const sessionEndSubscription = useSubscription(SESSION_END_SUBSCRIPTION);
  const eventUpdatesSubscription = useSubscription(EVENT_UPDATES_SUBSCRIPTION);

  useEffect(() => {
    localStorage.removeItem('partyCode');
    localStorage.setItem('route', 'juke');
    initEvent();
    document.addEventListener('visibilitychange', handleVisibilityChange, false);
    // destroy
    () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  // startSimulation and pauseSimulation defined elsewhere
  const handleVisibilityChange = () => {
    if (document.visibilityState !== 'hidden') {
      initEvent();
    }
  };

  useEffect(() => {
    if (votesSubsctiption.data) {
      const { voteAdded } = votesSubsctiption.data;
      onNewVote(voteAdded);
      updateSessionVotes();
      // setVotes(votes + 1);
    }
  }, [votesSubsctiption.data]);

  useEffect(() => {
    if (unvoteSubscription.data) {
      const { voteRemoved } = unvoteSubscription.data;
      onRemoveVote(voteRemoved);
      updateSessionVotes();
      // setVotes(votes - 1);
    }
  }, [unvoteSubscription.data]);

  useEffect(() => {
    if (userSubscription.data) {
      setNumberOfParticipants(numberOfParticipants + 1);
    }
  }, [userSubscription.data]);

  useEffect(() => {
    if (userDisconnectSubscription.data) {
      setNumberOfParticipants(numberOfParticipants - 1);
    }
  }, [userDisconnectSubscription.data]);

  useEffect(() => {
    if (tracksSubscription.data) {
      // set number of tracks
      const tracksLength = getTracksLength(tracksSubscription.data.playlistUpdates);
      setNumberOfTracks(tracksLength);

      initSongs();
    }
  }, [tracksSubscription.data]);

  useEffect(() => {
    if (sessionEndSubscription.data) {
      // get voted songs
      initEvent();
    }
  }, [sessionEndSubscription.data]);

  useEffect(() => {
    // event is over
    if (eventUpdatesSubscription.data?.eventUpdates.endDate) {
      router(`/guest/finished/${id}`, { replace: true });
    }

    // if (eventUpdatesSubscription.data?.eventUpdates.id !== id) {
    //   useToast(
    //     'The event has ended. Thank you for participating',
    //     ToastType.SUCCESS,
    //   );
    //   (async () => await authService.signOut())();
    // }
  }, [eventUpdatesSubscription.data]);

  useEffect(() => {
    if (event) {
      initSession();
    }

    if (event?.endDate) {
      router(`/guest/finished/${id}`, { replace: true });
    }
  }, [event]);

  useEffect(() => {
    setFilteredSongs(filterSongs(songs));
  }, [searchWord]);

  useEffect(() => {
    if (session) {
      setIsSessionOver(compareAsc(new Date(session.endDate), new Date()) <= 0);
    }
  }, [session]);

  useEffect(() => {
    setTimeout(() => {
      setVotesExcedeed(false);
    }, 1000);
  }, [votesExcedeed]);

  useEffect(() => {
    // if session is over close all modals present to the screen
    if (isSessionOver) {
      setSelectedMenu('playlist');
    }
  }, [isSessionOver]);

  useEffect(() => {
    const menu = document.getElementById('sticky-menu-hide');
    if (menu) {
      menu.classList.remove('active');
    }
  }, [selectedMenu, currentTab]);

  useEffect(() => {
    const menu = document.getElementById('sticky-menu-hide');
    if (menu) {
      if (scrollParam <= 0) {
        menu.classList.remove('active');
      } else if (prevScrollParam.current < (scrollParam || 0)) {
        //scroll down
        menu.classList.add('active');
      } else if (prevScrollParam.current > (scrollParam || 0)) {
        //scroll up
        menu.classList.remove('active');
      }
    }
    prevScrollParam.current = scrollParam || 0;
  }, [scrollParam]);

  const initEvent = async () => {
    try {
      console.log('init event');
      const eventResponse = await getEvent({
        variables: { eventId: +(id as string) },
      });
      if (!eventResponse.data.event.active) {
        if (eventResponse.data.event.endDate !== null) {
          router(`/guest/finished/${id}`, { replace: true });
        } else {
          router('/guest', { replace: true });
        }
      } else {
        setEvent(eventResponse.data.event);
        if (
          !eventResponse.data?.event?.activities?.find((activity: any) => activity.type === 'juke')
        ) {
          router(`/dj/event/${eventResponse.data.event.id}`);
        }
        // set number of tracks
        const tracksLength = getTracksLength(eventResponse.data.event);
        setNumberOfTracks(tracksLength);
      }
    } catch (error) {
      useToast(ERRORS.guest.event.getEvent, ToastType.ERROR);
      console.error(error);
    }

    try {
      const eventUsersResponse = await getEventUsers({
        variables: { eventId: +(id as string) },
      });
      setNumberOfParticipants(eventUsersResponse.data.eventUsers.length);
    } catch (error) {
      useToast(ERRORS.guest.event.getParticipants, ToastType.ERROR);
      console.error(error);
    }

    initSongs();
  };

  const initSongs = async () => {
    try {
      const songsResponse = await getSongs({
        variables: { eventId: +(id as string) },
      });
      setSongs(songsResponse.data.songs);
      setFilteredSongs(filterSongs(songsResponse.data.songs));
    } catch (error) {
      useToast(ERRORS.guest.event.getSongs, ToastType.ERROR);
      console.error(error);
    } finally {
      setIsRefreshing(false);
    }
  };

  const initSession = async () => {
    if (event.currentSessionId) {
      try {
        const { data } = await getSession({
          variables: { sessionId: event.currentSessionId },
        });

        // get voted songs
        const structuredSongs = votesService.parseSongData(data.session.songVotes);
        setTopSongs(structuredSongs);

        setSession(data.session);
        setVotes(data.session.songVotes.length);
      } catch (error) {
        useToast(ERRORS.guest.event.getSession, ToastType.ERROR);
        console.error(error);
      }
    }
  };

  const updateSessionVotes = async () => {
    if (event.currentSessionId) {
      try {
        const { data } = await getSession({
          variables: { sessionId: event.currentSessionId },
        });
        setVotes(data.session.songVotes.length);
      } catch (error) {
        useToast(ERRORS.guest.event.getVotes, ToastType.ERROR);
        console.error(error);
      }
    }
  };

  const onSessionComplete = () => {
    setIsSessionOver(true);
  };

  const getSessionEndDate = () => {
    return session?.endDate ? new Date(session.endDate) : new Date();
  };

  const isSongVoted = (song: any) => {
    return (
      song.votes.findIndex(
        (vote: any) => vote.userId === profile?.id && vote.sessionId === session?.id,
      ) >= 0
    );
  };

  const showVoteMessage = () => {
    // console.log('test message');
    const message = OnVoteMessages[Math.round(Math.random() * (OnVoteMessages.length - 1))];
    useToast(message, ToastType.SUCCESS);
  };

  const showUnvoteMessage = () => {
    const message = OnUnvoteMessages[Math.round(Math.random() * (OnUnvoteMessages.length - 1))];
    useToast(message, ToastType.SUCCESS);
  };

  const onVote = async (songId: number) => {
    try {
      const foundSong = songs.find((song) => song.id === songId);
      // let isVote = true;
      if (foundSong) {
        const foundVote = foundSong.votes.findIndex(
          (vote: any) => vote.userId === profile?.id && vote.sessionId === session?.id,
        );
        if (foundVote >= 0) {
          // removeSong
          onRemoveVote({
            song: { id: songId },
            user: { id: profile?.id },
            session: { id: session?.id },
          });
          // isVote = false;
        } else {
          if (votesUsed === 3) {
            setVotesExcedeed(true);
            return;
          }
          // add song
          onNewVote({
            song: { id: songId },
            user: { id: profile?.id },
            session: { id: session?.id },
          });
        }
      } else {
        if (votesUsed === 3) {
          setVotesExcedeed(true);
          return;
        }
        onNewVote({
          song: { id: songId },
          user: { id: profile?.id },
          session: { id: session?.id },
        });
      }

      await voteSong({
        variables: {
          voteInput: {
            sessionId: session.id,
            songId,
            userId: profile?.id,
          },
        },
      });
    } catch (error: any) {
      if (error.message === 'VOTE_04') {
        useToast(ERRORS.guest.event.voteLimit, ToastType.ERROR);
        onRemoveVote({
          song: { id: songId },
          user: { id: profile?.id },
          session: {
            id: session?.id,
          },
        });
      } else {
        useToast(ERRORS.guest.event.vote, ToastType.ERROR);
      }

      try {
        window.navigator.vibrate(200);
      } catch (error) {
        console.error(error);
      }

      console.error(error);
    }
  };

  const filterSongs = (values: any[]) => {
    return values.filter(
      (song) =>
        (song.artist as string).toLocaleLowerCase().includes(searchWord.toLocaleLowerCase()) ||
        (song.title as string).toLocaleLowerCase().includes(searchWord.toLocaleLowerCase()),
    );
  };

  const onNewVote = (vote: any) => {
    // update all songs
    const copyOfSongs = JSON.parse(JSON.stringify(songs));
    const copyOfTopSongs = JSON.parse(JSON.stringify(topSongs));
    const foundSong = [...copyOfSongs, ...copyOfTopSongs].find(
      (song: any) => song.id === vote.song.id,
    );

    if (!foundSong) {
      useToast('The song you have voted is not in the playlist!', ToastType.ERROR);
      return;
    }

    const foundVote = foundSong.votes.findIndex(
      (value: any) => value.userId === vote.user.id && value.sessionId === vote.session.id,
    );

    // vote aleady added
    if (foundVote >= 0) {
      return;
    }

    foundSong?.votes.push({
      userId: vote.user.id,
      songId: vote.song.id,
      sessionId: vote.session.id,
    });

    setSongs(copyOfSongs);
    setFilteredSongs(filterSongs(copyOfSongs));

    // update top songs
    let updatedSongs = votesService.upsertSong(topSongs, {
      ...vote,
      song: {
        title: foundSong.title,
        artist: foundSong.artist,
        id: foundSong.id,
      },
    });
    setTopSongs(updatedSongs);
  };

  const onRemoveVote = (vote: any) => {
    // update all songs
    const copyOfSongs = JSON.parse(JSON.stringify(songs));
    const foundSong = copyOfSongs.find((song: any) => song.id === vote.song.id);
    if (foundSong) {
      // remove song
      foundSong.votes = foundSong?.votes.filter(
        (v: any) => !(v.userId === vote.user.id && v.sessionId === vote.session.id),
      );
      setSongs(copyOfSongs);
      setFilteredSongs(filterSongs(copyOfSongs));
    }

    // update top songs
    let updatedSongs = votesService.removeSongVote(topSongs, vote);
    setTopSongs(updatedSongs);
  };

  const getTracksLength = (event: any) => {
    return event.playlist.reduce((prev: any, curr: any) => {
      prev += curr.songs.length;
      return prev;
    }, 0);
  };

  const onPlaylistTabChange = (tab: number) => {
    setCurrentTab(tab);

    if (tab === 1) {
      initSession();
    } else {
      initSongs();
    }
  };

  // temporary fix
  const calculateWinner = () => {
    const winners = topSongs.filter((song) => song.votes.length === topSongs[0].votes.length);

    return winners.sort((songA, songB) => songA.id - songB.id)[0];
  };

  const tipTheDj = () => {
    setToggleTipDJModal(true);
  };

  const requestSong = () => {
    setToggleRequestSongModal(true);
  };

  return (
    <ModuleMobileLayout
      className="flex !h-[100svh] min-h-fit flex-col !bg-framebound"
      canGoBack={event?.activities.length === 1 ? false : true}
      onRefresh={() => {
        setIsRefreshing(true);
        initEvent();
      }}
      isRefreshing={isRefreshing}
    >
      <main className="relative flex h-full w-full flex-col overflow-hidden text-white">
        <section id="header" className="flex h-fit shrink-0 items-end justify-between px-8 pt-16">
          <div className="flex w-full flex-col gap-2">
            <div className="w-full">
              <h1 className="text-2xl text-white">{event?.name}</h1>
              <p className="text-xs font-semibold text-white">{event?.djName}</p>
            </div>
            <div className="flex w-full flex-row items-center justify-between">
              <ButtonWrapper onClick={tipTheDj} id="tipDjButton">
                <p className="pointer-events-none text-sm font-semibold">Tip the DJ</p>
              </ButtonWrapper>
              <VotesRemaining
                songs={songs}
                userId={profile?.id}
                sessionId={session?.id}
                onVotesCountChange={setVotesUsed}
                votesExcedeed={votesExcedeed}
              />
            </div>
          </div>
        </section>
        <section
          id="statistics"
          className="mt-2 flex grow-0 justify-center divide-x divide-neon-red text-2xl font-semibold"
        >
          <StatisticsItem title={votes} description="Votes this session"></StatisticsItem>

          <StatisticsItem
            title={numberOfParticipants}
            description="Participants"
            onClick={setSelectedMenu.bind(null, 'participants')}
          ></StatisticsItem>
          <StatisticsItem
            title={
              <SessionCountDown endDate={getSessionEndDate()} onComplete={onSessionComplete} />
            }
            description="TIME REMAINING"
          />
        </section>
        {selectedMenu === 'participants' && event && (
          <Participants
            eventId={event.id}
            onScroll={(scrollValue: number) => {
              setScrollParam(scrollValue);
            }}
          />
        )}

        {selectedMenu === 'previous_sessions' && event && (
          <PastWinners
            eventId={event.id}
            onScroll={(scrollValue: number) => {
              setScrollParam(scrollValue);
            }}
          />
        )}

        {selectedMenu === 'playlist' && (
          <>
            {/* BETWEEN SESSIONS */}
            {isSessionOver && (
              <GuestSessionWinner
                winner={calculateWinner()}
                onClick={() => setToggleRequestSongModal(true)}
              />
            )}

            {!isSessionOver && (
              <section
                id="songs"
                className="gradient-background mt-4 flex min-h-0 w-full grow flex-col rounded-t-[1.25rem]"
              >
                <div id="tabs" className="flex h-[2.6rem] w-full shrink-0 justify-around">
                  <span
                    className={`relative flex w-1/2 items-center justify-center ${
                      currentTab == 1
                        ? 'selectedTab font-bold text-neon-pink'
                        : 'rounded-b-[1.25rem] bg-black'
                    }`}
                    onClick={() => onPlaylistTabChange(1)}
                  >
                    Top Songs <AiFillStar className="ml-1 text-lg" />
                  </span>
                  <span
                    className={`relative flex w-1/2 items-center justify-center ${
                      currentTab == 2
                        ? 'selectedTab font-bold text-neon-pink'
                        : 'rounded-b-[1.25rem] bg-black'
                    }`}
                    onClick={() => onPlaylistTabChange(2)}
                  >
                    Playlist <BsMusicNoteBeamed className="ml-1 text-lg" />
                  </span>
                </div>
                {currentTab === 1 && (
                  <div
                    id="playlists"
                    className="mt-[1rem] flex grow flex-col overflow-auto px-6"
                    onScroll={(event) => {
                      const scrollItem = document.querySelector('#playlists');
                      setScrollParam(scrollItem?.scrollTop || 0);
                    }}
                  >
                    {topSongs.length > 0 && (
                      <div className="search-section-hide flex w-full flex-row items-center justify-between gap-2 pb-2">
                        <p>Did not find your song?</p>
                        <ButtonWrapper onClick={requestSong} id="requestButtonTop">
                          <p className="pointer-events-none text-sm font-semibold">Request</p>
                        </ButtonWrapper>
                      </div>
                    )}
                    {topSongs.length ? (
                      topSongs.map((song, index) => (
                        <SongItem
                          key={song.id}
                          song={{
                            id: song.id,
                            title: song.title,
                            artist: song.artist,
                            votes: song.votes,
                          }}
                          voted={() => isSongVoted(song)}
                          index={index}
                          onVote={onVote}
                        />
                      ))
                    ) : (
                      <div className="flex flex-col items-center pt-8 text-center">
                        <div className="text-lg font-bold">All eyes are on you!</div>
                        Be the first that makes a move and pick a song you love from the DJ’s
                        playlist.
                        <span
                          id="next"
                          className="mt-2 flex h-10 w-36 items-center justify-center rounded-[0.6rem] bg-neon-purple text-white ring-2 ring-inset ring-neon-pink"
                          onClick={() => setCurrentTab(2)}
                        >
                          View Playlist <BsMusicNoteBeamed className="ml-1 mt-[2px]" />
                        </span>
                        <div className="mt-10 flex flex-col items-center gap-3">
                          <div className="text-lg font-bold">
                            Did not find what you were looking for?
                          </div>
                          <ButtonWrapper
                            onClick={() => setToggleRequestSongModal(true)}
                            id="requestButtonNewSession"
                            classNames="!p-5"
                          >
                            <p className="text-md pointer-events-none font-semibold">
                              Request a Song
                            </p>
                          </ButtonWrapper>
                        </div>
                      </div>
                    )}
                  </div>
                )}

                {currentTab === 2 && (
                  <div className="mt-3 flex flex-col gap-3 overflow-auto px-6">
                    <div id="search-bar" className="search-section-hide">
                      <SearchInput
                        value={searchWord}
                        onChange={(value: string) => setSearchWord(value)}
                        onClearText={() => setSearchWord('')}
                      />
                    </div>
                    <div className="search-section-hide flex w-full flex-row items-center justify-between gap-2">
                      <p>Did not find your song?</p>
                      <ButtonWrapper onClick={requestSong} id="requestButtonPlaylist">
                        <p className="pointer-events-none text-sm font-semibold">Request</p>
                      </ButtonWrapper>
                    </div>
                    <div
                      id="playlists"
                      className="flex grow flex-col overflow-auto"
                      onScroll={(event) => {
                        const scrollItem = document.querySelector('#playlists');
                        setScrollParam(scrollItem?.scrollTop || 0);
                      }}
                    >
                      {songs.length === 0 ? (
                        <div className="pt-8 text-center">
                          <div className="text-lg font-bold">
                            The DJ is currently preparing the playlists.
                          </div>
                          Soon you’ll be ready to vote and rock!
                        </div>
                      ) : filteredSongs.length ? (
                        filteredSongs.map((song, index) => (
                          <SongItem
                            key={song.id}
                            song={{
                              id: song.id,
                              title: song.title,
                              artist: song.artist,
                              // votes: song.votes,
                            }}
                            voted={() => isSongVoted(song)}
                            index={index}
                            onVote={onVote}
                            onUnvote={() => {}}
                          />
                        ))
                      ) : (
                        <div className="pt-8 text-center">
                          <div className="text-lg font-bold">Uh oh!</div>
                          We didn't find a song matching your search criteria.
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </section>
            )}
          </>
        )}

        {selectedMenu === 'song_requests' && event && (
          <SongRequests
            eventId={event.id}
            onScroll={(scrollValue: number) => {
              setScrollParam(scrollValue);
            }}
            onRequestSongClick={() => setToggleRequestSongModal(true)}
          />
        )}

        <TipDjModal
          open={toggleTipDJModal}
          onClose={setToggleTipDJModal.bind(null, false)}
          eventId={event?.id}
        />
        <RequestSongModal
          open={toggleRequestSongModal}
          onClose={setToggleRequestSongModal.bind(null, false)}
          eventId={event?.id}
        />
        <StickyMenu
          selected={selectedMenu}
          onPlaylistClick={() => {
            setSelectedMenu('playlist');
          }}
          onParticipantsClick={() => setSelectedMenu('participants')}
          onWinnersClick={() => setSelectedMenu('previous_sessions')}
          onSongRequestClick={() => {
            setSelectedMenu('song_requests');
          }}
        ></StickyMenu>
      </main>
    </ModuleMobileLayout>
  );
};

export default JukeApp;
