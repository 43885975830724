import React, { ReactNode } from 'react';
const ButtonWrapper = ({
  children,
  classNames,
  onClick,
  id,
}: {
  children: ReactNode;
  classNames?: string;
  onClick?: (event: any) => any;
  id?: string;
}) => {
  return (
    <div
      id={id}
      onClick={onClick}
      className={`${classNames} flex h-[30px] w-[30px] min-w-fit items-center justify-center rounded-full border-2 border-solid p-3 border-gradient-br-input-border-neon gradient-border-1`}
    >
      {children}
    </div>
  );
};

export default ButtonWrapper;
