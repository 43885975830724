import { gql } from '@apollo/client';

export const GET_SONGS_BY_EVENT_ID = gql`
  query ($eventId: Int!) {
    songs(eventId: $eventId) {
      id
      title
      artist
      votes {
        id
        songId
        userId
        sessionId
      }
    }
  }
`;
