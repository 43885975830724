import HorizontalLayout from '../components/horizontal-layout';
import Header from '../components/header';
import { useNavigate } from 'react-router-dom';
import Astronaut2 from '../assets/img/cosmonaut2.png';
import Astronaut1 from '../assets/img/cosmonaut1.png';

const Menu = () => {
  const navigate = useNavigate();

  return (
    <HorizontalLayout background="bg-frame5">
      <main className="flex h-full w-full flex-col">
        <section id="header" className="flex h-2/6 items-center justify-center">
          <Header
            title="Welcome to Juke DJ Young"
            subtitle="Lets create memorable events close to your fans"
          />
        </section>
        <ul
          id="menu"
          className="flex h-4/6 flex-wrap items-start justify-around md:px-16 lg:px-32"
        >
          <li className="relative h-[150px] w-[400px] min-w-[400px] cursor-pointer rounded-lg border-2 border-solid border-gradient-tr-red-neon-astro-1 gradient-border-2">
            <div
              className="bg h-full w-full rounded-lg bg-gradient-to-r from-[#904E95] to-[#E96443] "
              onClick={() => navigate('/dj/playlists')}
            >
              <div className="flex h-full w-2/3 flex-col justify-center pl-8 text-white">
                <h3 className="text-2xl">Playlists</h3>
                <p className="text-xs font-thin">
                  {'Create & edit your playlists ->'}
                </p>
              </div>
            </div>
            <div className="right absolute top-[-50px] right-[20px]">
              <img src={Astronaut1} alt="me" width="150" height="150" />
            </div>
          </li>
          <li className="relative h-[150px] w-[400px] min-w-[400px] cursor-pointer rounded-lg border-2 border-solid border-gradient-tr-red-blue-astro-1 gradient-border-2">
            <div
              className="bg h-full w-full rounded-lg bg-gradient-to-r from-[#2196F3] to-[#E13E32] "
              onClick={() => navigate('/dj/events')}
            >
              <div className="flex h-full w-2/3 flex-col justify-center pl-8 text-white">
                <h3 className="text-2xl">Events</h3>
                <p className="text-xs font-thin">
                  {'Create events & edit them ->'}
                </p>
              </div>
            </div>
            <div className="right absolute top-[-50px] right-[20px]">
              <img src={Astronaut2} alt="me" width="150" height="150" />
            </div>
          </li>
        </ul>
      </main>
    </HorizontalLayout>
  );
};

export default Menu;
