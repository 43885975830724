import { gql } from '@apollo/client';

export const LOGIN = gql`
  mutation ($name: String!) {
    login(name: $name) {
      id
      role
      profile {
        id
        username
      }
    }
  }
`;
