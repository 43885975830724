import { gql } from '@apollo/client';

export const GET_TOP_SPENDERS_BY_EVENT_ID = gql`
  query ($eventId: Int!) {
    topSpenders(eventId: $eventId) {
      id
      total
      username
    }
  }
`;
