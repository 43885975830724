import React from 'react';
import WithConfirm from './with-confirmation';

const GuestMenu = ({
  listItems,
  toggleMenu,
}: {
  listItems: {
    label: string;
    icon: any;
    onClick: () => void;
    withConfirm?: { confirmText: string; subtitle: string };
  }[];
  toggleMenu: () => void;
}) => {
  return (
    <div className="absolute right-0 h-fit w-fit divide-y divide-neon-purple rounded-lg bg-neon-pink px-2 py-1 text-white">
      {listItems.map((listItem, index) => (
        <div key={index} className="flex w-full flex-row items-center px-1 py-2">
          {listItem.withConfirm && (
            <WithConfirm
              onConfirm={listItem.onClick}
              confirmText="Logging out..."
              subtitle="We will wait you to come back later!"
            >
              <p className="flex items-center gap-2 text-sm font-semibold">
                {listItem.icon} {listItem.label}
              </p>
            </WithConfirm>
          )}
          {!listItem.withConfirm && (
            <p
              className="flex items-center gap-2 text-sm font-semibold"
              onClick={() => {
                toggleMenu();
                listItem.onClick();
              }}
            >
              {listItem.icon} {listItem.label}
            </p>
          )}
        </div>
      ))}
    </div>
  );
};

export default GuestMenu;
