import { useLazyQuery, useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { Check, Play, X } from 'react-feather';
import useToast from '../common/hooks/useToast';
import { ERRORS } from '../common/constants/errors';
import { ToastType } from '../common/models/toast-type.enum';
import {
  REJECT_SONG_REQUEST_MUTATION,
  GET_SONG_REQUESTS_BY_EVENT_ID,
  APPROVE_SONG_REQUEST_MUTATION,
  PLAY_SONG_REQUEST_MUTATION,
} from '../services/song-requests/song-requests.service';
import ConfirmationModal from './confirmation-modal';
import { useNewRequestSubscription } from '../services/song-requests/song-requests.service';

const RequestItem = ({
  songRequest,
  index,
  onApproveClick,
  onDeclineClick,
  onPlayClick,
}: {
  songRequest: any;
  index: number;
  onApproveClick: () => void;
  onDeclineClick: () => void;
  onPlayClick: () => void;
}) => {
  return (
    <div className="flex w-full items-center p-2">
      <div id="index" className="flex w-[5%]">
        <div className="flex h-[30px] w-[30px] items-center justify-center text-sm">{index}</div>
      </div>
      <div id="song" className="flex w-[80%] flex-col items-start justify-start px-4">
        <span className="text-xs font-bold">{songRequest.title}</span>
        <span className="text-xxs font-thin">{songRequest.artist}</span>
      </div>
      <div className="flex w-[15%] min-w-fit flex-col">
        <div id="actions" className="flex w-[15%] min-w-fit flex-row gap-1">
          {songRequest?.status === 'PENDING' && (
            <div className="flex items-center gap-2">
              <Check
                size={20}
                color="#3DE161"
                onClick={onApproveClick?.bind(null, songRequest.id)}
                className="cursor-pointer"
              />
              <X
                size={20}
                color="#E11159"
                onClick={onDeclineClick?.bind(null, songRequest.id)}
                className="cursor-pointer"
              />
              <p className="mt-1 w-fit self-end rounded-lg bg-neon-pink p-1 text-xs font-semibold">
                {songRequest.amount / 100} lei
              </p>
            </div>
          )}
          {songRequest?.status === 'APPROVED' && (
            <div className="flex items-center gap-1">
              <Play
                size={20}
                className="cursor-pointer"
                color="#F564D1"
                onClick={onPlayClick.bind(null, songRequest.id)}
              />
              <p className="w-fit self-end rounded-lg bg-neon-pink p-1 text-xs font-semibold">
                {songRequest.amount / 100} lei
              </p>
            </div>
          )}
          {songRequest?.status === 'REJECTED' && (
            <div className="flex items-center gap-2">
              <p className="text-xs font-semibold text-[#f74d4d]">Rejected</p>
              <p className="w-fit self-end rounded-lg bg-[#E11159] p-1 text-xs font-semibold">
                {songRequest.amount / 100} lei
              </p>
            </div>
          )}
          {songRequest?.status === 'PLAYED' && (
            <div className="flex items-center gap-2">
              <p className="text-xs font-semibold text-[#3DE161]">Played</p>
              <p className="w-fit self-end rounded-lg bg-[#28a944] p-1 text-xs font-semibold">
                {songRequest.amount / 100} lei
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const SongRequests = ({ eventId }: { eventId: number }) => {
  const [songRequests, setSongRequests] = useState<any[]>([]);
  const [showApproveSongRequestModal, setShowApproveSongRequestModal] = useState<boolean>(false);
  const [showDeclineSongRequestModal, setShowDeclineSongRequestModal] = useState<boolean>(false);

  const [getSongRequestsByEventId] = useLazyQuery(GET_SONG_REQUESTS_BY_EVENT_ID, {
    fetchPolicy: 'network-only',
  });
  const [songRequestId, setSongRequestId] = useState<number>();
  const [approveSongRequest] = useMutation(APPROVE_SONG_REQUEST_MUTATION);
  const [rejectSongSuggestion] = useMutation(REJECT_SONG_REQUEST_MUTATION);
  const [playSongSuggestion] = useMutation(PLAY_SONG_REQUEST_MUTATION);

  const { data: suggestion } = useNewRequestSubscription(eventId);

  useEffect(() => {
    if (eventId) {
      initSongRequests();
    }
  }, [eventId]);

  useEffect(() => {
    if (suggestion?.newSuggestion) {
      setSongRequests([
        {
          ...suggestion.newSuggestion,
          id: suggestion.newSuggestion.suggestionId,
          status: 'PENDING',
        },
        ...songRequests,
      ]);
    }
  }, [suggestion]);

  const initSongRequests = async () => {
    try {
      const { data } = await getSongRequestsByEventId({
        variables: { eventId },
      });
      sortAndInitSongRequests(data.suggestions);
    } catch (error) {
      useToast(ERRORS.dj.event.view.getSongRequests, ToastType.ERROR);
      console.error(error);
    }
  };

  const onApproveRequestClick = async (requestId: number | undefined) => {
    try {
      if (requestId) {
        const { data } = await approveSongRequest({
          variables: { suggestionId: requestId },
        });
        const {
          data: { suggestions },
        } = await getSongRequestsByEventId({
          variables: { eventId },
        });
        sortAndInitSongRequests(suggestions);
      } else {
        useToast(ERRORS.dj.event.view.playSongRequest, ToastType.ERROR);
      }
    } catch (error) {
      useToast(ERRORS.dj.event.view.playSongRequest, ToastType.ERROR);
      console.error(error);
    }
    setShowApproveSongRequestModal(false);
  };

  const onRemoveRequestClick = async (requestId: number | undefined) => {
    try {
      if (requestId) {
        const { data } = await rejectSongSuggestion({
          variables: { suggestionId: requestId },
        });
        const {
          data: { suggestions },
        } = await getSongRequestsByEventId({
          variables: { eventId },
        });
        sortAndInitSongRequests(suggestions);
      } else {
        useToast(ERRORS.dj.event.view.rejectSongRequest, ToastType.ERROR);
      }
    } catch (error) {
      useToast(ERRORS.dj.event.view.rejectSongRequest, ToastType.ERROR);
      console.error(error);
    }
    setShowDeclineSongRequestModal(false);
  };

  const onPlayRequestClick = async (requestId: number | undefined) => {
    try {
      if (requestId) {
        const { data } = await playSongSuggestion({
          variables: { suggestionId: requestId },
        });
        const {
          data: { suggestions },
        } = await getSongRequestsByEventId({
          variables: { eventId },
        });
        sortAndInitSongRequests(suggestions);
      } else {
        useToast(ERRORS.dj.event.view.playSongRequest, ToastType.ERROR);
      }
    } catch (error) {
      useToast(ERRORS.dj.event.view.playSongRequest, ToastType.ERROR);
      console.error(error);
    }
  };

  const sortAndInitSongRequests = (incommingSongRequests: any[]) => {
    const sortedSongRequests = [...incommingSongRequests]
      .map((songRequest) => ({
        ...songRequest,
        username: songRequest.payment.user.profile.username,
        amount: songRequest.payment.amount,
      }))
      .sort((a, b) => b.id - a.id);
    setSongRequests(sortedSongRequests);
  };

  return (
    <div className="gradient-background flex h-full flex-1 flex-col rounded-lg p-2 text-white">
      <h1 className="text-md border-b-2 border-neon-red p-2" id="header">
        SONG REQUESTS
      </h1>
      {songRequests && songRequests.length > 0 && (
        <div id="list" className="flex flex-col overflow-y-auto">
          {songRequests.map((songRequest, index) => (
            <RequestItem
              key={songRequest.id}
              index={songRequests.length - index}
              songRequest={songRequest}
              onApproveClick={() => {
                setShowApproveSongRequestModal(true);
                setSongRequestId(songRequest.id);
              }}
              onDeclineClick={() => {
                setShowDeclineSongRequestModal(true);
                setSongRequestId(songRequest.id);
              }}
              onPlayClick={() => {
                onPlayRequestClick(songRequest.id);
              }}
            />
          ))}
        </div>
      )}
      {songRequests.length === 0 && (
        <div id="empty" className="flex items-center justify-center p-4">
          <p className="text-sm font-thin">No songs yet.</p>
        </div>
      )}
      <ConfirmationModal
        isOpen={showApproveSongRequestModal}
        title="Approve song request"
        subtitle="Are you sure you want to approve the song request?"
        onConfirm={() => onApproveRequestClick(songRequestId)}
        toggle={setShowApproveSongRequestModal.bind(null, false)}
      />
      <ConfirmationModal
        isOpen={showDeclineSongRequestModal}
        title="Decline song request"
        subtitle="Are you sure you want to decline the song request?"
        onConfirm={() => onRemoveRequestClick(songRequestId)}
        toggle={setShowDeclineSongRequestModal.bind(null, false)}
      />
    </div>
  );
};

export default SongRequests;
