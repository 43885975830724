import { useNavigate } from 'react-router-dom';
import { ArrowRight } from 'react-feather';

const ForwardArrow = ({ color }: { color: string }) => {
  return (
    <div className="flex w-16 items-center justify-end">
      <div className={`rounded-full ${color} p-1`}>
        <ArrowRight className="flex-1 text-white" />
      </div>
    </div>
  );
};

function EventCard({ event }: { event: any }) {
  const router = useNavigate();

  const onEventClick = () => {
    router(`/dj/event/${event.id}`);
  };

  return (
    <li
      key={event.id}
      className={`card mb-4 h-full w-full cursor-pointer bg-gradient-to-r ${
        !event.active
          ? 'from-[#EF008E] to-[#0C82F2]'
          : 'from-[#1B9C7D] to-[#85A015]'
      } p-[3px]`}
      onClick={onEventClick}
    >
      <div
        className={`card flex h-full min-h-[160px] w-full flex-row bg-gradient-to-r ${
          !event.active
            ? 'from-[#9B0043] via-[#000000] to-[#9B0043]'
            : 'from-[#62A038] via-[#000000] to-[#62A038]'
        }`}
      >
        <div className="h-full w-[80%] p-4">
          <h6 className="text-xl font-bold leading-[2.2rem] text-white">
            {event.name}
          </h6>
          <p className="text-md font-light leading-[2.2rem] text-white opacity-80">
            {new Date(event.startDate).toLocaleDateString()} | {event.location}
          </p>
          <p className="text-xs font-light leading-[2.2rem] text-white opacity-80">
            Party Code: <b className="font-bold">{event.code.code}</b>
          </p>
        </div>
        <div className="flex h-full w-[20%] items-center justify-center pt-[4rem]">
          <ForwardArrow color={`${event.active ? 'bg-success' : 'bg-main'}`} />
        </div>
      </div>
    </li>
  );
}

export default EventCard;
