import { useLazyQuery, useMutation, useSubscription } from '@apollo/client';
import { useEffect, useState } from 'react';
import { GET_PAST_SESSIONS } from '../common/graphql/event.graphql';
import { PLAY_MUTATION, SESSION_END_SUBSCRIPTION } from '../common/graphql/session.graphql';
import votesService from '../common/services/votes.service';
import sessionService from '../common/services/session.service';
import useToast from '../common/hooks/useToast';
import { ERRORS } from '../common/constants/errors';
import { ToastType } from '../common/models/toast-type.enum';
import ButtonWrapper from '../components/button-wrapper';
import { Spinner } from 'reactstrap';

const SessionItem = ({ session, index, onPlayClick }: any) => {
  return (
    <div className="winner-item flex w-full items-center justify-between gap-4 py-2">
      <div id="index" className="flex w-[10%]">
        <ButtonWrapper classNames="!p-0">
          <p className="text-xs">S{index}</p>
        </ButtonWrapper>
      </div>
      <div id="song" className="flex w-full flex-col items-start justify-start text-4xl">
        <span className="text-xs font-bold">{session.songVotes[0].title}</span>
        <span className="text-xs font-thin">{session.songVotes[0].artist}</span>
      </div>
      <div id="votes" className="flex flex-row gap-1 text-sm font-bold">
        <span className={``}>{session.songVotes[0].votes.length}</span>
        votes
      </div>
    </div>
  );
};

const PastWinners = ({
  eventId,
  onScroll,
}: {
  eventId: number;
  onScroll: (scrollValue: number) => void;
}) => {
  const [sessions, setSessions] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [getPastSessions] = useLazyQuery(GET_PAST_SESSIONS, {
    fetchPolicy: 'network-only',
  });
  const [playSong] = useMutation(PLAY_MUTATION);
  const { data } = useSubscription(SESSION_END_SUBSCRIPTION);

  useEffect(() => {
    if (data) {
      // new session final
      const topSong = votesService.parseSongData(data.sessionEnded.songVotes)[0];

      if (topSong) {
        setSessions([{ ...data.sessionEnded, songVotes: [topSong] }, ...sessions]);
      }
    }
  }, [data]);

  useEffect(() => {
    if (eventId) {
      // get past sessions
      initSessions();
    }
  }, [eventId]);

  const initSessions = async () => {
    try {
      const { data } = await getPastSessions({
        variables: {
          eventId,
        },
      });
      const updatedSessions = sessionService.parseSessionData(data.event.sessions);
      setSessions(updatedSessions);
    } catch (error) {
      useToast(ERRORS.dj.event.view.getPastSessions, ToastType.ERROR);
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const onPlaySessionClick = async (sessionId: number) => {
    try {
      const { data } = await playSong({
        variables: { sessionId },
      });

      const newSessions = sessions.filter((s) => s.id !== data.play.id);
      const topSong = votesService.parseSongData(data.play.songVotes)[0];
      setSessions(
        [...newSessions, { ...data.play, songVotes: [topSong] }].sort((a, b) => b.id - a.id),
      );
    } catch (error) {
      useToast(ERRORS.dj.event.view.playSong, ToastType.ERROR);
      console.error(error);
    }
  };

  return (
    <section
      id="winners"
      className="gradient-background mt-4 flex min-h-0 w-full grow flex-col rounded-t-[1.25rem]"
    >
      <span className="flex w-full flex-row items-center px-6 pt-2 font-bold text-neon-pink">
        Winner Songs
      </span>
      {isLoading && (
        <div className="mt-20 flex h-full w-full items-start justify-center text-neon-pink">
          <Spinner />
        </div>
      )}
      {!isLoading && sessions.length > 0 && (
        <div
          id="winners"
          className="mt-[1rem] flex grow flex-col overflow-auto px-6"
          onScroll={(event) => {
            const scrollItem = document.querySelector('#winners');
            onScroll(scrollItem?.scrollTop || 0);
          }}
        >
          {sessions
            .sort((a, b) => b.id - a.id)
            .map((session, index) => (
              <SessionItem
                key={session.id}
                index={sessions.length - index}
                session={session}
                onPlayClick={onPlaySessionClick}
              />
            ))}
        </div>
      )}
      {!isLoading && sessions.length === 0 && (
        <div id="empty" className="flex items-center justify-center p-4">
          <p className="text-sm font-thin">No songs yet.</p>
        </div>
      )}
    </section>
  );
};

export default PastWinners;
