import { useMutation } from '@tanstack/react-query';
import {
  ILikeMemoryPayload,
  IMemoryPayload,
  likeMemory,
  postMemory,
} from './memories.api';

export const usePostMemoryMutation = () => {
  return useMutation((payload: IMemoryPayload) => postMemory(payload));
};

export const useLikeMemoryMutation = () => {
  return useMutation((payload: ILikeMemoryPayload) => likeMemory(payload));
};
