import { gql, useMutation, useSubscription } from '@apollo/client';

export const useAddTipMutation = () =>
  useMutation(gql`
    mutation Mutation($tip: CreateTipDto!) {
      addTip(tip: $tip) {
        id
      }
    }
  `);

export const useNewTipSubscription = (eventId: number) =>
  useSubscription(
    gql`
      subscription NewTip($eventId: Int!) {
        newTip(eventId: $eventId) {
          tipId
          amount
          username
        }
      }
    `,
    {
      variables: { eventId },
    },
  );
