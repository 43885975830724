import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { AuthProvider } from './common/auth/auth.context';
import { ApolloProvider } from '@apollo/client';
import client from './common/apollo/apollo';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

Auth.configure({
  region: process.env.REACT_APP_AWS_REGION,
  userPoolId: process.env.REACT_APP_AWS_USER_POOL_ID,
  userPoolWebClientId: process.env.REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID,
  oauth: {
    domain: process.env.REACT_APP_AWS_DOMAIN,
    scope: ['email', 'profile', 'openid'],
    redirectSignIn: process.env.REACT_APP_WS_REDIRECT_AUTH,
    redirectSignOut: process.env.REACT_APP_WS_REDIRECT_AUTH,
    responseType: 'code',
  },
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <ApolloProvider client={client}>
      <AuthProvider>
        <ToastContainer
          position="top-right"
          autoClose={2000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          limit={2}
        />
        <App />
      </AuthProvider>
    </ApolloProvider>
  </BrowserRouter>,
  // </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
