import { gql } from '@apollo/client';

export const VOTE_SUBSCTIPTION = gql`
  subscription Subscription {
    voteAdded {
      id
      song {
        id
        artist
        title
      }
      user {
        id
      }
      session {
        id
      }
    }
  }
`;

export const UNVOTE_SUBSCRIPTION = gql`
  subscription Subscription {
    voteRemoved {
      id
      song {
        id
        artist
        title
      }
      user {
        id
      }
      session {
        id
      }
    }
  }
`;

export const VOTE_SONG = gql`
  mutation ($voteInput: VoteInput!) {
    vote(voteInput: $voteInput) {
      id
      songId
      userId
      sessionId
    }
  }
`;
