import React, { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, useStripe, PaymentRequestButtonElement } from '@stripe/react-stripe-js';
import { requestPaymentSecret } from '../services/payment/payment.api';
import { ERRORS } from '../common/constants/errors';
import { ToastType } from '../common/models/toast-type.enum';
import useToast from '../common/hooks/useToast';

const stripeToPromise = loadStripe(process.env.REACT_APP_STRIPE_KEY as string);

interface CheckoutItem {
  amount: number;
  label: string;
}

interface PaymentActionsProps {
  id: string;
  amount: number;
  label: string;
  onPay: (paymentIntent: string) => Promise<void>;
  onFail: () => void;
}

const StripeActions = ({ label, amount, onPay, onFail, id }: PaymentActionsProps) => {
  const stripe = useStripe();
  const [paymentRequest, setPaymentRequest] = useState<any>(null);

  useEffect(() => {
    if (amount) {
      setPaymentRequest(null);
      initStripe({ label, amount });
    }
  }, [stripe, amount]);

  const initStripe = async (item: CheckoutItem) => {
    if (!stripe) {
      return null;
    }

    try {
      const pr = stripe.paymentRequest({
        country: 'RO',
        currency: 'ron',
        total: item,
        requestPayerName: true,
        requestPayerEmail: true,
      });

      // Check the availability of the Payment Request API.
      pr?.canMakePayment().then((result) => {
        if (result) {
          setPaymentRequest(pr);
        }
      });

      // request payment screet from server
      const clientSecret = await requestPaymentSecret(item.amount);

      pr.on('paymentmethod', async (event) => {
        // Confirm the PaymentIntent without handling potential next actions (yet).
        const { error: confirmError, paymentIntent } = await stripe.confirmCardPayment(
          clientSecret,
          {
            payment_method: event.paymentMethod.id,
          },
          {
            handleActions: false,
          },
        );

        if (confirmError) {
          // Report to the browser that the payment failed, prompting it to
          // re-show the payment interface, or show an error message and close
          // the payment interface.
          event.complete('fail');
          onFail();
          useToast(ERRORS.guest.payment.confirm, ToastType.ERROR);
          return;
        } else {
          // Report to the browser that the confirmation was successful, prompting
          // it to close the browser payment method collection interface.
          event.complete('success');

          // Check if the PaymentIntent requires any actions and, if so, let Stripe.js
          // handle the flow. If using an API version older than "2019-02-11"
          // instead check for: `paymentIntent.status === "requires_source_action"`.
          if (paymentIntent.status === 'requires_action') {
            // Let Stripe.js handle the rest of the payment flow.
            const { error } = await stripe.confirmCardPayment(clientSecret);
            if (error) {
              // The payment failed -- ask your customer for a new payment method.
              useToast(ERRORS.guest.payment.confirmCard, ToastType.ERROR);
              onFail();
            } else {
              // The payment has succeeded.
              await onPay(paymentIntent.id);
            }
          } else {
            // The payment has succeeded.
            await onPay(paymentIntent.id);
          }
        }
      });
    } catch (error) {
      console.log('error', error);
    }
  };

  if (!paymentRequest) {
    return <></>;
  }

  return (
    <PaymentRequestButtonElement
      id={id}
      options={{
        paymentRequest,
      }}
    />
  );
};

const PaymentActions = (props: PaymentActionsProps) => {
  return (
    <Elements stripe={stripeToPromise}>
      <div className="w-full">
        <StripeActions {...props} />
      </div>
    </Elements>
  );
};

export default PaymentActions;
