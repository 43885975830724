import { gql, useMutation, useSubscription } from '@apollo/client';

export const GET_SONG_REQUESTS_BY_EVENT_ID = gql`
  query ($eventId: Int!) {
    suggestions(eventId: $eventId) {
      artist
      id
      status
      title
      payment {
        amount
        user {
          profile {
            username
          }
        }
      }
    }
  }
`;

export const APPROVE_SONG_REQUEST_MUTATION = gql`
  mutation ($suggestionId: Int!) {
    approveSuggestion(suggestionId: $suggestionId) {
      artist
      id
      status
      title
    }
  }
`;

export const REJECT_SONG_REQUEST_MUTATION = gql`
  mutation ($suggestionId: Int!) {
    rejectSuggestion(suggestionId: $suggestionId) {
      artist
      id
      status
      title
    }
  }
`;

export const PLAY_SONG_REQUEST_MUTATION = gql`
  mutation ($suggestionId: Int!) {
    playSuggestion(suggestionId: $suggestionId) {
      artist
      id
      status
      title
    }
  }
`;

export const useAddRequestMutation = () =>
  useMutation(gql`
    mutation SuggestSong($suggestion: CreateSongSuggestionDto!) {
      suggestSong(suggestion: $suggestion) {
        id
      }
    }
  `);

export const useNewRequestSubscription = (eventId: number) =>
  useSubscription(
    gql`
      subscription NewSuggestion($eventId: Int!) {
        newSuggestion(eventId: $eventId) {
          amount
          artist
          title
          suggestionId
          username
        }
      }
    `,
    {
      variables: { eventId },
    },
  );

export const useRequestResolverSubscription = (eventId: number) =>
  useSubscription(
    gql`
      subscription Subscription($eventId: Int!) {
        suggestionResolved(eventId: $eventId) {
          eventId
          status
          suggestionId
          username
          amount
          title
          artist
        }
      }
    `,
    {
      variables: { eventId },
    },
  );
