import { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { AuthContext } from '../common/auth/auth.context';

const GuestRoutes = (props: any) => {
  const { profile } = useContext(AuthContext);

  return profile?.role === 'guest' ? <Outlet /> : <Navigate to="/" />;
};

export default GuestRoutes;
