import { useLazyQuery, useSubscription } from '@apollo/client';
import { useEffect, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import votesService from '../common/services/votes.service';
import Lottie from 'lottie-react';
import {
  GET_ACTIVE_EVENT_BY_ID_WITH_FULL_PLAYLIST,
  GET_PAST_SESSIONS,
} from '../common/graphql/event.graphql';
import {
  GET_SESSION_WITH_SONGS,
  SESSION_END_SUBSCRIPTION,
} from '../common/graphql/session.graphql';
import { compareAsc } from 'date-fns';
import useToast from '../common/hooks/useToast';
import { ERRORS } from '../common/constants/errors';
import { ToastType } from '../common/models/toast-type.enum';
import { UNVOTE_SUBSCRIPTION, VOTE_SUBSCTIPTION } from '../common/graphql/vote.graphql';
import {
  EVENT_USERS_DISCONNECT_SUBSCRIPTION,
  EVENT_USERS_SUBSCRIPTION,
  GET_EVENT_USERS,
} from '../common/graphql/user.graphql';
import SessionCountDown from '../components/countdown';
import { ClockIcon, HeartIcon, MusicalNoteIcon, UserGroupIcon } from '@heroicons/react/24/solid';
import { GET_TOP_SPENDERS_BY_EVENT_ID } from '../common/graphql/statistics.graphql';
import ButtonWrapper from '../components/button-wrapper';
import loadingAnimation from '../assets/lottie/music_loader.json';
import confettiAnimation from '../assets/lottie/confetti.json';
import { useRequestResolverSubscription } from '../services/song-requests/song-requests.service';
import { useNewTipSubscription } from '../services/tips/tips.service';
import useCustomToast from '../common/hooks/useCustomToast';
import {
  REQUEST_PLAYING_TIMER,
  STATISTICS_CAROUSEL_INTERVAL,
  STATISTICS_TOAST_TIMER,
} from '../common/constants/variables';

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(' ');
}

export type EVENT_TYPE = {
  total: number;
  username: string;
  type: 'tip' | 'request-approved' | 'request-played';
  song?: { artist: string; title: string };
};

const EventStatisticsItem = ({
  label,
  className,
  children,
  icon,
}: {
  label: string;
  className?: string;
  children: any;
  icon: any;
}) => {
  return (
    <div
      className={classNames(
        'flex h-[35rem] min-h-fit w-[30%] flex-col items-center justify-between gap-10 rounded-3xl border-2 border-solid p-5',
        className ? className : '',
      )}
    >
      {icon}
      <div className="text-6xl font-semibold text-white">{label}</div>
      {children}
    </div>
  );
};

const EventStatistics = () => {
  // state
  const [event, setEvent] = useState<null | any>(null);
  const [session, setSession] = useState<null | any>(null);
  const [lastSessionWinner, setLastSessionWinner] = useState<null | any>(null);
  const [votes, setVotes] = useState<number>(0);
  const [numberOfParticipants, setNumberOfParticipants] = useState<number>(0);
  const [topSpenders, setTopSpenders] = useState<any[]>();
  const [isSessionActive, setIsSessionActive] = useState<boolean>(false);

  // api queries
  const [getEventById] = useLazyQuery(GET_ACTIVE_EVENT_BY_ID_WITH_FULL_PLAYLIST, {
    fetchPolicy: 'network-only',
  });
  const [getSession] = useLazyQuery(GET_SESSION_WITH_SONGS, {
    fetchPolicy: 'network-only',
  });
  const [getEventUsers] = useLazyQuery(GET_EVENT_USERS, {
    fetchPolicy: 'network-only',
  });
  const [getTopSpenders] = useLazyQuery(GET_TOP_SPENDERS_BY_EVENT_ID, {
    fetchPolicy: 'network-only',
  });
  const [getPastSessions] = useLazyQuery(GET_PAST_SESSIONS, {
    fetchPolicy: 'network-only',
  });

  const votesSubsctiption = useSubscription(VOTE_SUBSCTIPTION);
  const unvoteSubscription = useSubscription(UNVOTE_SUBSCRIPTION);
  const userSubscription = useSubscription(EVENT_USERS_SUBSCRIPTION);
  const userDisconnectSubscription = useSubscription(EVENT_USERS_DISCONNECT_SUBSCRIPTION);
  const sessionEndSubscription = useSubscription(SESSION_END_SUBSCRIPTION);
  const { data: suggestionResolved } = useRequestResolverSubscription(event?.id);
  const { data: newTip } = useNewTipSubscription(event?.id);
  const [canAnimate, setCanAnimate] = useState<boolean>(false);
  const [requestPlaying, setRequestPlaying] = useState<EVENT_TYPE | undefined>();
  const [toastCount, setToastCount] = useState<number>(0);

  /**
   * INIT
   */
  useEffect(() => {
    initEvent();
    setInterval(() => {
      initEvent();
    }, 30 * 1000);
  }, []);

  useEffect(() => {
    if (event?.currentSessionId) {
      initSession();
    }
  }, [event]);

  useEffect(() => {
    if (votesSubsctiption.data) {
      //   const { voteAdded } = votesSubsctiption.data;
      //   onNewVote(voteAdded);
      updateSessionVotes();
      //   setVotes(votes + 1);
    }
  }, [votesSubsctiption.data]);

  useEffect(() => {
    if (unvoteSubscription.data) {
      //   const { voteRemoved } = unvoteSubscription.data;
      //   onRemoveVote(voteRemoved);
      updateSessionVotes();
      //   setVotes(votes - 1);
    }
  }, [unvoteSubscription.data]);

  useEffect(() => {
    if (userSubscription.data) {
      setNumberOfParticipants(numberOfParticipants + 1);
    }
  }, [userSubscription.data]);

  useEffect(() => {
    if (userDisconnectSubscription.data) {
      setNumberOfParticipants(numberOfParticipants - 1);
    }
  }, [userDisconnectSubscription.data]);

  useEffect(() => {
    if (sessionEndSubscription.data) {
      // get voted songs
      initEvent();
    }
  }, [sessionEndSubscription.data]);

  useEffect(() => {
    if (session) {
      setIsSessionActive(compareAsc(new Date(session.endDate), new Date()) > 0);
    }
  }, [session]);

  useEffect(() => {
    if (newTip) {
      useCustomToast(
        {
          total: newTip.newTip.amount,
          username: newTip.newTip.username,
          type: 'tip',
        },
        onDisplay,
      );
    }
  }, [newTip]);

  useEffect(() => {
    if (suggestionResolved) {
      if (suggestionResolved.suggestionResolved.status === 'APPROVED') {
        useCustomToast(
          {
            total: suggestionResolved.suggestionResolved.amount,
            username: suggestionResolved.suggestionResolved.username,
            type: 'request-approved',
            song: {
              title: suggestionResolved.suggestionResolved.title,
              artist: suggestionResolved.suggestionResolved.artist,
            },
          },
          onDisplay,
        );
        return;
      }
      if (suggestionResolved.suggestionResolved.status === 'PLAYED') {
        setRequestPlaying({
          total: suggestionResolved.suggestionResolved.amount,
          username: suggestionResolved.suggestionResolved.username,
          type: 'request-played',
          song: {
            title: suggestionResolved.suggestionResolved.title,
            artist: suggestionResolved.suggestionResolved.artist,
          },
        });
        if (!canAnimate) setCanAnimate(true);
        setTimeout(() => {
          setRequestPlaying(undefined);
        }, REQUEST_PLAYING_TIMER);
        return;
      }
    }
  }, [suggestionResolved]);

  useEffect(() => {
    if (toastCount === 0 && !requestPlaying) {
      setCanAnimate(false);
    }
  }, [toastCount, requestPlaying]);

  const initSession = async () => {
    try {
      const { data } = await getSession({
        variables: { sessionId: event.currentSessionId },
      });

      if (compareAsc(Date.now(), new Date(data.session.endDate)) >= 0) {
      } else {
        setSession(data.session);
        setVotes(data.session.songVotes.length);
      }
    } catch (error) {
      useToast(ERRORS.dj.event.view.getSession, ToastType.ERROR);
      console.error(error);
    }
  };

  const updateSessionVotes = async () => {
    if (event.currentSessionId) {
      try {
        const { data } = await getSession({
          variables: { sessionId: event.currentSessionId },
        });
        setVotes(data.session.songVotes.length);
      } catch (error) {
        useToast(ERRORS.guest.event.getVotes, ToastType.ERROR);
        console.error(error);
      }
    }
  };

  const initEvent = async () => {
    try {
      const { data } = await getEventById();
      setEvent(data.eventActive);

      if (data?.eventActive) {
        const eventUsersResponse = await getEventUsers({
          variables: { eventId: data.eventActive?.id },
        });
        setNumberOfParticipants(eventUsersResponse.data.eventUsers.length);
      }

      initSpenders(data.eventActive?.id);
      initPastWinner(data.eventActive?.id);
    } catch (error) {
      useToast(ERRORS.dj.event.view.get, ToastType.ERROR);
      console.error(error);
    }
  };

  const initPastWinner = async (eventId: number) => {
    try {
      const { data } = await getPastSessions({
        variables: {
          eventId,
        },
      });
      if (data?.event?.sessions?.length > 0) {
        //1. get last session
        const lastSession = [...data.event.sessions].sort((a: any, b: any) => a.id - b.id)[
          data.event.sessions.length - 1
        ];
        //2. get last session winner song
        const topSong = votesService.parseSongData(lastSession.songVotes)[0];

        //3. set last session winner song
        if (topSong) {
          setLastSessionWinner({ artist: topSong.artist, title: topSong.title });
        } else {
          setLastSessionWinner(undefined);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const initSpenders = async (eventId: number) => {
    const { data: response } = await getTopSpenders({
      variables: { eventId: eventId },
    });
    try {
      if (response?.topSpenders) {
        const topSpendersList = [];
        response.topSpenders[1]?.total > 0
          ? topSpendersList.push({ ...response.topSpenders[1], category: 'silver' })
          : null;
        response.topSpenders[0]?.total > 0
          ? topSpendersList.push({ ...response.topSpenders[0], category: 'gold' })
          : null;
        response.topSpenders[2]?.total > 0
          ? topSpendersList.push({ ...response.topSpenders[2], category: 'bronze' })
          : null;

        setTopSpenders(topSpendersList);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getSessionEndDate = () => {
    return session?.endDate ? new Date(session.endDate) : new Date();
  };

  const onDisplay = () => {
    if (!canAnimate) {
      setCanAnimate(true);
      setToastCount.bind(null, toastCount + 1);
      setTimeout(() => {
        setToastCount.bind(null, toastCount - 1);
      }, STATISTICS_TOAST_TIMER);
    }
  };

  return (
    <main className="flex h-screen w-full flex-col bg-black">
      {canAnimate && (
        <Lottie
          className="lottie absolute z-[9999] h-full w-full"
          animationData={confettiAnimation}
          loop={true}
        />
      )}

      <div className="flex h-screen w-screen items-center justify-center bg-framebound bg-cover bg-top bg-no-repeat">
        <div className="absolute h-full w-full bg-black-30 backdrop-blur-md"></div>
        {requestPlaying ? (
          <div className="relative flex h-[90%] w-[90%] items-center justify-center rounded-xl bg-gradient-to-tr from-[#fb7bdf] via-[#c36aff] to-[#ffff72] p-20 text-white backdrop-blur-md">
            <div className="flex flex-col items-center gap-2 text-center drop-shadow-lg">
              <p className="text-5xl">We're playing</p>
              <p className="webkit-line-clamp-2 break-words text-9xl font-bold">
                🎵{requestPlaying.song?.title}🎵
              </p>
              <p className="webkit-line-clamp-2 break-words pb-24 text-5xl">
                {requestPlaying.song?.artist}
              </p>
              <p className="text-6xl">requested by</p>
              <p className="webkit-line-clamp-2 break-words text-8xl font-bold">
                {requestPlaying.username}
              </p>
            </div>
            <div
              id="juke-logo"
              className="absolute bottom-10 left-10 h-40 w-72 bg-juke-logo bg-contain bg-center bg-no-repeat drop-shadow-lg"
            ></div>
            <div
              id="qr-logo"
              className="absolute bottom-10 right-10 h-52 w-52 bg-qrcode_juke bg-contain bg-center bg-no-repeat drop-shadow-lg"
            ></div>
          </div>
        ) : (
          <Carousel
            className="h-full w-full"
            showArrows={true}
            autoFocus={true}
            autoPlay={true}
            infiniteLoop={true}
            interval={STATISTICS_CAROUSEL_INTERVAL}
            stopOnHover={false}
            showThumbs={false}
          >
            <div
              id="section_1_poster"
              className="flex h-screen w-screen flex-col items-center justify-around p-[2%]"
            >
              <div className="flex h-full w-full flex-row items-center justify-center gap-[5rem]">
                <div className="h-[100%] w-[100%] bg-poster bg-contain bg-center bg-no-repeat" />
              </div>
            </div>
            <div
              id="section_2_top"
              className="flex h-screen w-screen flex-col items-center justify-around p-[2%]"
            >
              {(topSpenders?.length || 0) == 0 && (
                <div className="flex h-screen w-screen flex-col items-center text-white">
                  <div className="h-[15rem] w-[30rem] bg-juke-logo bg-contain bg-center bg-no-repeat pb-32"></div>
                  <div className="flex h-full w-full flex-row items-center justify-center space-x-20 p-20">
                    <div className="flex h-full flex-col items-center justify-center gap-10">
                      <div className="flex flex-row items-end gap-3 text-neon-pink">
                        <MusicalNoteIcon className="h-10 w-10" />
                        <MusicalNoteIcon className="h-20 w-20" />
                        <MusicalNoteIcon className="h-10 w-10" />
                      </div>
                      <p className="text-7xl font-bold">Vote your favorite songs</p>
                      <p className="text-7xl font-bold">and dance on the Beats! </p>
                      <br />
                      <p className="text-7xl font-bold">Scan the QR and</p>
                      <p className="text-7xl font-bold italic text-neon-pink">Let's Juke It On!</p>
                    </div>
                    <div className="h-[35rem] w-[35rem] bg-qrcode_juke bg-contain bg-no-repeat"></div>
                  </div>
                </div>
              )}
              {(topSpenders?.length || 0) > 0 && (
                <div className="flex h-screen w-screen flex-col items-center gap-8 overflow-hidden p-20 text-white">
                  <p className="text-4xl font-extrabold xl:text-8xl">Top Tippers & Requesters</p>
                  <div className="flex h-full w-full flex-row items-center justify-center gap-10 lg:gap-20">
                    {topSpenders?.map((spender, index) => (
                      <ButtonWrapper
                        key={index}
                        classNames={`${
                          spender.category === 'gold' ? 'scale-110' : ''
                        } flex !w-[35rem] min-w-[30%] h-fit flex-col items-center gap-5 lg:gap-10 !rounded-[50px] !p-10 !border-8`}
                      >
                        <div
                          className={`flex h-32 w-32 items-center justify-center rounded-full bg-contain bg-center bg-no-repeat xl:h-40 xl:w-40 ${
                            spender.category === 'gold'
                              ? 'bg-gold_badge'
                              : spender.category === 'silver'
                              ? 'bg-silver_badge'
                              : 'bg-bronze_badge'
                          }`}
                        >
                          {spender.category === 'gold' && (
                            <div className="ray_box">
                              <div className="ray ray1"></div>
                              <div className="ray ray2"></div>
                              <div className="ray ray3"></div>
                              <div className="ray ray4"></div>
                              <div className="ray ray5"></div>
                              <div className="ray ray6"></div>
                              <div className="ray ray7"></div>
                              <div className="ray ray8"></div>
                              <div className="ray ray9"></div>
                              <div className="ray ray10"></div>
                            </div>
                          )}
                        </div>
                        <p className="webkit-line-clamp-3 h-[9rem] max-w-[30rem] break-words pt-0 text-3xl font-bold xl:pt-10 xl:text-5xl">
                          {spender.username}
                        </p>
                        <div className="mt-auto flex items-end rounded-xl px-4 py-2 text-4xl font-bold text-white shadow-lg border-gradient-br-input-border-white00 xl:text-7xl">
                          <p>{spender.total / 100}</p>
                          <p className="text-4xl">lei</p>
                        </div>
                      </ButtonWrapper>
                    ))}
                  </div>
                </div>
              )}
            </div>
            <div
              id=" section_3_statistics"
              className="flex h-screen w-screen items-center justify-center p-[2%]"
            >
              {!isSessionActive ? (
                <div className="flex h-screen w-screen flex-col items-center justify-center gap-3 text-white">
                  <div className="flex flex-row items-end gap-3 text-neon-pink">
                    <Lottie className="lottie h-96" animationData={loadingAnimation} loop={true} />
                  </div>
                  <p className="text-6xl font-bold shadow-lg">
                    The next session is about to start...
                  </p>
                  <div className="h-[20rem] w-[40rem] bg-juke-logo bg-contain bg-center bg-no-repeat pb-32"></div>
                </div>
              ) : (
                <div className="flex h-screen w-screen flex-col items-center gap-10 p-[5rem]">
                  <div className="h-[24rem] w-[32rem] bg-juke_statistics bg-contain bg-center bg-no-repeat"></div>
                  <div id="Statistics" className="flex h-fit w-full items-center justify-around">
                    <EventStatisticsItem
                      className="bg-[#EB129830] backdrop-blur-lg"
                      label="Votes this session"
                      icon={
                        <div className="flex items-center justify-center rounded-full bg-neon-purple bg-opacity-40 p-3">
                          <HeartIcon className="h-20 w-20 text-neon-purple" />
                        </div>
                      }
                    >
                      <p className="mt-auto text-9xl font-bold text-neon-purple">
                        {votes?.toString()}
                      </p>
                    </EventStatisticsItem>
                    <EventStatisticsItem
                      className="bg-[#9BA2FF30] backdrop-blur-lg"
                      label="Participants"
                      icon={
                        <div className="flex items-center justify-center rounded-full bg-[#9BA2FF] bg-opacity-40 p-3 opacity-90">
                          <UserGroupIcon className="h-20 w-20 text-[#9BA2FF]" />
                        </div>
                      }
                    >
                      <p className="mt-auto text-9xl font-bold text-[#9BA2FF]">
                        {numberOfParticipants?.toString()}
                      </p>
                    </EventStatisticsItem>
                    <EventStatisticsItem
                      className="bg-[#44FFD230] backdrop-blur-lg"
                      label="Voting Time Remaining"
                      icon={
                        <div className="flex items-center justify-center rounded-full bg-[#44FFD2] bg-opacity-40 p-3">
                          <ClockIcon className="h-20 w-20 text-[#44FFD2]" />
                        </div>
                      }
                    >
                      <SessionCountDown
                        endDate={getSessionEndDate()}
                        onComplete={() => setIsSessionActive(false)}
                        className="mt-auto text-9xl font-bold text-[#44FFD2]"
                      />
                    </EventStatisticsItem>
                  </div>
                </div>
              )}
            </div>
            <div
              id="section_4_winner"
              className="flex h-screen w-screen items-center justify-center p-[2%]"
            >
              {lastSessionWinner && (
                <div className="relative flex h-full w-full items-center justify-center overflow-hidden rounded-xl p-20 text-white">
                  <div className="z-50 h-full w-full">
                    <div className="flex h-full flex-col items-center justify-between space-y-2 drop-shadow-lg">
                      <p className="text-5xl text-white">And the last session winner is...</p>
                      <div className="space-y-4">
                        <div className="flex items-center justify-center text-white">
                          <MusicalNoteIcon className="h-[10rem] w-[10rem] text-neon-green" />
                          <p className="webkit-line-clamp-2 break-words text-8xl font-bold">
                            {lastSessionWinner.title}
                          </p>
                          <MusicalNoteIcon className="h-[10rem] w-[10rem] text-neon-green" />
                        </div>
                        <p className="webkit-line-clamp-2 break-words text-5xl text-white">
                          {lastSessionWinner.artist}
                        </p>
                      </div>
                      <p className="w-[60%] text-6xl font-semibold text-neon-green">
                        Vote your favorites in the app and keep the music flowing!
                      </p>
                    </div>
                    <div
                      id="juke-logo"
                      className="absolute bottom-10 left-10 h-40 w-72 bg-juke-logo bg-contain bg-center bg-no-repeat drop-shadow-lg"
                    ></div>
                    <div
                      id="qr-logo"
                      className="absolute bottom-10 right-10 h-52 w-52 bg-qrcode_juke bg-contain bg-center bg-no-repeat drop-shadow-lg"
                    ></div>
                  </div>
                  <div className="absolute left-0 top-0 z-30 h-full w-full bg-[#000000A0] backdrop-blur-md"></div>
                  <div className="bg-winner absolute left-0 top-0 z-20 h-full w-full bg-cover  bg-center bg-no-repeat"></div>
                </div>
              )}
              {!lastSessionWinner && (
                <div className="flex h-full w-[80%] flex-col items-center justify-center space-y-10">
                  <div className="h-[15rem] w-[35rem] bg-juke-logo bg-contain bg-center bg-no-repeat"></div>
                  <p className="break-word text-center text-8xl font-semibold text-white">
                    Vote your favorites in the app and <br /> 💖 keep the music flowing! 💖
                  </p>
                  <div className="h-80 w-80 bg-qrcode_juke bg-contain bg-center bg-no-repeat"></div>
                </div>
              )}
            </div>
          </Carousel>
        )}
      </div>
    </main>
  );
};

export default EventStatistics;
