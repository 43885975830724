import { Song } from '../common/services/youtube.service';

const PlaylistCard = ({ item, onClick }: { item: any; onClick?: any }) => {
  const parsePreview = (songs: Song[]) => {
    const artists = songs.map((song) => song.artist);
    const artistsUnique = new Set(artists);
    return Array.from(artistsUnique).join(',');
  };

  return (
    <div
      className="card mr-[10px] mt-[10px] flex h-32 w-32 shrink-0 cursor-pointer flex-col bg-neon-red text-white hover:opacity-80"
      onClick={onClick}
    >
      <div className="flex flex-2 flex-col rounded-t-lg bg-neon-red px-[1rem] pt-[1rem]">
        <h4 className="flex-1 text-sm text-white">{item.name}</h4>
      </div>
      <div className="flex-1 rounded-b-lg bg-[#00000080] px-2 py-3 opacity-25">
        <p className="h-full text-[9px] line-clamp-2">
          {parsePreview(item.songs)}
        </p>
      </div>
    </div>
  );
};

export default PlaylistCard;
