import { useLazyQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { Plus } from 'react-feather';
import Eventlist from '../components/event-list';
import Header from '../components/header';
import PrimaryButton from '../components/primary-button';
import VerticalLayout from '../components/vertical-layout';
import { GET_EVENTS_QUERY } from '../common/graphql/event.graphql';
import { AuthContext } from '../common/auth/auth.context';
import useToast from '../common/hooks/useToast';
import { ERRORS } from '../common/constants/errors';
import { ToastType } from '../common/models/toast-type.enum';

const Events = () => {
  const router = useNavigate();
  const { profile } = useContext(AuthContext);
  const [getEvents] = useLazyQuery(GET_EVENTS_QUERY, {
    fetchPolicy: 'network-only',
  });
  const [events, setEvents] = useState<any[]>([]);

  useEffect(() => {
    if (profile) {
      initEvents();
    }
  }, [profile]);

  const initEvents = async () => {
    try {
      const { data } = await getEvents({ variables: { userId: profile?.id } });
      setEvents(data.user.events);
    } catch (error) {
      useToast(ERRORS.dj.event.list.getAll, ToastType.ERROR);
      console.error(error);
    }
  };

  const addEvent = () => {
    router(`/dj/event/add`);
  };
  return (
    <VerticalLayout background="bg-desktop" canGoBack>
      <main className="flex h-full max-w-[550px] flex-col">
        <section className="flex h-[10%] items-center justify-end">
          <span className="flex min-w-[120px] cursor-pointer justify-between text-white">
            {/* Past events <ArrowRight /> */}
          </span>
        </section>
        <section className="h-[80%]">
          <Header title="Juke Events" subtitle="See all events or create a new one" />
          <div id="event-list" className="max-h-[32rem] overflow-y-auto py-4">
            {events.length > 0 && <Eventlist events={events} />}
            {events.length === 0 && <p className="text-xs font-thin text-white">No events added</p>}
          </div>
        </section>
        <section className="flex h-[10%] items-center justify-center p-1">
          <PrimaryButton
            onClick={addEvent}
            color="bg-main"
            icon={<Plus className="text-sm text-white" />}
          >
            Create New Event
          </PrimaryButton>
        </section>
      </main>
    </VerticalLayout>
  );
};

export default Events;
