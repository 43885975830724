export enum PaymentType {
  TIP = 'tip',
  SUGGESTION = 'suggestion',
}

export enum PaymentStatus {
  COMPLETE = 'COMPLETE',
  REFUNDED = 'REFUNDED',
  PENDING = 'PENDING',
}

export interface IUserPayment {
  id: number;
  eventName: string;
  paymentType: PaymentType;
  amount: number;
  createdOn: Date;
  status: PaymentStatus;
}
