import { gql } from '@apollo/client';

export const GET_EVENT_USERS = gql`
  query ($eventId: Int!) {
    eventUsers(eventId: $eventId) {
      id
      profile {
        username
      }
    }
  }
`;

export const GET_PARTICIPANTS = gql`
  query ($eventId: Int!) {
    participants(eventId: $eventId) {
      id
      name
      votes
    }
  }
`;

export const EVENT_USERS_SUBSCRIPTION = gql`
  subscription {
    userConnected {
      id
    }
  }
`;

export const EVENT_USERS_DISCONNECT_SUBSCRIPTION = gql`
  subscription {
    userDisconnected {
      id
    }
  }
`;

export const CONNECT_USER = gql`
  mutation ($connectToEventInput: ConnectToEventInput!) {
    connect(connectToEventInput: $connectToEventInput) {
      id
      events {
        id
        active
      }
    }
  }
`;

export const GET_INITIAL_GUEST_EVENT = gql`
  query ($userId: Int!) {
    user(userId: $userId) {
      events {
        id
        active
        code {
          code
        }
      }
    }
  }
`;

export const GET_GUEST_EVENT = gql`
  query ($userId: Int!) {
    user(userId: $userId) {
      isOnboarded
      events {
        id
      }
    }
  }
`;

export const UPDATE_USER = gql`
  mutation ($editUserData: EditUserInput!) {
    editUser(editUserData: $editUserData) {
      id
    }
  }
`;

export const DISCONNECT_USER = gql`
  mutation ($userId: Int!) {
    disconnect(userId: $userId) {
      id
    }
  }
`;

export const CREATE_PROFILE = gql`
  mutation ($newProfileInput: NewProfileInput!) {
    createProfile(newProfileInput: $newProfileInput) {
      id
      profile {
        id
        username
        agreement
      }
    }
  }
`;

export const GET_TOP_VOTERS = gql`
  query ($eventId: Int!) {
    topVoters(eventId: $eventId) {
      id
      profile {
        username
      }
      votes {
        id
      }
    }
  }
`;
