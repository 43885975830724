export const MESSAGES = {
  dj: {
    playlist: {
      add: {
        create: 'Playlist created!',
      },
      edit: {
        update: 'Playlist udpated!',
        delete: 'Playlist deleted!',
      },
    },
    event: {
      add: {
        create: 'Event created',
        startSession: 'Session started',
        startEvent: 'Event started',
        stopEvent: 'Event stoped',
        requestFeedback: 'Feedback Request Sent',
      },
      edit: {
        update: 'Event updated!',
        delete: 'Event deleted',
      },
    },
  },
  guest: {
    event: {
      disconnect: 'You have refreshed the event',
      uploadMemory: 'Memory uploaded with success!',
      requestSong: 'Thank you for the request!',
      tipDj: 'Thank you for the tip!',
    },
    profile: {
      create: 'Profile created, welcome to Juke!',
    },
  },
};
