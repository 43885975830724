import { ChevronLeft, DollarSign, Menu, Power, X } from 'react-feather';
import { useContext, useEffect, useState } from 'react';
import MobileBG from '../assets/img/background-mobile.png';
import { loadImage } from '../common/services/preload-images.service';
import LoadingScreen from './loading-screen';
import PrimaryButton from './primary-button';
import { Button, Modal, ModalBody, Spinner } from 'reactstrap';
import QRCODE from '../assets/img/qrcode_juke.png';
import { BsFillShareFill } from 'react-icons/bs';
import { RotateCw } from 'react-feather';
import { AuthContext } from '../common/auth/auth.context';
import RoundButtonWrapper from './round-button-wrapper';
import { useNavigate } from 'react-router-dom';
import GuestMenu from './guest-menu';
import Lottie from 'lottie-react';
import musicLoadingAnimation from '../assets/lottie/music_loader.json';

const preloadImages = [MobileBG];

const ModuleMobileLayout = ({
  children,
  canGoBack,
  className,
  onRefresh,
  isLoading,
  isRefreshing,
}: {
  onRefresh?: any;
  children?: React.ReactNode;
  canGoBack?: boolean;
  className?: string;
  isLoading?: boolean;
  isRefreshing?: boolean;
}) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [wrapperLoading, setWrapperLoading] = useState(true);
  const { logout } = useContext(AuthContext);
  const router = useNavigate();
  const { profile } = useContext(AuthContext);

  useEffect(() => {
    Promise.all(preloadImages.map((path) => loadImage(path))).then(() => {
      setWrapperLoading(false);
    });
  }, []);

  const onPaymentsClick = () => {
    router('/guest/profile/payments');
  };

  return (
    <>
      {(isLoading || wrapperLoading) && <LoadingScreen />}
      {!isLoading && !wrapperLoading && (
        <section className="h-full">
          <div
            className={`hidden h-screen flex-col items-center justify-center bg-desktop bg-cover bg-center bg-no-repeat px-24 py-16 lg:flex`}
          >
            <h1 className="flex flex-col items-center justify-center text-3xl text-white">
              <div>Please open this view on a mobile device</div>
              <PrimaryButton color="bg-main" onClick={logout}>
                Logout
              </PrimaryButton>
            </h1>
          </div>
          <div
            className={`relative h-full min-h-fit bg-framemobile bg-cover bg-top bg-no-repeat lg:hidden ${className}`}
          >
            <section className="fixed z-10 flex h-fit w-full flex-row-reverse items-start justify-between p-3">
              {profile && (
                <div className="relative">
                  <RoundButtonWrapper
                    classNames={menuOpen ? '!bg-neon-pink' : ''}
                    onClick={() => setMenuOpen(!menuOpen)}
                  >
                    {menuOpen && <X size={25} className="text-white"></X>}
                    {!menuOpen && <Menu size={25} className="text-white" />}
                  </RoundButtonWrapper>
                  {menuOpen && (
                    <GuestMenu
                      listItems={[
                        {
                          label: 'Payments',
                          onClick: onPaymentsClick,
                          icon: <DollarSign size={16} className="text-white"></DollarSign>,
                        },
                        {
                          label: 'Log Out',
                          onClick: logout,
                          icon: <Power size={16} className="text-white" />,
                          withConfirm: {
                            confirmText: 'Logging out...',
                            subtitle: 'We will wait you to come back later!',
                          },
                        },
                      ]}
                      toggleMenu={setMenuOpen.bind(null, !menuOpen)}
                    ></GuestMenu>
                  )}
                </div>
              )}

              <div className="flex gap-3">
                {canGoBack && (
                  <RoundButtonWrapper
                    onClick={() => {
                      localStorage.setItem('route', 'event');
                      router(-1);
                    }}
                  >
                    <ChevronLeft size={25} className="cursor-pointer text-white" />
                  </RoundButtonWrapper>
                )}
                <RoundButtonWrapper classNames="!bg-main" onClick={setModalOpen.bind(null, true)}>
                  <BsFillShareFill size={16} className="cursor-pointer text-white" />
                </RoundButtonWrapper>
                {onRefresh && (
                  <RoundButtonWrapper onClick={onRefresh}>
                    <RotateCw size={25} className="cursor-pointer text-white" />
                  </RoundButtonWrapper>
                )}
              </div>
            </section>
            <section className="flex h-full min-h-fit">{children}</section>
            {modalOpen && (
              <Modal
                isOpen={true}
                toggle={setModalOpen.bind(null, true)}
                centered
                size="lg"
                contentClassName="rounded-[1.25rem] bg-[#F3F3F3] max-w-lg"
                // modalClassName="max-w-lg"
                className="max-w-lg"
              >
                <ModalBody className="gradient-background text-white">
                  <div className="flex h-[15rem] flex-col items-center justify-around px-2 pt-2">
                    <div id="header" className="flex flex-col items-center">
                      <p className="text-center text-xl font-semibold ">Share with your friends</p>
                    </div>
                    <img className="mt-2" src={QRCODE} alt="me" width={140} height={140} />
                    <div className="flex items-center justify-evenly pt-2">
                      <Button
                        className={`flex h-[2.5rem] w-auto min-w-[10rem] items-center justify-around rounded-lg border-none bg-gray-selected p-3 hover:border-none hover:bg-light-gray-200 active:bg-light-gray-200`}
                        onClick={setModalOpen.bind(null, false)}
                      >
                        <span className="mr-2 ">Cancel</span>
                      </Button>
                    </div>
                  </div>
                </ModalBody>
              </Modal>
            )}
            {isRefreshing && (
              <div className="absolute left-0 top-0 flex h-[100svh] w-[100svw] flex-col items-center justify-center bg-black-30 text-neon-pink backdrop-blur-xl fade-in">
                <Lottie className="lottie h-48" animationData={musicLoadingAnimation} loop={true} />
                <p className="text-center text-xl font-semibold">
                  Please wait, <br />
                  we are loading your experience...
                </p>
              </div>
            )}
          </div>
        </section>
      )}
    </>
  );
};

export default ModuleMobileLayout;
