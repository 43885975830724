import { useLazyQuery, useSubscription } from '@apollo/client';
import { useEffect, useState } from 'react';
import { GET_SESSION_WITH_SONGS } from '../common/graphql/session.graphql';
import { UNVOTE_SUBSCRIPTION, VOTE_SUBSCTIPTION } from '../common/graphql/vote.graphql';
import SessionCountDown from './countdown';
import {
  EVENT_USERS_DISCONNECT_SUBSCRIPTION,
  EVENT_USERS_SUBSCRIPTION,
  GET_EVENT_USERS,
} from '../common/graphql/user.graphql';
import useToast from '../common/hooks/useToast';
import { ERRORS } from '../common/constants/errors';
import { ToastType } from '../common/models/toast-type.enum';
import { getEventTipTotal } from '../services/event/event.api';
import { useNewTipSubscription } from '../services/tips/tips.service';
import { RONFormat } from '../common/constants/formatters';

export const StatisticsItem = ({
  title,
  description,
  onClick,
}: {
  title: any;
  description: string;
  onClick?: () => void;
}) => {
  return (
    <div className="flex flex-1 flex-col items-center justify-center p-1" onClick={onClick}>
      {title}
      <h4 className="text-xxs font-extralight uppercase">{description}</h4>
    </div>
  );
};

const Statistics = ({
  sessionId,
  eventId,
  tracks,
  onSessionComplete,
  eventActive,
}: {
  sessionId: number;
  eventId: number;
  tracks: number;
  onSessionComplete: any;
  eventActive: boolean;
}) => {
  const [session, setSession] = useState<any | null>(null);
  const [votes, setVotes] = useState<number>(0);
  const [numberOfParticipants, setNumberOfParticipants] = useState<number>(0);
  const [totalTips, setTotalTips] = useState<number>(0);
  // queries
  const [getSessionById, {}] = useLazyQuery(GET_SESSION_WITH_SONGS, {
    fetchPolicy: 'network-only',
  });
  const [getEventUsers, {}] = useLazyQuery(GET_EVENT_USERS, {
    fetchPolicy: 'network-only',
  });

  // subscriptions
  const votesSubscription = useSubscription(VOTE_SUBSCTIPTION);
  const unvoteSubsription = useSubscription(UNVOTE_SUBSCRIPTION);
  const userSubscription = useSubscription(EVENT_USERS_SUBSCRIPTION);
  const userDisconnectSubscription = useSubscription(EVENT_USERS_DISCONNECT_SUBSCRIPTION);
  const { data: newTip } = useNewTipSubscription(eventId);

  useEffect(() => {
    if (sessionId) {
      initSession();
    }
  }, [sessionId]);

  useEffect(() => {
    if (eventId) {
      initUsers();
      initTotalTips(eventId);
    }
  }, [eventId]);

  useEffect(() => {
    if (votesSubscription.data) {
      // setVotes(votes + 1);
      updateSessionVotes();
    }
  }, [votesSubscription.data]);

  useEffect(() => {
    if (unvoteSubsription.data) {
      // setVotes(votes - 1);
      updateSessionVotes();
    }
  }, [unvoteSubsription.data]);

  useEffect(() => {
    if (userSubscription.data) setNumberOfParticipants(numberOfParticipants + 1);
  }, [userSubscription.data]);

  useEffect(() => {
    if (userDisconnectSubscription.data) setNumberOfParticipants(numberOfParticipants - 1);
  }, [userDisconnectSubscription.data]);

  useEffect(() => {
    if (newTip) {
      setTotalTips(totalTips + newTip.newTip.amount);
    }
  }, [newTip]);

  const initSession = async () => {
    try {
      const { data } = await getSessionById({ variables: { sessionId } });
      setSession(data.session);
      setVotes(data.session.songVotes.length);
    } catch (error) {
      useToast(ERRORS.dj.event.view.getSession, ToastType.ERROR);
      console.error(error);
    }
  };

  const initUsers = async () => {
    try {
      const { data } = await getEventUsers({ variables: { eventId } });
      setNumberOfParticipants(data.eventUsers.length);
    } catch (error) {
      useToast(ERRORS.dj.event.view.getEventUsers, ToastType.ERROR);
      console.error(error);
    }
  };

  const updateSessionVotes = async () => {
    if (sessionId) {
      try {
        const { data } = await getSessionById({
          variables: { sessionId },
        });
        setVotes(data.session.songVotes.length);
      } catch (error) {
        useToast(ERRORS.guest.event.getVotes, ToastType.ERROR);
        console.error(error);
      }
    }
  };

  const initTotalTips = async (eventId: number) => {
    try {
      const { total } = await getEventTipTotal(eventId);
      setTotalTips(total);
    } catch (error) {
      useToast(ERRORS.guest.event.tipsAmount, ToastType.ERROR);
      console.error(error);
    }
  };

  return (
    <div className="flex h-full w-full divide-x divide-neon-red px-1 py-4 text-white">
      <StatisticsItem
        title={
          <SessionCountDown
            endDate={session && eventActive ? new Date(session.endDate) : new Date()}
            onComplete={onSessionComplete}
          />
        }
        description="TIME REMAINING"
      />
      <StatisticsItem
        title={<h1 className="text-2xl">{votes}</h1>}
        description="VOTES THIS SESSION"
      />
      <StatisticsItem
        title={<h1 className="text-2xl">{numberOfParticipants}</h1>}
        description="PARTICIPANTS"
      />
      <StatisticsItem
        title={<h1 className="text-2xl">{RONFormat.format(totalTips / 100)}</h1>}
        description="DJ TIPS"
      />
    </div>
  );
};

export default Statistics;
