import { Input } from 'reactstrap';
import { Search, X } from 'react-feather';
import ButtonWrapper from './button-wrapper';

const SearchInput = ({ value, onChange, onKeyPress, onClearText }: any) => {
  return (
    <ButtonWrapper classNames="!w-full py-0 gap-1 !h-[2.2rem]">
      <Input
        type="text"
        placeholder="Search..."
        value={value}
        className="h-full w-full rounded-2xl p-0 text-white placeholder:text-xs placeholder:text-white focus:outline-none"
        style={{ backgroundColor: 'transparent', border: 'none' }}
        onChange={(event) => onChange(event.target.value)}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            onKeyPress();
          }
        }}
      />
      <Search className="h-[1rem] w-[1rem] text-white" />
      {value?.length > 0 && (
        <X className="h-[1rem] w-[1rem] text-white" onClick={onClearText} />
      )}
    </ButtonWrapper>
  );
};

export default SearchInput;
