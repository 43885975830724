import { gql } from '@apollo/client';

export const GET_SESSION_BY_ID = gql`
  query Session($sessionId: Int!) {
    session(id: $sessionId) {
      id
      endDate
    }
  }
`;

export const GET_SESSION_WITH_SONGS = gql`
  query ($sessionId: Int!) {
    session(id: $sessionId) {
      id
      endDate
      songVotes {
        id
        song {
          id
          title
          artist
        }
        user {
          id
        }
        session {
          id
        }
      }
    }
  }
`;

export const SESSION_END_SUBSCRIPTION = gql`
  subscription Subscription {
    sessionEnded {
      id
      played
      endDate
      songVotes {
        id
        song {
          id
          artist
          title
        }
        user {
          id
        }
        session {
          id
        }
      }
    }
  }
`;

export const PLAY_MUTATION = gql`
  mutation ($sessionId: Int!) {
    play(sessionId: $sessionId) {
      id
      played
      songVotes {
        id
        song {
          id
          artist
          title
        }
        user {
          id
        }
        session {
          id
        }
      }
    }
  }
`;

export const GET_USER_SESSION = gql`
  query ($sessionId: Int!) {
    session(id: $sessionId) {
      endDate
    }
  }
`;

export const GET_TOP_SONGS = gql`
  query ($eventId: Int!) {
    sessions(eventId: $eventId) {
      id
      winner {
        id
        artist
        title
        votes {
          id
          userId
          sessionId
        }
      }
    }
  }
`;
