import { toast } from 'react-toastify';
import { EVENT_TYPE } from '../../pages/EventStatistics';
import { STATISTICS_TOAST_TIMER } from '../constants/variables';

const useCustomToast = (content: EVENT_TYPE, onDisplay: () => void) => {
  switch (content?.type) {
    case 'tip':
      toast(
        <div className="flex flex-col items-center gap-3 text-center">
          <p className="text-7xl font-bold">{content.username}</p>
          <p className="text-3xl">tipped the DJ</p>
          <p className="text-6xl font-bold">💰{content.total / 100} lei💰</p>
        </div>,
        {
          position: 'top-center',
          className: `!bg-white-60 backdrop-blur-2xl !min-w-fit !py-5 !px-10 !rounded-2xl`,
          progressClassName: 'no-progress',
          autoClose: STATISTICS_TOAST_TIMER,
          onOpen: () => onDisplay(),
          closeButton: false,
        },
      );
      break;
    case 'request-approved':
      toast(
        <div className="flex flex-col items-center gap-2 text-center">
          <p className="text-3xl">DJ approved</p>
          <p className="text-7xl font-bold">🎵{content.song?.title}🎵</p>
          <p className="pb-3 text-4xl">{content.song?.artist}</p>
          <p className="text-3xl">requested by</p>
          <p className="text-6xl font-bold">{content.username}</p>
        </div>,
        {
          position: 'top-center',
          className: `!bg-white-60 backdrop-blur-2xl !min-w-fit !py-5 !px-10 !rounded-2xl`,
          progressClassName: 'no-progress',
          autoClose: STATISTICS_TOAST_TIMER,
          onOpen: () => onDisplay(),
          closeButton: false,
        },
      );
      break;
  }
};

export default useCustomToast;
