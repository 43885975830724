import { FormFeedback, FormGroup, Input } from 'reactstrap';
import { InputType } from 'reactstrap/types/lib/Input';

const PrimaryInput = ({
  onChange,
  id,
  type,
  placeholder,
  defaultValue,
  errorMessage,
  invalid,
  value,
}: {
  onChange: any;
  id: string;
  type: InputType;
  errorMessage?: string;
  invalid?: boolean;
  placeholder: string;
  defaultValue?: any;
  value?: any;
}) => {
  return (
    <FormGroup className="relative mb-0 h-[2rem] rounded-lg border-2 border-solid border-gradient-br-input-border-neon gradient-border-1">
      <Input
        className="h-full w-full rounded-lg p-2 text-white focus:outline-none"
        style={{ backgroundColor: 'transparent', border: 'none' }}
        defaultValue={defaultValue}
        id={id}
        type={type}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        invalid={invalid}
      />
      <FormFeedback>{errorMessage || ''}</FormFeedback>
    </FormGroup>
  );
};

export default PrimaryInput;
