import { useContext, useEffect, useState } from 'react';
import { Form } from 'reactstrap';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import VerticalLayout from '../components/vertical-layout';
import PlaylistGroup from '../components/playlist-group';
import { PlaylistModel } from '../common/models/playlist.model';
import Header from '../components/header';
import PrimaryInput from '../components/primary-input';
import PrimaryButton from '../components/primary-button';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { AuthContext } from '../common/auth/auth.context';
import { GET_PLAYLISTS_BY_USER_ID } from '../common/graphql/playlist.graphql';
import {
  DELETE_EVENT,
  EDIT_EVENT,
  GET_EVENT_BY_ID,
} from '../common/graphql/event.graphql';
import useToast from '../common/hooks/useToast';
import { ERRORS } from '../common/constants/errors';
import { ToastType } from '../common/models/toast-type.enum';
import { MESSAGES } from '../common/constants/messages';
import WithConfirm from '../components/with-confirmation';

const EventEdit = () => {
  const router = useNavigate();
  const { id } = useParams();
  // form state
  const [event, setEvent] = useState<any | null>(null);
  const [name, setName] = useState<string>('');
  const [location, setLocation] = useState<string>('');
  const [date, setDate] = useState<Date>(new Date());
  const [hour, setHour] = useState<string>('');
  const [djName, setDjName] = useState<string>('');
  // submision
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

  const { profile } = useContext(AuthContext);
  const [playlists, setPlaylists] = useState<PlaylistModel[]>([]);
  const [selectedPlaylists, setSelectedPlaylists] = useState<number[]>([]);
  // make sure this is called after the profile is loaded
  const [getPlaylists] = useLazyQuery(GET_PLAYLISTS_BY_USER_ID, {
    fetchPolicy: 'cache-only',
  });
  const [getEvent] = useLazyQuery(GET_EVENT_BY_ID, {
    fetchPolicy: 'network-only',
  });
  const [editEvent] = useMutation(EDIT_EVENT);
  const [deleteEvent] = useMutation(DELETE_EVENT);

  useEffect(() => {
    if (profile) {
      initEventData();
    }
  }, [profile]);

  useEffect(() => {
    if (event) {
      setName(event.name);
      setLocation(event.location);
      setDate(new Date(event.startDate));
      setDjName(event.djName);
      // setHour(new Date(event.startDate).getTime().toString());
      setSelectedPlaylists(event.playlist.map((item: any) => item.id));

      initPLaylistData();
    }
  }, [event]);

  const initPLaylistData = async () => {
    try {
      const { data } = await getPlaylists({
        variables: { userId: profile?.id },
      });
      setPlaylists(data.user.playlist);
    } catch (error) {
      useToast(ERRORS.dj.event.add.getPlaylists, ToastType.ERROR);
      console.error(error);
    }
  };

  const initEventData = async () => {
    try {
      const { data } = await getEvent({
        variables: { eventId: +(id as string) },
      });
      setEvent(data.event);
    } catch (error) {
      useToast(ERRORS.dj.event.edit.getEvent, ToastType.ERROR);
      console.error(error);
    }
  };

  const onSubmit = async () => {
    setIsSubmitted(true);
    if (
      name &&
      location &&
      date &&
      // hour &&
      djName
    ) {
      if ([...selectedPlaylists, ...playlists].length === 0) {
        useToast(ERRORS.dj.event.add.requiredPlaylist, ToastType.ERROR);
        return;
      }
      // construct the date
      const startDate = new Date(date);
      if (hour.toString().length === 5) {
        startDate.setHours(+hour.split(':')[0]);
        startDate.setMinutes(+hour.split(':')[1]);
      }

      const playlistIds: number[] = [];
      selectedPlaylists.forEach((id) => {
        const foundIndex = event?.playlist.findIndex(
          (item: { id: number }) => item.id === id,
        );
        // if id wasn't in the initial event add new
        if (foundIndex < 0) {
          playlistIds.push(id);
        }
      });

      const removedPlaylistIds: number[] = [];
      event?.playlist.forEach((item: { id: number }) => {
        const foundIndex = selectedPlaylists.findIndex(
          (id: number) => item.id === id,
        );
        // if id wasn't in the initial event add new
        if (foundIndex < 0) {
          removedPlaylistIds.push(item.id);
        }
      });

      let editEventInput: any = {
        id: event.id,
        name,
        location,
        startDate,
        djName,
      };

      if (playlistIds.length > 0) {
        editEventInput = {
          ...editEventInput,
          playlistIds,
        };
      }

      if (removedPlaylistIds.length > 0) {
        editEventInput = {
          ...editEventInput,
          removedPlaylistIds,
        };
      }

      try {
        const { data } = await editEvent({
          variables: { editEventInput },
        });

        useToast(MESSAGES.dj.event.edit.update, ToastType.SUCCESS);
        router('/dj/events', { replace: true });
        setEvent(data.editEvent);
      } catch (error) {
        useToast(ERRORS.dj.event.edit.update, ToastType.ERROR);
        console.error(error);
      }
    }
    setIsSubmitted(false);
  };

  const onPlaylistSelected = (id: number) => {
    const foundIndex = selectedPlaylists.findIndex((item) => item === id) >= 0;
    if (foundIndex) {
      // if found remove
      setSelectedPlaylists(selectedPlaylists.filter((item) => item !== id));
    } else {
      // if not found push
      setSelectedPlaylists([...selectedPlaylists, id]);
    }
  };

  const onDeleteEvent = async () => {
    try {
      await deleteEvent({ variables: { removeEventId: +(id as string) } });
      useToast(MESSAGES.dj.event.edit.delete, ToastType.SUCCESS);
      router(-1);
    } catch (error) {
      useToast(ERRORS.dj.event.edit.delete, ToastType.ERROR);
      console.error(error);
    }
  };

  return (
    <VerticalLayout background="bg-frame3" canGoBack>
      <main className="flex h-full w-full max-w-[610px] flex-col">
        <section id="header" className="flex h-[10%] items-end">
          <Header
            title="Update your event"
            subtitle="Update all the requested details for your event"
          />
        </section>
        <section id="form" className="h-[15%] min-w-[500px] py-1">
          <Form className="w-full">
            <div className="mb-2 flex w-full flex-wrap items-start justify-start">
              <div className="w-[50%]">
                <PrimaryInput
                  id="name"
                  type="text"
                  placeholder="Event name"
                  errorMessage="Please enter event name"
                  value={name}
                  invalid={isSubmitted && name.length === 0}
                  onChange={(event: any) => setName(event.target.value)}
                />
              </div>
              <div className="w-[30%] px-2">
                <div className="relative flex h-[2rem] items-center rounded-lg border-2 border-solid border-gradient-br-input-border-neon gradient-border-1">
                  <DatePicker
                    className=" bg-transparent h-full w-full rounded-lg  p-1 text-white focus:outline-none"
                    selected={date}
                    onChange={(date: Date) => setDate(date)}
                    minDate={new Date()}
                  />
                </div>
              </div>
              <div className="w-[20%]">
                <PrimaryInput
                  id="hour"
                  type="time"
                  //   value={hour}
                  invalid={isSubmitted && hour.length === 0}
                  errorMessage="Missing Time"
                  placeholder="Start hour"
                  onChange={(event: any) => setHour(event.target.value)}
                />
              </div>
            </div>
            <div className="w-ful1 flex items-start justify-start">
              <div className="w-[50%]">
                <PrimaryInput
                  id="location"
                  type="text"
                  placeholder="Location"
                  errorMessage="Please enter location"
                  value={location}
                  invalid={isSubmitted && location.length === 0}
                  onChange={(event: any) => setLocation(event.target.value)}
                />
              </div>
              <div className="w-[50%] pl-2">
                <PrimaryInput
                  id="djName"
                  type="text"
                  placeholder="DJ Name"
                  errorMessage="Please enter dj name"
                  value={djName}
                  invalid={isSubmitted && djName.length === 0}
                  onChange={(event: any) => setDjName(event.target.value)}
                />
              </div>
            </div>
          </Form>
        </section>
        <section id="playlist" className="h-[60%] pt-6">
          <p className="text-sm text-white">
            Playlist added{' '}
            <span className="text-success">{selectedPlaylists.length}</span>
          </p>
          <div className="h-full max-h-96 w-full">
            <PlaylistGroup
              playlist={playlists}
              selectable
              onSelect={onPlaylistSelected}
              selectedItems={playlists.map((pl) => pl.id)}
            />
          </div>
        </section>
        <section id="actions" className="mt-4 flex justify-between p-2">
          {!event?.endDate && (
            <WithConfirm
              onConfirm={onDeleteEvent}
              confirmText="Delete the event"
              subtitle="This action will delete this event and is ireversible. Are you sure?"
            >
              <PrimaryButton color="bg-transparent">
                <span className="px-4 text-[#FF0000]">Delete Event</span>
              </PrimaryButton>
            </WithConfirm>
          )}
          <div className="flex flex-row-reverse">
            <PrimaryButton color="bg-main" onClick={onSubmit}>
              Save
            </PrimaryButton>
            <PrimaryButton color="bg-transparent" onClick={() => router(-1)}>
              Cancel
            </PrimaryButton>
          </div>
        </section>
      </main>
    </VerticalLayout>
  );
};

export default EventEdit;
