import ModuleMobileLayout from '../components/module-mobile-layout';
import Frame from '../assets/img/juke-logo.png';
import { AiOutlineCalendar, AiOutlineClockCircle, AiOutlineEnvironment } from 'react-icons/ai';
import { useLazyQuery } from '@apollo/client';
import { GET_EVENT_LOBBY_DATA } from '../common/graphql/event.graphql';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { format } from 'date-fns/esm';
import useToast from '../common/hooks/useToast';
import { ERRORS } from '../common/constants/errors';
import { ToastType } from '../common/models/toast-type.enum';

const Detail = ({ title, subtitle, Icon }: any) => {
  return (
    <div className="mb-2 flex items-center">
      <div
        id="icon"
        className="mr-2 flex h-9 w-9 items-center justify-center rounded-[5px] bg-gray-selected"
      >
        {Icon}
      </div>
      <div id="data" className="flex flex-col">
        <div id="title" className="text-uppercase text-xxs">
          {title}
        </div>
        <div id="main" className="text-sm">
          {subtitle}
        </div>
      </div>
    </div>
  );
};

const GuestEventFinished = () => {
  const { id } = useParams();
  const [event, setEvent] = useState<null | any>(null);
  const [getEvent] = useLazyQuery(GET_EVENT_LOBBY_DATA, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    loadEventData();
  }, []);

  const loadEventData = async () => {
    try {
      const response = await getEvent({
        variables: { eventId: +(id as string) },
      });
      setEvent(response.data.event);
    } catch (error) {
      useToast(ERRORS.guest.event.getEvent, ToastType.ERROR);
      console.error(error);
    }
  };

  const getDate = () => {
    return event ? format(new Date(event.startDate), 'dd.MM.yyyy') : '';
  };

  const getTime = () => {
    return event ? format(new Date(event.startDate), 'HH:mm') : '';
  };

  return (
    <>
      <ModuleMobileLayout className="bg-framebound">
        <main className="relative flex h-full w-full flex-col items-center justify-evenly overflow-y-auto text-white ">
          <section
            id="card"
            className="m-4 flex h-[250px] w-[80%] flex-col rounded-lg border-[1px] border-solid bg-gradient-to-bl from-[#6FB8FF]/40 to-[#74002F]/40 p-4 backdrop-opacity-50"
          >
            <h1 className="text-2xl font-semibold text-white">{event?.name}</h1>
            <p className="mb-6 text-xs font-normal text-white">{event?.djName} </p>

            <Detail subtitle={getDate()} title={'Date'} Icon={<AiOutlineCalendar size={24} />} />
            <Detail subtitle={getTime()} title={'Hour'} Icon={<AiOutlineClockCircle size={24} />} />
            <Detail
              subtitle={event?.location}
              title={'Location'}
              Icon={<AiOutlineEnvironment size={24} />}
            />
          </section>

          <section id="bottom" className="flex h-[10rem] pb-4">
            <div id="message" className="flex h-full flex-col items-center justify-between">
              <div className="flex flex-col items-center justify-center">
                <h1 className="text-xl text-white">This event has ended,</h1>
                <p className="text-uppercase text-xl font-bold text-white">
                  THANK YOU FOR PARTICIPATING
                </p>
              </div>
              <img className="mt-2" src={Frame} alt="me" width={120} height={120} />
            </div>
          </section>
        </main>
      </ModuleMobileLayout>
    </>
  );
};

export default GuestEventFinished;
