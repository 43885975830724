import ModuleMobileLayout from '../components/module-mobile-layout';
import Frame from '../assets/img/juke-logo.png';
import { AiOutlineCalendar, AiOutlineClockCircle, AiOutlineEnvironment } from 'react-icons/ai';
import { useLazyQuery, useMutation, useSubscription } from '@apollo/client';
import {
  EVENT_UPDATES_SUBSCRIPTION,
  GET_EVENT_ACTIVE_LOBBY_DATA,
} from '../common/graphql/event.graphql';
import { useNavigate } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { format } from 'date-fns/esm';
import useToast from '../common/hooks/useToast';
import { ERRORS } from '../common/constants/errors';
import { ToastType } from '../common/models/toast-type.enum';
import { CONNECT_USER, DISCONNECT_USER } from '../common/graphql/user.graphql';
import { AuthContext } from '../common/auth/auth.context';

const Detail = ({ title, subtitle, Icon }: any) => {
  return (
    <div className="mb-2 flex items-center">
      <div
        id="icon"
        className="mr-2 flex h-9 w-9 items-center justify-center rounded-[5px] bg-gray-selected"
      >
        {Icon}
      </div>
      <div id="data" className="flex flex-col">
        <div id="title" className="text-uppercase text-xxs">
          {title}
        </div>
        <div id="main" className="text-sm">
          {subtitle}
        </div>
      </div>
    </div>
  );
};

const GuestLobby = () => {
  const router = useNavigate();

  const { profile } = useContext(AuthContext);
  const [event, setEvent] = useState<null | any>(null);
  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);
  const [getEvent] = useLazyQuery(GET_EVENT_ACTIVE_LOBBY_DATA, {
    fetchPolicy: 'network-only',
  });
  const [connect] = useMutation(CONNECT_USER);
  const [disconnect] = useMutation(DISCONNECT_USER);
  const { data, loading } = useSubscription(EVENT_UPDATES_SUBSCRIPTION);

  useEffect(() => {
    if (data?.eventUpdates.active && data?.eventUpdates.id === +(event?.id as string)) {
      handleEventData(data?.eventUpdates);
    }
  }, [data]);

  useEffect(() => {
    if (event) handleEventData(event);
  }, [event]);

  useEffect(() => {
    loadEventData();
    document.addEventListener('visibilitychange', handleVisibilityChange, false);
    // destroy
    () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  // startSimulation and pauseSimulation defined elsewhere
  const handleVisibilityChange = () => {
    if (document.visibilityState !== 'hidden') {
      loadEventData();
    }
  };

  const loadEventData = async () => {
    try {
      const response = await getEvent();
      setEvent(response.data.eventActive);
    } catch (error) {
      useToast(ERRORS.guest.event.getEvent, ToastType.ERROR);
      console.error(error);
    } finally {
      setIsRefreshing(false);
    }
  };

  const handleEventData = async (updatedEvent: any) => {
    if (updatedEvent?.active) {
      try {
        await connect({
          variables: {
            connectToEventInput: {
              code: updatedEvent.code.code,
              userId: profile?.id,
            },
          },
        });
      } catch (error) {
        console.error(error);
      } finally {
        router(`/guest/event/${updatedEvent.id}`, { replace: true });
      }
    }
  };

  const getDate = () => {
    return event ? format(new Date(event.startDate), 'dd.MM.yyyy') : '';
  };

  const getTime = () => {
    return event ? format(new Date(event.startDate), 'HH:mm') : '';
  };

  return (
    <>
      <ModuleMobileLayout
        className="bg-framebound"
        canGoBack={false}
        onRefresh={() => {
          setIsRefreshing(true);
          loadEventData();
        }}
        isRefreshing={isRefreshing}
      >
        <main className="relative flex h-full w-full flex-col items-center justify-evenly overflow-y-auto text-white ">
          {event === null ? (
            <></>
          ) : (
            <section
              id="card"
              className="m-4 flex h-fit min-h-[250px] w-[80%] flex-col rounded-lg border-[1px] border-solid bg-gradient-to-bl from-[#6FB8FF]/40 to-[#74002F]/40 p-4 backdrop-opacity-50"
            >
              <h1 className="text-2xl font-semibold text-white">{event?.name}</h1>
              <p className="mb-6 text-xs font-normal text-white">{event?.djName} </p>

              <Detail subtitle={getDate()} title={'Date'} Icon={<AiOutlineCalendar size={24} />} />
              <Detail
                subtitle={getTime()}
                title={'Hour'}
                Icon={<AiOutlineClockCircle size={24} />}
              />
              <Detail
                subtitle={event?.location}
                title={'Location'}
                Icon={<AiOutlineEnvironment size={24} />}
              />
            </section>
          )}

          <section id="bottom" className="flex h-[10rem] pb-4">
            <div id="message" className="flex h-full flex-col items-center justify-between">
              <div className="flex flex-col items-center justify-center">
                <h1 className="text-xl text-white">Hold tight,</h1>
                <p className="text-uppercase text-xl font-bold text-white">
                  {event === null ? 'NEXT PARTY WILL HAPPEN SOON' : 'PARTY IS ABOUT TO START'}
                </p>
              </div>
              <img className="mt-2" src={Frame} alt="me" width={120} height={120} />
            </div>
          </section>
        </main>
      </ModuleMobileLayout>
    </>
  );
};

export default GuestLobby;
