import { gql } from '@apollo/client';

export const GET_PLAYLISTS_BY_USER_ID = gql`
  query ($userId: Int!) {
    user(userId: $userId) {
      playlist {
        id
        name
        songs {
          id
          artist
          title
        }
      }
    }
  }
`;

export const GET_PLAYLIST_BY_ID = (playlistId: any) => gql`
  {
    playlist(playlistId: ${playlistId}) {
      id
      name
      songs {
        id
        artist
        title
      }
    }
  }
`;

export const CREATE_PLAYLIST = gql`
  mutation CreatePlaylist($playlistInput: NewPlaylistInput!) {
    createPlaylist(playlistInput: $playlistInput) {
      id
      name
    }
  }
`;

export const EDIT_PLAYLIST = gql`
  mutation EditPlaylist($editPlaylist: EditPlaylistInput!) {
    editPlaylist(editPlaylist: $editPlaylist) {
      id
    }
  }
`;

export const DELETE_PLAYLIST = gql`
  mutation DeletePlaylist($deletePlaylistId: Int!) {
    deletePlaylist(deletePlaylistId: $deletePlaylistId) {
      id
      name
    }
  }
`;
