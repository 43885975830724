import { gql } from '@apollo/client';

export const GET_MEMORIES_BY_EVENT_ID = gql`
  query ($eventId: Int!, $userId: Int!) {
    memories(eventId: $eventId, userId: $userId) {
      id
      description
      likesCount
      path
      liked
      user {
        id
        profile {
          username
        }
      }
    }
  }
`;

export const NEW_LIKES_SUBSCRIPTION = gql`
  subscription {
    newLikes {
      id
    }
  }
`;

export const MEMORIES_UPDATES_SUBSCRIPTION = gql`
  subscription {
    newMemory {
      path
      likesCount
      liked
      id
      eventId
      description
    }
  }
`;
